<template>
  <div class="More">
    <div class="head">
      <div class="account">
        <div class="title">{{$t('daily.all')}}({{systemInfo.tkname}})</div>
        <div class="total">{{infoData.all}}</div>
        <div class="yesterday">
          <div class="yes-title">{{$t('daily.yesterday')}}</div>
          <div class="yes-num">+{{infoData.yesterday}}</div>
          <div class="yes-rate">+{{infoData.rate}}%</div>
        </div>
      </div>
      <div class="head-list">
        <div class="head-item">
          <div class="hi-key">{{$t('daily.yes_mining')}}</div>
          <div class="hi-value">{{infoData.yesterday_mining}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{$t('daily.yes_node')}}</div>
          <div class="hi-value">{{infoData.yesterday_node}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{$t('daily.release_mining')}}</div>
          <div class="hi-value">{{infoData.all_miningIng}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{$t('daily.release_node')}}</div>
          <div class="hi-value">{{infoData.all_nodeIng}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{$t('daily.all_mining')}}</div>
          <div class="hi-value">{{infoData.all_mining}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{$t('daily.all_node')}}</div>
          <div class="hi-value">{{infoData.all_node}}</div>
        </div>
        
      </div>
    </div>
    <div class="plan">
      <div class="plan-top">
        <div class="plan-head">
          <div class="ph-item">
            <CommonBtn :btnText="$t('daily.mining_reward')" :btnType="type == 0 ? 0 : 2" @btnClick="changeType(0)"></CommonBtn>
          </div>
          <div class="ph-item">
            <CommonBtn :btnText="$t('daily.node_reward')" :btnType="type == 1 ? 0 : 2" @btnClick="changeType(1)"></CommonBtn>
          </div>
        </div>
      </div>
      <div class="date">
        <div class="date-cell" @click="openDate">
          <div class="date-date">{{date}}</div>
          <img class="date-img" src="@/assets/images/icon_date2.png" alt="">
        </div>
      </div>
      <div class="plan-main">
        <table class="plan-table">
          <thead>
            <tr>
              <template v-if="type == 0">
                <th class="td1">{{$t('daily.date')}}</th>
                <th class="td2">{{$t('daily.mining_reward')}}</th>
                <th class="td3">{{$t('daily.status')}}</th>
                <th class="td4">{{$t('daily.pledge')}}</th>
              </template>
              <template v-else>
                <th class="td1">{{$t('daily.date')}}</th>
                <th class="td2">{{$t('daily.node_reward')}}</th>
                <th class="td3">{{$t('daily.status')}}</th>
                <th class="td4"></th>
              </template>
            </tr>
          </thead>
        </table>
        <van-list
          v-if="infoList.length != 0 || firstLoad"
          v-model:loading="loading"
          :finished="finished"
          finished-text=""
          @load="onLoad"
          class="plan-list"
        >
          <table class="plan-table">
            <table class="plan-table">
              <tbody>
                <tr v-for="(o, i) in infoList" :key="i">
                  <template v-if="type == 0">
                    <td class="td1">{{o.date}}</td>
                    <td class="td2">{{o.ct}}</td>
                    <td class="td3">{{o.status}}</td>
                    <td class="td4">{{o.quote}}</td>
                  </template>
                  <template v-else>
                    <td class="td1">{{o.date}}</td>
                    <td class="td2">{{o.ct}}</td>
                    <td class="td3">{{o.status}}</td>
                    <td class="td4"></td>
                  </template>
                </tr>
              </tbody>
            </table>
          </table>
        </van-list>
        <NoData v-if="infoList.length == 0 && !firstLoad"/>
      </div>
    </div>
    <van-calendar v-model:show="dateShow" type="range" :min-date="minDate" :max-date="maxDate"
     :allow-same-day="true" :confirm-text="$t('daily.confirm')" :confirm-disabled-text="$t('daily.end')" @confirm="onConfirm" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { statisticsApi, statisticsMiningApi, statisticsNodeApi } from '@/api/user'
import NoData from '@/components/NoData'
export default {
  name: 'More',
  components: {
    NoData
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
      userInfo: state => state.user.userInfo,
    }),
  },
  watch: {
    userInfo() {
      if (this.userInfo.uid) {
        this.getData()
      }
    }
  },
  data() {
    return {
      infoData: {},
      type: 0, // 0全部 1我的团队
      infoList: [],

      start: 0,
      limit: 10,
      firstLoad: true,
      loading: false,
      finished: false,

      dateShow: false,
      date: '',
      datestart: '',
      dateend: '',

      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2120, 0, 31),
    }
  },
  created() {
    let date = new Date()
    let last = date.getTime() - 1000 * 3600 * 24 * 30
    last = new Date(last)
    this.minDate = new Date(date.getTime() - 1000 * 3600 * 24 * 30 * 12 * 20)
    this.maxDate = new Date(date.getTime() + 1000 * 3600 * 24 * 30 * 12 * 20)
    this.datestart = this.formatDate(last)
    this.dateend = this.formatDate(date)
    this.date = `${this.datestart} - ${this.dateend}`
  },
  mounted() {
    this.pageType = this.$route.query.type || '';
    this.getData()
  },
  methods: {
    ...mapActions(['setResetPage']),
    async getData() {
      await statisticsApi({type: this.pageType}).then(res => {
        this.infoData = res
      })
    },
    changeType(index) {
      this.type = index

      this.start = 0
      this.infoList = []
      this.firstLoad = true
      this.finished = false
      // this.$forceUpdate()
      this.onLoad()
    },
    onLoad() {
      console.log('onload')
      this.loading = true
      let req = this.type == 0 ? statisticsMiningApi : statisticsNodeApi
      req({start: this.start, limit: this.limit, datestart: this.datestart, dateend: this.dateend, type: this.pageType}).then(res => {
        if (this.firstLoad) {
          this.firstLoad = false
        }
        if (this.start == 0 || !this.start) {
          this.infoList = []
        }
        this.infoList = this.infoList.concat(res.list)
        if (this.infoList.length >= res.count) {
          this.finished = true
        } else {
          this.finished = false
        }
        this.start = this.infoList.length
        this.loading = false
        console.log(this.start, this.limit, this.finished, this.loading)
      })
    },
    openDate() {
      this.dateShow = true
      console.log(this.dateShow)
    },
    formatDate(date) {
      let year = date.getFullYear()
      let mouth = date.getMonth() + 1
      let day = date.getDate()
      if (mouth < 10) {
        mouth = '0' + mouth
      }
      if (day < 10) {
        day = '0' + day
      }
      return `${year}-${mouth}-${day}`
    },
    onConfirm(date) {
      const [start, end] = date;
      this.dateShow = false;
      this.datestart = this.formatDate(start)
      this.dateend = this.formatDate(end)
      this.date = `${this.datestart} - ${this.dateend}`
      console.log(this.date)
      
      this.start = 0
      this.infoList = []
      this.firstLoad = true
      this.finished = false
      // this.$forceUpdate()
      this.onLoad()
    },
  }
}
</script>
<style lang="scss" scoped>
:root {
  // --van-calendar-background-color: #000 !important;
}
.More {
  min-height: calc(100vh - 45px);
  padding: 14px 15px 20px 15px;
  background: url(~@/assets/images/bg.png);
  background-size: auto;
  background-repeat: repeat;
  .account {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .title {
      margin-bottom: 4px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #A5A8AA;
    }
    .total {
      margin-bottom: 4px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      color: #fff;
    }
    .yesterday {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .yes-title {
        margin-right: 8px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #A5A8AA;
      }
      .yes-num {
        margin-right: 8px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #fff;
      }
      .yes-rate {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #EDC67F;
      }
    }
  }
  .head {
    margin-bottom: 22px;
    .head-list {
      display:flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 10px 0;
      .head-item {
        width: 100%;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 4px 0;
        .hi-key {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #A5A8AA;
        }
        .hi-value {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #FFFFFF;
        }
      }
    }
  }
  .plan {
    width: 100%;
    padding: 35px 16px;
    background: #202122;
    // box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D, inset 5px 11px 20px #000000;
    border-radius: 40px;
    .plan-top {
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
    }
    .plan-head {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      .ph-item {
        width: 80px;
        height: 36px;
        margin: 0 9px;
      }
    }
    .plan-img {
      width: 20px;
      height: 20px;
      margin-left: 2px;
    }
    .plan-main {
      width: 100%;
      margin-top: 20px;
      overflow-x: auto;
    }
    $table-width: 530px;
    .plan-list {
      width: $table-width;
      height: 430px;
      overflow-y: auto;
    }
    .plan-table {
      overflow-x: auto;
      width: $table-width;
      thead {
        background: #202122;
        box-shadow: inset -1px -1px 1px rgba(210, 210, 210, 0.25), inset -2px 1px 4px #000000;
        border-radius: 10px;
      }
      th, td {
        text-align: left;
        font-size: 14px;
        font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 16px;
      }
      th, td{
        padding: 14px 16px;
      }
      tbody tr:not(:last-child) {
        border-bottom: 1px solid rgba(255,255,255,0.2);
      }
      .td1 {
        width: 130px;
      }
      .td2 {
        width: 160px;
      }
      .td3 {
        width: 80px;
      }
      .td4 {
        width: 160px;
      }
    }
  }
  .date {
    width: 100%;
    height: 30px;
    .date-cell {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 10px;
      border: 1px solid #A5A8AA;
      border-radius: 5px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #fff;
      .date-date {
        flex: 1;
      }
      .date-img {
        width: 20px;
        height: 20px;
        margin-left: 10px;
      }
    }
  }
}
</style>

