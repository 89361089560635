<template>
  <van-popup
    v-model:show="visible"
    position="bottom"
    round
    class="NoteContent"
  >
    <div class="content">
      <div class="header">
        <div class="header-left">
          <div class="header-text">{{infoData.nodeid ? $t('note.editChild') : $t('note.addChild')}}</div>
        </div>
        <img class="header-icon" @click="close" src="@/assets/images/icon_Delete.png" alt="">
      </div>
      <div class="detail">
        <div class="symbol"></div>
        <div class="list">
          <template v-if="infoData.nodeid">
            <div class="item">
              <div class="item-key">{{$t('note.account')}}：</div>
              <div class="item-show" >{{infoData.user}}({{infoData.addr}}) </div>
            </div>
          </template>
          <template v-else>
            <div class="item">
              <div class="item-key">{{$t('note.account')}}：</div>
              <div class="item-show" v-if="pageType == 2">{{infoData.user}} </div>
              <div class="item-value" v-else>
                <input v-model="infoData.user" type="text" :placeholder="$t('note.pla_1')">
              </div>
            </div>
            <div class="item">
              <div class="item-key">{{$t('note.address')}}：</div>
              <div class="item-show" >{{infoData.addr || $t('note.pla_2')}} </div>
              <!-- <div class="item-value" v-else>
                <input v-model="infoData.addr" type="text" placeholder="输入子节点账户地址">
              </div> -->
            </div>
          </template>
          <div class="item">
            <div class="item-key">{{$t('note.note')}}：</div>
            <div class="item-value">
              <input v-model="infoData.name" type="text" :placeholder="$t('note.pla_4')">
            </div>
          </div>
          <template v-if="nodeInfo.my.uprate == 1">
            <div class="item">
              <div class="item-key">{{$t('note.prorate')}}：</div>
              <div class="item-value">
                <input v-model="infoData.rate" type="text" :placeholder="$t('note.pla_3')">
                <div class="item-rate">%</div>
              </div>
            </div>
            <div class="item">
              <div class="item-key">{{$t('note.allot')}}：</div>
              <div class="item-import">{{nodeInfo.my.rateKe}}%</div>
            </div>
          </template>
          <div class="item">
            <div class="item-key">{{$t('note.cycle')}}：</div>
            <div class="item-radio">
              <div class="item-radio-item" :class="{'item-radio-active': infoData.ratetype == 1}" @click="infoData.ratetype = 1">{{$t('note.day')}}</div>
              <div class="item-radio-item" :class="{'item-radio-active': infoData.ratetype == 2}" @click="infoData.ratetype = 2">{{$t('note.month')}}</div>
            </div>
          </div>
          <div class="item">
            <div class="item-key">{{$t('note.status')}}：</div>
            <div class="item-radio">
              <div class="item-radio-item" :class="{'item-radio-active': infoData.rewardstatus == 1}" @click="infoData.rewardstatus = 1">{{$t('note.normal')}}</div>
              <div class="item-radio-item" :class="{'item-radio-active': infoData.rewardstatus == 2}" @click="infoData.rewardstatus = 2">{{$t('note.pause')}}</div>
            </div>
          </div>
          <div class="item" v-if="!infoData.nodeid">
            <div class="item-key">{{$t('note.mode')}}：</div>
            <div class="item-radio">
              <div class="item-radio-item" :class="{'item-radio-active': infoData.uprate == 1}" @click="infoData.uprate = 1">{{$t('note.custom')}}</div>
              <div class="item-radio-item" :class="{'item-radio-active': infoData.uprate == 2}" @click="infoData.uprate = 2">{{$t('note.percent')}}</div>
            </div>
          </div>
        </div>
        <div class="btnArr">
          <div class="btnItem">
            <CommonBtn :btnText="infoData.nodeid == '' ? $t('note.submitAdd') : $t('note.submitEdit')" @btnClick="submit"></CommonBtn>
          </div>
          <img @click="del" v-if="infoData.nodeid" class="btnDel" src="@/assets/images/del.png" alt="">
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { NodeAdd, NodeEdit, GetUserByName, NodeInfo, NodeDel } from '@/api/user'

export default {
  name: 'NoteContent',
  components: {
  },
  data() {
    return {
      visible: false,
      infoData: {
        nodeid: '',
        name: '', // 节点名称
        user: '', // 用户名称
        addr: '',
        rate: '', // 分红比例,按业绩填0
        ratetype: '1',  // 1按天2按月
        rewardstatus: '1', // 发放状态 1正常2暂停
        uprate: '1', // 上级分配分红, 1按比例2按业绩
      },
      nodeInfo: {
        my: {}
      },
      pageType: 1, // 1新增 2删除
      btnLoading: false,
    }
  },
  watch: {
    infoData:{
      handler(newVal){
        console.log(newVal);
        if (this.infoData.user) {
          GetUserByName({name: this.infoData.user}).then(res => {
            if (res.list.length != 0) {
              this.infoData.addr = res.list[0].addr
            } else {
              this.infoData.addr = ''
            }
          })
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      account: state => state.wallet.account,
      userInfo: state => state.user.userInfo,
      systemInfo: state => state.system.systemInfo,
    }),
  },
  mounted() {
  },
  methods: {
    ...mapActions(['setResetPage']),
    async show(data) {
      if (data?.nodeid) {
        this.infoData = {
          nodeid: data.nodeid,
          name: data.name, // 节点名称
          user: data.username, // 用户名称
          addr: data.user,
          rate: data.rate, // 分红比例,按业绩填0
          ratetype: data.ratetype,  // 1按天2按月
          rewardstatus: data.rewardstatus, // 发放状态 1正常2暂停
          uprate: data.uprate, // 上级分配分红, 1按比例2按业绩
        }
      } else {
        this.infoData = {
          nodeid: '',
          name: '', // 节点名称
          user: '', // 用户名称
          addr: '',
          rate: this.nodeInfo.my.uprate != 1 ? '0' : '', // 分红比例,按业绩填0
          ratetype: '1',  // 1按天2按月
          rewardstatus: '1', // 发放状态 1正常2暂停
          uprate: '1', // 上级分配分红, 1按比例2按业绩
        }
      }
      await this.getData()
      this.visible = true
    },
    getData() {
      NodeInfo().then(res => {
        this.nodeInfo = res
      })
    },
    close() {
      this.visible = false
    },
    submit() {
      this.infoData = JSON.parse(JSON.stringify(this.infoData))
      if (this.infoData.name == '' || this.infoData.addr == '' || this.infoData.rate == '') {
        this.$toast(this.$t('toast.complete'))
        return
      }
      let req = this.infoData.nodeid ? NodeEdit : NodeAdd
      req(this.infoData).then(res => {
        console.log(res)
        this.$toast(this.$t('toast.submitSuc'))
        this.visible = false
        this.setResetPage(true)
        this.$emit('updatePage')
      }).catch(err => {
        this.$forceUpdate()
        console.log(err)
      })
    },
    del() {
      NodeDel({nodeid: this.infoData.nodeid}).then(() => {
        this.$toast(this.$t('toast.del'))
        this.visible = false
        this.$emit('updatePage')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
:root {
  --van-overlay-background-color: rgba(0, 0, 0, .4) ;
}
.NoteContent {
  border-radius: 40px 40px 0px 0px;
  .content {
    padding: 15px 22px;
    background: #292D32;
    box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px rgba(0, 0, 0, 0.66);

  }
  .header {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;
    .header-left {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .header-text {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #FFFFFF;
    }
    .header-icon {
      width: 16px;
      height: 16px;
    }
  }
  .detail {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .symbol {
      width: 29px;
      height: 6px;
      margin: 0 0 6px 0;
      margin-right: auto;
      background: linear-gradient(90deg, #F6DFA9 0%, #EDC67F 75.52%);
    }
    
  }
  .list {
    width: 100%;
    .item {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 20px 0;
      .item-key {
        width: 90px;
        margin-right: 8px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #B8B6B2;
      }
      .item-value {
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
        height: 41px;
        padding: 0 18px;
        background: #292D32;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -5px 4px rgba(80, 80, 80, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset -5px -8px 10px #30343A, inset 8px 8px 10px #24262C;
        border-radius: 20px;
        input {
          width: 100%;
          border: none;
          background: transparent;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          color: #B8B6B2;
        }
        input::placeholder {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          text-align: left;
          color: #B8B6B2;
        }
        .item-rate {
          margin-left: 4px;
          color: #B8B6B2;
        }
      }
      .item-show {
          display:flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          flex: 1;
          height: 41px;
          padding: 0 18px;
          font-weight: 400;
          font-size: 13px;
          text-align: left;
          color: #B8B6B2;
          word-break: break-all;
      }
      .item-address {
        flex: 1;
        flex-wrap: wrap;
        word-break: break-all;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        text-align: left;

        color: #FFFFFF;
      }
      .item-import {
        flex: 1;
        padding: 2px 18px;
        text-align: left;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #EFBB5E;
      }
      .item-radio {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 41px;
        background: #292D32;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -5px 4px rgba(80, 80, 80, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset -5px -8px 10px #30343A, inset 8px 8px 10px #24262C;
        border-radius: 20px;
        .item-radio-item {
          display:flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          flex: 1;
          height: 100%;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #B8B6B2;
        }
        .item-radio-active {
          background: linear-gradient(320.66deg, #F6DFA9 14.75%, #EDC67F 84.81%);
          box-shadow: 0px 2px 8px #000000, inset 0px 2px 2px #FFEABF, inset 0px -1px 1px #9E865A;
          border-radius: 100px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #75521A;
        }
      }
    }
  }
  .btnArr {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 20px 0;
    .btnItem {
      width: 302px;
      height: 40px;
    }
    .btnDel {
      width: 40px;
      height: 40px;
      margin-left: 20px;
    }
  }
}
</style>
