<template>
    <div class="Compose">
        <img class="back-btn" src="../../assets/images/icon_Return.png" alt="" @click="goBack()">
        
        <div class="grade-box" @click="this.sort_visible = true;">
            <span>{{ contract.name + ' - ' + attriList[parseInt(attriType)-1]}}</span>
            <img style="height: 7px; width: 11px; margin-left: 10px;" src="../../assets/images/icon_arrow3.png" alt="">
        </div>
        <van-list
            v-model:loading="loading"
            :finished="finished"
        >
            <div class="nft-list">
                <div class="nft-item" :class="{'marginl9': index%2==1}" v-for="(item, index) in list" :key="index" @click="checkNum(item.tokenId)">
                    <img class="nft-logo" v-lazy="fullImage(item.uri)" />
                    <div class="nft-text">
                        <span class="text-white">{{ contract.name + '-' + attribute(contract.lv, parseInt(item.tokenId)) }}</span>
                        <span class="text-grey">{{ '#'+item.tokenId }}</span>
                    </div>
                    <div class="nft-text">
                        <span class="text-grey">{{ piece.name + '-' + attribute(contract.lv, parseInt(item.tokenId)) }}</span>
                        <span :class="getAttributePiece(item.tokenId).length>=2?'text-green':'text-grey'">{{ getAttributePiece(item.tokenId).length+'/2' }}</span>
                    </div>
                    <div class="nft-text">
                        <span class="text-grey">MUD</span>
                        <span :class="mudBalance>=contract.need?'text-green':'text-grey'">{{ mudBalance+'/'+contract.need }}</span>
                    </div>
                </div>
            </div>
        </van-list>

        <van-popup
            v-model:show="visible"
            position="center"
            round
            style="background: none;"
            >
            <div class="compose-box" v-if="!admit&&!uping">
                <div class="compose-body">
                    <img class="del" src="../../assets/images/icon_delete1.png" alt="" @click="visible=false;uping=false;">
                    <div class="thing">{{ $t('nft.thing') }}</div>
                    <div class="piece-list">
                        <div class="item" v-for="(item, index) in getAttributePiece(targetId)" :key="index" @click="tapPiece(item)">
                            <img class="logo" v-lazy="fullImage(item.uri)" />
                            <div style="display: flex; flex-direction: column; justify-content: center; margin-left: 10px;">
                                <span class="text">{{ piece.name + '-' + attribute(contract.lv, parseInt(targetId)) }}</span>
                                <span class="text" style="margin-top: 3px;">{{ "#"+item.tokenId }}</span>
                            </div>
                            <div style="flex-grow: 1; display: flex; justify-content: flex-end;">
                                <div v-if="selected.indexOf(item)<0" class="unselected"></div>
                                <div v-else class="selected">
                                    <img class="icon" src="../../assets/images/icon_Selected1.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="height: 1px; width: 100%; background-color: #6A6960;"></div>
                    <div style="color: #787878; font-size: 13px; margin: 10px 0;">{{ $t('nft.pay') }}</div>
                    <div style="display: flex; justify-content: space-between; padding-right: 10px;">
                        <span style="font-size: 13px; color: white;">{{ $t('nft.fee') }}</span>
                        <span style="font-size: 13px; color: white;">{{ contract.need+' MUD' }}</span>
                    </div>
                    <div class="cbtn" @click="compose">{{ $t('nft.com_confirm') }}</div>
                </div>
                <div class="compose-head">{{ $t('nft.composeNft') }}</div>
            </div>
            <!-- 授权中 -->
            <div class="compose-box" v-else-if="uping">
                <div class="compose-body">
                    <div style="font-size: 18px; color: white; font-weight: 400; margin: 50px 0 10px 10px;">{{ $t('nft.allowing') }}</div>
                    <div style="display: flex; align-items: center;">
                        <div :class="approved1?'font_green':'font_grey'">{{ '1. 对 ' + piece.name + '-' + attribute(contract.lv, parseInt(targetId)) + ' #' +selected[0].tokenId + ' '+$t('nft.allow') }}</div>
                        <van-loading v-if="!approved1" style="margin: 8px 0 0 5px;" size="20px">
                            <div style="font-size: 12px;">{{ '預計5分鐘內完成' }}</div>
                        </van-loading>
                        <img v-if="approved1" style="height: 23px; margin: 8px 0 0 8px;" src="../../assets/images/image1.png" alt="">
                    </div>
                    <div style="display: flex; align-items: center;">
                        <div :class="approved2?'font_green':'font_grey'">{{ '2. 对 ' + piece.name + '-' + attribute(contract.lv, parseInt(targetId)) + ' #' +selected[1].tokenId + ' '+$t('nft.allow') }}</div>
                        <van-loading v-if="approved1&&!approved2" style="margin: 8px 0 0 5px;" size="20px">
                            <div style="font-size: 12px;">{{ '預計5分鐘內完成' }}</div>
                        </van-loading>
                        <img v-if="approved2" style="height: 23px; margin: 8px 0 0 8px;" src="../../assets/images/image1.png" alt="">
                    </div>
                    
                    <div class="font_grey">{{ '3. 提交 ' + contract.name + '-' + attribute(contract.lv, parseInt(targetId)) + ' #'+targetId+' '+$t('nft.composeDeal')  }}</div>
                    <div style="font-size: 14px; color: white; font-weight: 400; margin: 40px 0 10px 10px;">{{ $t('nft.allow_notice') }}</div>
                </div>
            </div>
            <!-- 上链提示 -->
            <div class="compose-box" v-else-if="admit">
                <div class="compose-body">
                    <div style="font-size: 18px; color: white; font-weight: 400; margin: 100px auto 10px auto;">{{ $t('nft.admited') }}</div>
                    <div style="font-size: 12px; color: rgba(255, 255, 255, 0.5); width: 250px; margin: 0 auto;">{{ $t('nft.admit_notice') }}</div>
                    <div style="display: flex; justify-content: space-between; margin-top: 50px; width: 100%">
                        <span style="font-size: 12px; color: rgba(255, 255, 255, 0.5);">{{ $t('nft.hash') }}</span>
                        <div style="width: 217px; height: 54px; word-wrap: break-word; text-align: end; font-size: 12px; color: white;">{{ hash }}</div>
                    </div>
                    <div style="display: flex; justify-content: flex-end; margin-top: 10px; align-items: center;">
                        <span style="font-size: 12px; color: rgba(255, 255, 255, 0.5);" @click="toExplorer">{{ $t('nft.toLine') }}</span>
                        <img style="height: 11px; width: 7px; margin-left: 5px;" src="../../assets/images/right_arrow.png" alt="">
                    </div>
                    <div class="bbtn" @click="backHome">{{ $t('nft.backHome') }}</div>
                </div>
                <img style="position: absolute; top: -15px; left: 0; right: 0; margin: 0 auto; height: 130px; width: 130px;" src="../../assets/images/image1.png" alt="">
            </div>
        </van-popup>

        <van-popup
            v-model:show="sort_visible"
            position="bottom"
            style="background: none;"
        >
            <Sort
                :closePop="()=>{sort_visible=false}"
                :sortCleanUp="sortCleanUp"
                :sortConfirm="sortConfirm"
                :sortType="1"
                :initLv="contract.lv"
                :initId="'1'"
            />
        </van-popup>
    </div>
</template>
  
<script>
  import { mapState } from 'vuex'
  import { nftList, myNft, nftLog, nftCompound } from '@/api/user'
  import Web3 from "web3"
  import { fullImageUrl, getContract, getAttribute } from '@/utils/filter'
  import upgradeAbi from '@/utils/upgradeAbi'
  import upgradeLegendAbi from '@/utils/upgradeLegendAbi'
  import coinAbi from '@/utils/coinAbi'
  import fractionAbi from '@/utils/fractionAbi'
  import commonAnimalAbi from '@/utils/commonAnimalAbi'
  import rareAnimalAbi from '@/utils/rareAnimalAbi'
  import epicAnimalAbi from '@/utils/epicAnimalAddrAbi.json'
  import Sort from '@/components/Sort'

  export default {
    name: 'Compose',
    components: {
        Sort
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system.systemInfo,
        userInfo: state => state.user.userInfo,
        account: state => state.wallet.account,
        mudBalance: state => state.wallet.mudBalance,
        mudAddr: state => state.wallet.mudAddr,
        upgradeAddr: state => state.wallet.upgradeAddr,
        upgradeLegendAddr: state => state.wallet.upgradeLegendAddr,
        fractionAddr:  state => state.wallet.fractionAddr, // 基本
        commonAnimalAddr:  state => state.wallet.commonAnimalAddr, // 普通（白银）
        rareAnimalAddr:  state => state.wallet.rareAnimalAddr, 
        epicAnimalAddr: state => state.wallet.epicAnimalAddr
      }),
    },
    data() {
        return {
            list: [],
            contract: {},
            piece: {},
            pieceList: [],
            visible: false,
            selected: [],
            targetId: [],
            hash: '',
            admit: false,
            empower: false,
            loading: false,
            uping: false,
            finished: false,
            approved1: false,
            approved2: false,
            attriList: ['金','木','水','火','土'],
            attriType: 1,
            sort_visible: false
        }
    },
    mounted() {
        this.getNft()
        this.getMyNft()
    },
    methods: {
        goBack(){
            this.$router.back();
        },
        backHome(){
            this.visible = false;
            this.goBack();
        },
        tapPiece(item){
            if(this.selected.indexOf(item)>=0){
                this.selected.splice(this.selected.indexOf(item),1)
            }else{
                if(this.selected.length<2){
                    this.selected.push(item)
                }
            }
        },
        getNft(lv){
            this.loading = true;
            this.finished = false;
            this.contract = getContract(lv??this.$route.query.lv)
            this.piece = getContract((parseInt(lv??this.$route.query.lv)-1).toString())
            nftList({address: getContract(lv??this.$route.query.lv).addr, type: this.attriType}).then((res)=>{
                this.list = res.list[parseInt(lv??this.$route.query.lv)-1].data;
                this.loading = false;
                this.finished = true;
            })
            
        },
        // 获取全部合成材料
        getMyNft(lv){
            myNft({address: getContract((parseInt(lv??this.$route.query.lv)-1).toString()).addr, type: this.attriType}).then((res)=>{
                this.pieceList = res.list[parseInt(lv??this.$route.query.lv)-2].data;
            })
        },
        // 获取对应属性合成材料
        getAttributePiece(id){
            return this.pieceList.filter(item => getAttribute(this.piece.lv, parseInt(item.tokenId)) == getAttribute(this.contract.lv, parseInt(id)))
        },
        backInit(){
            this.uping = false;
            this.approved1 = false;
            this.approved2 = false;
        },
        async compose(){
            if(this.selected.length<2){
                this.$toast('请选择合成所需'+this.piece.name)
                return;
            }
            
            this.uping = true;
            
            // if(this.uping)return;
            
            const web3 = new Web3(window.ethereum)
            const address = this.account
            
            // mud授权
            var val = '999999999999999'
            let mudContract = new web3.eth.Contract(coinAbi, this.mudAddr, {
                from: address
            })
            
            await mudContract.methods.allowance(address, this.upgradeAddr).call((error, result) => {
                console.log('approve', error, result)
                if(result<300000000){
                    mudContract.methods.approve(this.upgradeAddr, val).send((error, result)=>{
                        console.log('approve', error, result)
                        if (error) {
                            error = this.$t('toast.dealFail')
                            this.$toast(error)
                        }
                    })
                }
            })

            await mudContract.methods.allowance(address, this.upgradeLegendAddr).call((error, result) => {
                console.log('approve', error, result)
                if(result<300000000){
                    mudContract.methods.approve(this.upgradeLegendAddr, val).send((error, result)=>{
                        console.log('approve', error, result)
                        if (error) {
                            error = this.$t('toast.dealFail')
                            this.$toast(error)
                        }
                    })
                }
            })
            
            // 当前碎片授权
            if(this.contract.lv=='2'){
                let fractionContract = new web3.eth.Contract(fractionAbi, this.fractionAddr, {
                    from: address
                })
                fractionContract.methods.getApproved(parseInt(this.selected[0].tokenId)).call(async (error, result)=>{
                    console.log('getApproved', error, result)
                    if(result!=this.upgradeAddr){
                        fractionContract.methods.approve(this.upgradeAddr, parseInt(this.selected[0].tokenId)).send((error, result)=>{
                            console.log('approve', error, result)
                            
                            if (error)  {
                                this.backInit();
                                error = this.$t('toast.dealFail')
                                this.$toast(error)
                            }else{
                                setTimeout(()=>{
                                    this.lv2Approve()
                                },20000)
                            }
                        })
                    }else{
                        this.lv2Approve()
                    }
                })
            }else if(this.contract.lv=='3'){
                let commonContract = new web3.eth.Contract(commonAnimalAbi, this.commonAnimalAddr, {
                    from: address
                })
                commonContract.methods.getApproved(parseInt(this.selected[0].tokenId)).call(async (error, result)=>{
                    console.log('getApproved', error, result)
                    if(result!=this.upgradeAddr){
                        commonContract.methods.approve(this.upgradeAddr, parseInt(this.selected[0].tokenId)).send((error, result)=>{
                            console.log('approve', error, result)
                            
                            if (error)  {
                                this.backInit();
                                error = this.$t('toast.dealFail')
                                this.$toast(error)
                            }else{
                                setTimeout(()=>{
                                    this.lv3Approve()
                                },20000)
                            }
                        })
                    }else{
                        this.lv3Approve()
                    }
                })
            }else if(this.contract.lv=='4'){
                let rareContract = new web3.eth.Contract(rareAnimalAbi, this.rareAnimalAddr, {
                    from: address
                })
                rareContract.methods.getApproved(parseInt(this.selected[0].tokenId)).call(async (error, result)=>{
                    console.log('getApproved', error, result)
                    if(result!=this.upgradeAddr){
                        rareContract.methods.approve(this.upgradeAddr, parseInt(this.selected[0].tokenId)).send((error, result)=>{
                            console.log('approve', error, result)
                            
                            if (error)  {
                                this.backInit();
                                error = this.$t('toast.dealFail')
                                this.$toast(error)
                            }else{
                                setTimeout(()=>{
                                    this.lv4Approve()
                                },20000)
                            }
                        })
                    }else{
                        this.lv4Approve()
                    }
                })
            }else if(this.contract.lv=='5'){
                let epicContract = new web3.eth.Contract(epicAnimalAbi, this.epicAnimalAddr, {
                    from: address
                })
                epicContract.methods.getApproved(parseInt(this.selected[0].tokenId)).call(async (error, result)=>{
                    console.log('getApproved', error, result)
                    if(result!=this.upgradeLegendAddr){
                        epicContract.methods.approve(this.upgradeLegendAddr, parseInt(this.selected[0].tokenId)).send((error, result)=>{
                            console.log('approve', error, result)
                            
                            if (error)  {
                                this.backInit();
                                error = this.$t('toast.dealFail')
                                this.$toast(error)
                            }else{
                                setTimeout(()=>{
                                    this.lv5Approve()
                                },20000)
                            }
                        })
                    }else{
                        this.lv5Approve()
                    }
                })
            }

            
        },
        lv2Approve(){
            this.approved1 = true;

            const web3 = new Web3(window.ethereum)
            const address = this.account

            let fractionContract = new web3.eth.Contract(fractionAbi, this.fractionAddr, {
                from: address
            })
            fractionContract.methods.getApproved(parseInt(this.selected[1].tokenId)).call(async (error, result)=>{
                console.log('getApproved', error, result)
                if(result!=this.upgradeAddr){
                    fractionContract.methods.approve(this.upgradeAddr, parseInt(this.selected[1].tokenId)).send((error, result)=>{
                        console.log('approve', error, result)
                        
                        if (error) {
                            this.backInit();
                            error = this.$t('toast.dealFail')
                            this.$toast(error)
                        }else{
                            setTimeout(()=>{
                                this.admitCompose()
                            },20000)
                        }
                    })
                }else{
                    this.admitCompose()
                }
            })
        },
        lv3Approve(){
            this.approved1 = true;

            const web3 = new Web3(window.ethereum)
            const address = this.account

            let commonContract = new web3.eth.Contract(commonAnimalAbi, this.commonAnimalAddr, {
                from: address
            })
            commonContract.methods.getApproved(parseInt(this.selected[1].tokenId)).call(async (error, result)=>{
                console.log('getApproved', error, result)
                if(result!=this.upgradeAddr){
                    commonContract.methods.approve(this.upgradeAddr, parseInt(this.selected[1].tokenId)).send((error, result)=>{
                        console.log('approve', error, result)
                        
                        if (error) {
                            this.backInit();
                            error = this.$t('toast.dealFail')
                            this.$toast(error)
                        }else{
                            setTimeout(()=>{
                                this.admitCompose()
                            },20000)
                        }
                    })
                }else{
                    this.admitCompose()
                }
            })
        },
        lv4Approve(){
            this.approved1 = true;

            const web3 = new Web3(window.ethereum)
            const address = this.account

            let rareContract = new web3.eth.Contract(rareAnimalAbi, this.rareAnimalAddr, {
                from: address
            })
            rareContract.methods.getApproved(parseInt(this.selected[1].tokenId)).call(async (error, result)=>{
                console.log('getApproved', error, result)
                if(result!=this.upgradeAddr){
                    rareContract.methods.approve(this.upgradeAddr, parseInt(this.selected[1].tokenId)).send((error, result)=>{
                        console.log('approve', error, result)
                        
                        if (error) {
                            this.backInit();
                            error = this.$t('toast.dealFail')
                            this.$toast(error)
                        }else{
                            setTimeout(()=>{
                                this.admitCompose()
                            },20000)
                        }
                    })
                }else{
                    this.admitCompose()
                }
            })
        },
        lv5Approve(){
            this.approved1 = true;

            const web3 = new Web3(window.ethereum)
            const address = this.account

            let epicContract = new web3.eth.Contract(epicAnimalAbi, this.epicAnimalAddr, {
                from: address
            })
            epicContract.methods.getApproved(parseInt(this.selected[1].tokenId)).call(async (error, result)=>{
                console.log('getApproved', error, result)
                if(result!=this.upgradeLegendAddr){
                    epicContract.methods.approve(this.upgradeLegendAddr, parseInt(this.selected[1].tokenId)).send((error, result)=>{
                        console.log('approve', error, result)
                        
                        if (error) {
                            this.backInit();
                            error = this.$t('toast.dealFail')
                            this.$toast(error)
                        }else{
                            setTimeout(()=>{
                                this.admitCompose()
                            },20000)
                        }
                    })
                }else{
                    this.admitCompose()
                }
            })
        },
        async admitCompose(){
            this.approved2 = true;

            const web3 = new Web3(window.ethereum)
            const address = this.account

            let composeContract = this.contract.lv=='5'?(
                    new web3.eth.Contract(upgradeLegendAbi, this.upgradeLegendAddr, {
                    from: address, // default from address
                    // gasLimit: 1000000,
                    // gasPrice: 10000000000 // default gas price in wei, 10 gwei in this case
                })
            ):(new web3.eth.Contract(upgradeAbi, this.upgradeAddr, {
                from: address, // default from address
            }));
            let func = this.contract.lv=='2'?composeContract.methods.GetCommonAnimal:(this.contract.lv=='3'?composeContract.methods.GetRareAnimal:(this.contract.lv=='4'?composeContract.methods.GetEpicAnimal:composeContract.methods.GetLegend))
            let method = this.contract.lv=='2'?'GetCommonAnimal':(this.contract.lv=='3'?'GetRareAnimal':(this.contract.lv=='4'?'GetEpicAnimal':'GetLegend'));
            await func(parseInt(this.selected[0].tokenId), parseInt(this.selected[1].tokenId), parseInt(this.targetId))
            .send((error, result) => {
                console.log('comPose- Output:', error, result, '\n')
                if (!error) {
                    nftLog({
                        method: method,
                        tokenId1: parseInt(this.selected[0].tokenId),
                        tokenId2: parseInt(this.selected[1].tokenId),
                        expectedTokenId: parseInt(this.targetId),
                        hash: result
                    }).then(() => {
                        
                    })
                    this.hash = result;

                    this.visible = true;
                    this.admit = true;

                    this.click = false;
                    this.uping = false;
                } else {
                    error = this.$t('toast.dealFail')
                    this.backInit();
                    this.$toast(error)
                }
            });
        },
        fullImage(url){
            return fullImageUrl(url)
        },
        attribute(lv, id){
            return getAttribute(lv, id)
        },
        checkNum(id){
            
            if(this.getAttributePiece(id).length<2){
                this.$toast(this.piece.name+'-'+getAttribute(this.contract.lv, id)+' 不足')
                return;
            }else if(this.mudBalance<this.contract.need){
                this.$toast('MUD不足')
                return;
            }

            let method = this.contract.lv=='2'?'GetCommonAnimal':(this.contract.lv=='3'?'GetRareAnimal':(this.contract.lv=='4'?'GetEpicAnimal':'GetLegend'));
            nftCompound({method: method, expectedTokenId: parseInt(id)}).then((data)=>{
                if(data["compound"]){
                    this.targetId = id;
                    this.visible = true;

                    this.uping = false;
                    this.approved1 = false;
                    this.approved2 = false;
                    this.admit = false;
                }else{
                    this.$toast('此nft已被合成')
                    return;
                }
            })

            
        },
        toExplorer(){
            var url = 'https://polygonscan.com/tx/'+this.hash;
            window.open(url)
        },
        sortCleanUp(){
            this.sort_visible = false;
            this.getNft('0','0')
        },
        sortConfirm(lv, id){
            this.sort_visible = false;

            this.contract = getContract(lv);
            this.attriType = parseInt(id);

            this.getNft(lv)
            this.getMyNft(lv)
        }
    }
  }
</script>
<style lang="scss" scoped>
.Compose {
    position: relative;
    min-height: 100%;
    padding: 0 0px;
    padding-top: 20px;
    // padding-bottom: 70px;
    background: url(~@/assets/images/bg_NFT.png);
    background-size: 100% auto;
    background-position: 0 -40px;
}
.back-btn {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 20px;
    left: 20px;
}
.grade-box {
    height: 31.5px;
    width: 151px;
    margin: 0px auto 20px auto;
    background: url(~@/assets/images/btn_grade.png);
    background-size: 100% auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 13px;
    font-weight: 400;
}
.nft-list {
    display: flex;
    flex-wrap: wrap;
    width: 341px;
    margin: 0 auto;
    .nft-item {
        height: 222px;
        width: 166px;
        border-radius: 17px;
        background: #213335;
        display: flex;
        margin: 0 0 10px 0px;
        flex-direction: column;
        align-items: center;
        border: 1px solid #3E4C66;
        box-shadow: inset 0px 4px 21px #39456F;

        .nft-logo {
            height: 130px;
            width: 152px;
            border-radius: 10px;
            object-fit: cover;
            margin: 5px auto;
        }
        .nft-text {
            display: flex;
            width: 145px;
            margin: 5px auto 0 auto;
            justify-content: space-between;
            .text-white {
                color: white;
                font-size: 12px;
                font-weight: 400;
            }
            .text-grey {
                color: rgba(255, 255, 255, 0.5);
                font-size: 12px;
                font-weight: 400;
            }
            .text-green {
                color: #00C744;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
    .marginl9 {
        margin-left: 9px;
    }
}
.compose-box {
    height: 500px;
    width: 341px;
    position: relative;
    .compose-head {
        position: absolute;
        height: 45px;
        width: 193px;
        background: url(~@/assets/images/img_popup1.png);
        background-size: 100% auto;
        left: 0;
        right: 0;
        top: 10px;
        margin: 0 auto;
        color: white;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .compose-body {
        height: 450px;
        width: 341px;
        background: url(~@/assets/images/img_popup.png);
        background-size: 100% auto;
        position: absolute;
        top: 30px;
        display: flex;
        flex-direction: column;
        padding: 0 20px 0 20px;
        .del {
            height: 25px;
            width: 25px;
            margin: 20px 0 0 90%;
        }
        .thing {
            font-size: 14px;
            color: white;
            margin: 10px 0 10px 0;
        }
        .piece-list {
            height: 200px;
            overflow-y: auto;
            // display: -webkit-box;
            white-space: nowrap;
            margin-bottom: 20px;
            .item {
                display: flex;
                height: 54px;
                margin-top: 10px;
                align-items: center;
                padding-right: 8px;
                .logo {
                    height: 54px;
                    width: 54px;
                    border-radius: 10px;
                    object-fit: cover;
                }
                .text {
                    font-size: 13px;
                    color: white;
                    font-weight: 400;
                }
                .unselected {
                    height: 18px;
                    width: 18px;
                    background: #6A6961;
                    border-radius: 2px;
                }
                .selected {
                    height: 18px;
                    width: 18px;
                    background: #FFE600;
                    border-radius: 2px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .icon {
                        height: 12px;
                        width: 12px;
                    }
                }
            }
        }
        .cbtn {
            height: 45px;
            width: 240px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: url(~@/assets/images/btn_confirm1.png);
            background-size: 100% 100%;
            margin: 20px auto;
        }
        .bbtn {
            height: 45px;
            width: 138px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: url(~@/assets/images/btn_return.png);
            background-size: 100% auto;
            font-size: 14px;
            color: white;
            font-weight: 400;
            margin: 30px auto;
        }
    }
}
.grade {
    width: 100%;
    height: 180px;
    background: #121E1F;
    display: flex;
    flex-direction: column;
    .item {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 14px;
    }
}
.font_green {
    color: #00C744;
    font-size: 16px;
    font-weight: 400; 
    margin: 20px 0 10px 20px;
}
.font_grey {
    font-size: 16px; 
    color: rgba(255, 255, 255, 0.5); 
    font-weight: 400; 
    margin: 20px 0 10px 20px;
}
</style>
  
  