<template>
  <div class="Message">
    <div class="main" v-html="infoData.content"></div>
  </div>
</template>

<script>
import { GetRule } from '@/api/system'
import { newsDetail } from '../../api/user'
export default {
  name: 'Message',
  components: {
  },
  data() {
    return {
      infoData: {},
    }
  },
  mounted() {
    let type = this.$route.query.type || ''
    let id = this.$route.query.id || ''
    this.getData(type, id)
  },
  methods: {
    async getData(type, id) {
      console.log(type);
      if (type==2) {
        await GetRule({type: type}).then(res => {
          this.infoData = res
        })
      }else if(type==1){
        await newsDetail({id: parseInt(id)}).then(res=>{
          this.infoData = res
        })
      }
      
    },
  }
}
</script>
<style lang="scss" scoped>
.Message {
  min-height: calc(100vh - 45px);
  padding: 20px 15px 20px 15px;
  background: url(~@/assets/images/bg.png);
  background-size: auto;
  background-repeat: repeat;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;

  color: #B8B6B2;
}
</style>
<style>
  .Message img {
    max-width: 100%;
    height: auto;
  }
</style>

