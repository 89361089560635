<template>
  <div class="Home">
    <div class="tips">
      <div class="tips-title">{{$t('home.pledge')}} {{systemInfo.tkname}} {{$t('home.vote')}}</div>
      <div class="tips-sub">
        {{$t('home.partition')}}
        <div class="tips-sub-num">{{indexInfo.day}}</div>
      </div>
      <div class="tips-op"></div>
      <div class="tips-item">
        <img src="@/assets/images/btn_Total.png" alt="">
        <div class="tips-item-text">{{$t('home.network')}}</div>
        <div class="tips-item-num">{{indexInfo.all}}</div>
      </div>
      <div class="tips-item">
        <img src="@/assets/images/btn_Award.png" alt="">
        <div class="tips-item-text">{{$t('home.yesterday')}}</div>
        <div class="tips-item-num">{{indexInfo.lastReward}}</div>
      </div>
      <div class="tips-item">
        <img src="@/assets/images/btn_Quantity.png" alt="">
        <div class="tips-item-text">{{$t('home.destroy')}}</div>
        <div class="tips-item-num">{{indexInfo.lastDestroy}}</div>
      </div>
    </div>
    <div id="svgaplayerweb" class="svg">
      <img src="https://img.metadot.vip/img_zhiya2.png" alt="">
    </div>
    <div class="asset">
      <div class="asset-head" @click="toAsset">
        <div class="">{{$t('home.assets')}}：{{userAll}} {{systemInfo.tkname}}</div>
        <img src="@/assets/images/btn_RightArrow.png" alt="">
      </div>
      <div class="asset-symbol">
        <div class="as-side"></div>
        <div class="as-center"></div>
        <div class="as-side"></div>
      </div>
      <div class="asset-desc">
        <div class="asset-desc-item">
          <div class="asset-desc-key">{{$t('common.myPledge')}}({{systemInfo.tkname}})</div>
          <div class="asset-desc-value">{{userPledge}}</div>
        </div>
        <div class="asset-desc-item">
          <div class="asset-desc-key">{{$t('home.daily')}}({{systemInfo.tkname}})</div>
          <div class="asset-desc-value">{{indexInfo.userReward}}</div>
        </div>
      </div>
    </div>
    <!-- <div v-if="imgUrl" class="advertising" @click="toInvite">
      <img :src="imgUrl" alt="">
    </div> -->
    <div class="news" v-if="newslist.length>0">
      <div v-for="(item, index) in newslist" :key="index" @click="goDetail(item)">
        <div class="item-detail" v-if="index==0">
          <div class="left-content">
            <div class="title">{{ $t('home.news') }}</div>
            <div class="two-lines">{{ item['intro'] }}</div>
            <div class="todetail">{{ $t('home.detail') }}</div>
          </div>
          <img class="right-pic" :src="item['logo']" alt="">
        </div>
        <div v-else class="news-item">
          <div class="news-title">{{ item['title'] }}</div>
          <img class="arrow-right" src="@/assets/images/right_arrow.png" alt="">
        </div>
      </div>
    </div>
    <div class="product">
      <!-- <div class="product-head">
        <div class="ph-title">
          <div class="pht-side"></div>
          <div class="pht-text">{{$t('home.list')}}</div>
          <div class="pht-side"></div>
        </div>
        <img class="ph-img1" src="@/assets/images/img_Arrow.png" alt="">
        <img class="ph-img2" src="@/assets/images/img_RoundDial.png" alt="">
      </div> -->
      <div class="product-tab">
        <div class="product-tab-item" :class="listType == 2 ? 'product-tab-item-active' : ''" @click="changeType(2)">{{ $t('home.list_tab_1') }}</div>
        <div class="product-tab-item" :class="listType == 1 ? 'product-tab-item-active' : ''" @click="changeType(1)">{{ $t('home.list_tab_2') }}</div>
      </div>
      <div class="list">
        <div class="item" v-for="(o, i) in pledgeList" :key="i">
          <div class="item-main">
            <div class="item-desc">
              <div class="id-detail">
                <div class="idd-flex">
                  <div class="iddf-key">{{$t('home.year')}}</div>
                  <div class="iddf-value">{{o.rate}}%</div>
                </div>
                <div class="idd-flex">
                  <div class="iddf-key">{{$t('home.date')}}</div>
                  <div class="iddf-value">{{o.cycle}}{{$t('common.day')}}</div>
                </div>
              </div>
              <div class="item-reward">{{$t('home.tip')}}：{{o.reward}} {{systemInfo.tkname}}</div>
            </div>
            <div class="item-btn">
              <CommonBtn :btnText="$t('home.join')" @btnClick="toDetail"></CommonBtn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TabBar :active="0"></TabBar>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import { IndexInfo, PledgeList } from '@/api/system'
import TabBar from '@/components/TabBar'
import SVGA from 'svgaplayerweb'
import { newsList } from '../api/user'
export default {
  name: 'Home',
  components: {
    TabBar
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
      account: state => state.wallet.account,
      angleNum: state => state.wallet.angleNum,
      abNum: state => state.wallet.abNum,
      teamNum: state => state.wallet.teamNum,
      pledgeNum: state => state.wallet.pledgeNum,
      miningExtracted: state => state.wallet.miningExtracted,
    }),
    userAll() {
      if (this.angleNum[1] == '-' || this.abNum[1] == '-' || this.teamNum[1] == '-' || this.pledgeNum[1] == '-') {
        return '-'
      } else {
        return this.angleNum[1] + this.abNum[1] + this.teamNum[1] + this.pledgeNum[1]
      }
      
    },
    userPledge() {
      return this.pledgeNum[1]
    }
  },
  watch: {
    account() {
      this.checkBalance()
      this.miningCheckBalance()
    }
  },
  data() {
    return {
      active: 0,
      indexInfo: {
        userReward: ''
      },
      pledgeList: [],
      imgUrl: '',
      listType: 2, // 2流动性质押 1单币质押
      newslist: []
    }
  },
  async mounted() {
    
    await this.getData()
    // window.scrollTo(0,0)
    // await setTimeout(() => {
    //   this.loadSvga()
    // }, 400);
    
  },
  methods: {
    ...mapActions(['SetMaskLoading', 'SetLoadingText', 'checkBalance', 'miningCheckBalance']),
    async getData() {
      await IndexInfo().then(res => {
        this.indexInfo = res
      })
      await this.getList()
      await this.getNews()
      if (this.systemInfo && this.systemInfo.banner) {
        let imgArr = this.systemInfo.banner.filter(item => item.type == 1) // 1首页2我的3团队
        let imgObj = JSON.parse(JSON.stringify(imgArr[0]))
        this.imgUrl = '/' + imgObj.url
      }
      await this.checkBalance()
      await this.miningCheckBalance()
    },
    async getList() {
      this.SetLoadingText(this.$t('common.loading'))
      this.SetMaskLoading(true)
      await PledgeList({type: this.listType}).then(res => {
        this.SetMaskLoading(false)
        this.pledgeList = res
      }).catch(() => {
        this.SetMaskLoading(false)
      })
    },
    async getNews() {
      await newsList().then(res => {
        if(res.list.length<=3){
          this.newslist = res.list;
        }else{
          this.newslist = res.list.slice(0,3);
        }
      })
    },
    changeType(type) {
      this.listType = type
      this.pledgeList = []
      this.getList()
    },
    loadSvga () {
      var parser = new SVGA.Parser('#svgaplayerweb')
      var player = new SVGA.Player('#svgaplayerweb')
      
      parser.load(`/home_bg.svga`, function (videoItem) {
        player.setContentMode("AspectFit");
        player.setVideoItem(videoItem);
        player.startAnimation();
      }, function (error) {
        console.log(error)
      })
    },
    toAsset() {
      this.$router.push('/asset')
    },
    toInvite() {
      this.$router.push('/invite')
    },
    toDetail() {
      if (this.listType == 2) {
        this.$router.push('/liquidity')
      } else {
        this.$router.push('/exchange')
      }
      
    },
    goDetail(item) {
        // 链接类型 1不跳转 2内链 3外链
        switch(item['type']){
            case '1':
                this.$router.push('/message?type=1&&id='+item['id'].toString())
                break;
            case '2':
                this.$router.push(item['url'])
                break;
            case '3':
                window.open(item['url'])
                break;
        }
      }
  }
}
</script>
<style lang="scss" scoped>
.Home {
  box-sizing: border-box;
  height: auto;
  padding: 1px 0;
  padding-bottom: 70px;
  padding-top: 42px;
  margin-top: -40px;
  background: url(~@/assets/images/bg_home2.png);
  background-size: 100% auto;
  background-position: 0 -40px;
  .tips {
    // height: 169px;
    margin: 38px 0 0 30px;
    .tips-title {
      font-family: 'Alibaba PuHuiTi';
      font-style: normal;
      font-weight: 400;
      font-size: 26px;
      line-height: 36px;
      color: #FFFFFF;
      text-shadow: 3px 3px 3px rgba($color: #eeeeee, $alpha: 0.4);
    }
    .tips-sub {
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 11px;
      font-family: 'Alibaba PuHuiTi';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      text-align: center;

      color: #FFFFFF;
    }
    .tips-sub-num {
      margin-left: 6px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;

      color: #EDC67F;
    }
    .tips-op {
      width: 144px;
      height: 1px;
      margin: 6px 4px 12px 4px;
      background: linear-gradient(90deg, #F6DFA9 0%, rgba(246, 223, 169, 0) 95.83%);


    }
    .tips-item {
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 8px;
      img {
        width: 18px;
        height: 18px;
      }
      .tips-item-text {
        max-width: 240px;
        margin: 0 9px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #FFFFFF;
      }
      .tips-item-num {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        color: #EDC67F;
      }
    }
  }
  .svg {
    width: 100%;
    height: 346px;
    background: #000000;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .asset {
    box-sizing: border-box;
    width: 345px;
    // height: 200px;
    margin: 0 15px;
    margin-bottom: 28px;
    padding: 26px 10px;

    background: #202122;
    // box-shadow: -6px -13px 12px -8px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D;
    border-radius: 40px;
    box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.4);

    .asset-head {
      position: relative;
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      font-family: 'Alibaba PuHuiTi';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      color: #A5A8AA;
      img {
        position: absolute;
        right: -10px;
        top: -16px;
        width: 70px;
        height: 70px;
      }
    }
    .asset-symbol {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 22px 0;
      .as-side {
        width: 8.02px;
        height: 8px;
        background: #272A2E;
        box-shadow: inset 0px -1px 1px #454647;
        border-radius: 50%;
      }
      .as-center {
        width: 262px;
        height: 9px;
        margin: 0 7px;
        background: rgba(36, 37, 40, 0.7);
        box-shadow: inset -2px -2px 4px rgba(255, 255, 255, 0.08), inset 2px 2px 4px rgba(0, 0, 0, 0.8);
        border-radius: 25px;
      }
    }
    .asset-desc {
      display: flex;
      margin: 0 6px;
      .asset-desc-item {
        flex: 1;
      }
      .asset-desc-key {
        margin-bottom: 5px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #9F8E65;
      }
      .asset-desc-value {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
      }
    }
  }
  .advertising {
    width: 345px;
    height: 66px;
    margin: 0 auto;
    margin-bottom: 28px;
    border-radius: 6px;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D;
    img {
      width: 100%;
      height: 100%;
    }  
  }
  .product {
    .product-tab {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      // margin: 0 -8px;
      margin-bottom: 18px;
      .product-tab-item {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex: 1;
        margin: 0px 4px;
        padding: 17px 0;
        border-radius: 20px;
        background: #292D32;
        box-shadow: 8px 8px 10px 0px #24262C inset, -5px -8px 10px 0px #30343A inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset, 0px -5px 4px 0px rgba(80, 80, 80, 0.25) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: #948E8E;
        font-family: PingFang SC;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
      }
      .product-tab-item-active {
        color: #75521A;
        font-family: PingFang SC;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        border-radius: 20px;
        background: linear-gradient(321deg, #F6DFA9 0%, #EDC67F 100%);
        box-shadow: 0px 4px 8px 0px #000, -1px -2px 7px 0px rgba(254, 254, 254, 0.25), 0px -2px 1px 0px #9E865A inset, 0px 1px 1px 0px #FFFAEB inset;
      }
    }
    .product-head {
      position: relative;
      width: 100%;
      padding: 0 20px;
      margin-bottom: 28px;
      .ph-img1 {
        position: absolute;
        right: 100px;
        bottom: 0;
        width: 55px;
        height: 26px;
      }
      .ph-img2 {
        position: absolute;
        right: 10px;
        bottom: -10px;
        width: 74px;
        height: 73px;
      }
      .ph-title {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 194px;
        height: 59px;
        background: #292D32;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -5px 4px rgba(80, 80, 80, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset -5px -8px 10px #30343A, inset 8px 8px 10px #24262C;
        border-radius: 20px;
        .pht-side {
          width: 8.02px;
          height: 8px;
          background: #191B1E;
          box-shadow: inset 0px -1px 1px #454647;
          border-radius: 50%;
        }
        .pht-text {
          margin: 0 15px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          color: #948E8E;
        }
      }
    }
  }
  .list {
    width: 100%;
    min-height: 400px;
    .item {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 344px;
      height: 124px;
      margin: 15px auto;
      background: #292D32;
      box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D;
      border-radius: 36px;
      .item-main {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 326px;
        height: 105px;
        padding: 16px 17px;
        background: linear-gradient(180deg, #353A40 0%, #22252A 100%);
        box-shadow: 0px 3px 6px #2F2A1F;
        border-radius: 30px;
        border: 1px solid;
        border-image-source: linear-gradient(96.14deg, #000000 4.71%, #000000 59.77%, #000000 78.1%, #000000 90.8%, #514009 96.44%);
      }
      .item-desc {
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 190px;
        height: 100%;
        .id-detail {
          display:flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 100%;
          .idd-flex {
            flex: 1;
          }
          .iddf-key {
            margin-bottom: 4px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            color: #9F8E65;
          }
          .iddf-value {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
          }
        }
        .item-reward {
          width: auto;
          white-space: nowrap;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #9F8E65;  
        }
      }
      .item-btn {
        width: 83px;
        height: 35px;
        margin-left: 10px;
      }
    }
  }

  .news {
    width: 92%;
    padding: 10px 30px;
    margin: 0px auto 30px auto;
    background: #202122;
    // box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-content: center;
    .item-detail {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .title {
        color: #9F8E65;
        font-size: 12px;
      }
      .left-content {
        display: flex;
        flex-direction: column;
      }
      .right-pic {
        height: 78px;
        width: 78px;
        border-radius: 10px;
        overflow: hidden;
      }
      .two-lines {
        font-size: 13px;
        color: white;
        font-weight: 400;
        width: 183px;
        line-height: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 最大显示两行 */
        -webkit-box-orient: vertical; /* 垂直方向排列 */
        text-overflow: ellipsis; /* 超出部分省略表示 */
        overflow: hidden;
        margin: 10px 0;
      }
      .todetail {
        font-size: 12px;
        color: #FEFEFE69;
        margin-bottom: 10px;
      }
    }
    .news-item {
      width: 100%;
      height: 47px;
      border-top: 1px solid #000;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .news-title {
      font-size: 13px;
      color: white;
      font-weight: 400;
      width: 247px;
      line-height: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .arrow-right {
      height: 9px;
      width: 5px;
    }
  }
}
</style>

