<template>
  <div class="Pdf">
    <!-- <div id="demo"></div> -->
    <div class="pdf-main" v-html="ruleInfo.content"></div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { GetRule } from '@/api/system'
// import Pdfh5 from "pdfh5"
// import "pdfh5/css/pdfh5.css"
export default {
  name: 'Pdf',
  components: {
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
    }),
  },
  data() {
    return {
      pdfh5: null,
      ruleInfo: {}
    }
  },
  mounted() {
    // setTimeout(() => {
    //   this.SetMaskLoading(true)
    //   this.SetLoadingText('Loading..')
    //   //实例化
    //   this.pdfh5 = new Pdfh5("#demo", {
    //     pdfurl: this.systemInfo.pdf
    //   });
    //   //监听完成事件
    //   this.pdfh5.on("complete", function (status, msg, time) {
    //     this.SetMaskLoading(false)
    //     console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
    //   })
    // }, 500);
    GetRule({type: 1}).then(res => {
      this.ruleInfo = res
      this.ruleInfo.content = this.ruleInfo.content.replace(/<img/g, '<img style="width: 100%;height:auto;" ')
    })
  },
  methods: {
    ...mapActions(['SetMaskLoading', 'SetLoadingText']),
  }
}
</script>
<style lang="scss" scoped>
.Pdf {
  min-height: calc(100vh - 45px);
  padding: 20px 15px 20px 15px;
  background: url(~@/assets/images/bg.png);
  background-size: auto;
  background-repeat: repeat;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;

  color: #B8B6B2;
}
#demo {
  width: 100%;
  height: 100%;
}
</style>

