<template>
  <div class="Team">
    <div class="main main2">
      <div class="main-award">
        <div class="ma-key">{{$t('asset.asset')}}({{systemInfo.tkname}})</div>
        <div class="ma-value">{{teamTotal}}</div>
      </div>
      <div class="main-list main-list-col">
        <div class="main-row">
          <div class="main-item">
              <div class="mi-key">{{$t('asset.team')}}({{systemInfo.tkname}}) 
                <img @click="openNotice" src="@/assets/images/icon_Tooltip.png" alt="">
              </div>
              <div class="mi-value">{{teamNum[0]}}</div>
          </div>
          <div class="main-btn">
            <CommonBtn :btnText="$t('asset.btn_2')" :btnType="2"  @btnClick="openWithdraw(1, 3)"></CommonBtn>
          </div>
        </div>
      </div>
    </div>
    <div class="record">
      <div class="record-title">{{$t('asset.bill')}}</div>
      <div class="record-symbol"></div>
      <!-- <div class="tab">
        <div class="tab-item" v-for="(o, i) in typeArr" :key="i" :class="{'tab-active': logType == i}" @click="changeTabIndex(i)">
          <div class="tab-text">{{o.name}}</div>
          <div class="tab-op"></div>
        </div>
      </div> -->
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text=""
        @load="onLoad"
      >
        <div class="record-list">
          <div class="item" v-for="(o, i) in assetList" :key="i">
            <div class="item-desc">
              <div class="item-title">{{o.title}}</div>
              <div class="item-time">{{o.time}}</div>
            </div>
            <div class="item-value" :class="o.value >= 0 ? 'color-up' : 'color-down'">{{o.value}}</div>
          </div>
        </div>
      </van-list>
      <NoData v-if="assetList.length == 0 && !firstLoad"></NoData>
    </div>
    <Withdraw ref="Withdraw" @refresh="refresh"></Withdraw>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { MiningAccount, MiningLog, NetworkAccount, NetworkLog } from '@/api/user'
import Withdraw from '@/components/Withdraw'
import NoData from '@/components/NoData'
export default {
  name: 'Team',
  components: {
    Withdraw,
    NoData
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
      account: state => state.wallet.account,
      mudBalance: state => state.wallet.mudBalance,
      angleNum: state => state.wallet.angleNum,
      abNum: state => state.wallet.abNum,
      teamNum: state => state.wallet.teamNum,
      pledgeNum: state => state.wallet.pledgeNum,
      pledgeAddr: state => state.wallet.pledgeAddr,
      miningAddr: state => state.wallet.miningAddr,
      decimals: state => state.wallet.decimals,
      miningExtracted: state => state.wallet.miningExtracted,
    }),
    teamTotal () {
      if (this.teamNum[1] == '-') {
        return '-'
      } else {
        return this.teamNum[1]
      }
    }
  },
  watch: {
    async account() {
      if (this.account) {
        await this.checkBalance()
        await this.getMiningInfo()
      }
    },
    pageType() {
      if (this.pageType == 0) {
        this.getMiningInfo()
      } else {
        this.checkBalance()
      }
    }
  },
  data() {
    return {
      pageType: 0, // 0 挖矿 1 全网
      assetInfo: {},
      assetList: [],
      start: 0,
      limit: 10,
      firstLoad: true,
      loading: false,
      finished: false,
      award: '-', // 可提取奖励
      pledge: '-', // 质押中
      extractable: '-', // 可提取质押数

      logType: 0, // 类型 0全部1质押2挖矿奖励3节点奖励7提取
      typeArr: [
        {
          name: this.$t('asset.tab_1')
        },
        {
          name: this.$t('asset.tab_2')
        },
        {
          name: this.$t('asset.tab_3')
        },
        {
          name: this.$t('asset.tab_4')
        },
        {
          name: this.$t('asset.tab_5')
        }
      ]
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapActions(['GetUserInfo', 'setNoticeType', 'checkBalance', 'miningCheckBalance']),
    async getData() {
      let req = this.pageType == 0 ? MiningAccount : NetworkAccount
      await req().then(res => {
        this.assetInfo = res
      })
      if (this.account) {
        await this.GetUserInfo()
        await this.getMiningInfo()
      }
    },
    async getMiningInfo() {

      await this.miningCheckBalance()
      this.award = this.miningExtracted

      await this.checkBalance(4)
      this.extractable = this.pledgeNum[0]
      this.pledge = this.pledgeNum[1]
    },
    refresh() {
      if (this.pageType == 0) {
        this.getMiningInfo()
      } else {
        this.checkBalance()
      }
    },
    changeStatus(type) {
      this.pageType = type
      this.getData()

      this.start = 0
      this.assetList = []
      this.firstLoad = true
      this.finished = false
      // this.onLoad()
    },
    onLoad() {
      let req = this.pageType == 0 ? MiningLog : NetworkLog
      req({start: this.start, limit: this.limit, type: this.logType}).then(res => {
        if (this.firstLoad) {
          this.firstLoad = false
        }
        this.assetList = this.assetList.concat(res.list)

        this.assetList = []
        res.count = 0


        if (this.assetList.length >= res.count) {
          this.finished = true
        } else {
          this.finished = false
        }
        this.start = this.assetList.length
        this.loading = false
        
      })
    },
    toRecord() {
      this.$router.push('/record')
    },
    openWithdraw(type, status) {
      if (type == 1 && status == 3 && this.teamNum[0] == 0) {
        this.$toast(this.$t('toast.noBalance'))
        return
      }
      this.$refs.Withdraw.show(type, status)
    },
    openNotice() {
      this.setNoticeType(12)
    },
    changeTabIndex(type) {
      this.logType = type

      this.start = 0
      this.assetList = []
      this.firstLoad = true
      this.finished = false
      this.onLoad()
    },
  }
}
</script>
<style lang="scss" scoped>
.Team {
  position: relative;
  min-height: calc(100vh - 50px);
  padding: 0 15px;
  padding-top: 40px;
  padding-bottom: 70px;
  background: url(~@/assets/images/bg.png);
  background-size: 100% auto;
  background-position: 0 -40px;
  .main {
    width: 100%;
    padding: 30px 24px;
    margin-bottom: 24px;
    border-radius: 40px;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D, inset 5px 11px 20px #000000;
    .main-award {
      margin-bottom: 0px;
      .ma-key {
        margin-bottom: 6px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #A5A8AA;
      }
      .ma-value {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        color: #FFFFFF;
      }
    }
    .main-yesterday {
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 22px;
      .my-key {
        margin-right: 14px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #A5A8AA;
      }
      .my-num {
        margin-right: 9px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #A5A8AA;
      }
      .my-rate {
        padding: 2px 9px;
        background: #292D32;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -5px 4px rgba(80, 80, 80, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset -5px -8px 10px #30343A, inset 8px 8px 10px #24262C;
        border-radius: 20px;
        color: #A5A8AA;
      }
      .my-rate-up {
        color: $color-gold;
      }
    }
    .main-list {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 32px;
      .main-row {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px 0;
        margin: 2px 0;
        .main-btn {
          width: 90px;
          height: 36px;
          margin-left: 20px;
        }
      }
      .main-row:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
      .main-item {
        flex: 1;
        .mi-key {
          margin-bottom: 5px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #A5A8AA;
          img {
            width: 25px;
            height: 25px;
          }
        }
        .mi-value {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #FFFFFF;
        }
      }
    }
    .main-list-col {
      flex-direction: column;
    }
    .main-btns {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .main-btn {
        width: 137px;
        height: 41px;
      }
      .main-btnmain {
        width: 284px;
        height: 40px;
        margin: 10px auto;
      }
    }
  }
  .main1 {
    height: 291px;
    background: url(~@/assets/images/bg_Mining.png);
    background-size: 100% auto;
  }
  .main2 {
    height: 180px;
    padding: 36px 24px;
    // background: url(~@/assets/images/bg_WholeNetwork.png);
    background: url(~@/assets/images/bg_Mining.png);
    background-size: 100% auto;
  }
  .record {
    width: 100%;
    padding: 24px 30px;
    background: #202122;
    // box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D, inset 5px 11px 20px #000000;
    border-radius: 40px;
    .record-title {
      margin-bottom: 7px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #FFFFFF;
    }
    .record-symbol {
      width: 29px;
      height: 6px;
      margin-bottom: 7px;
      background: linear-gradient(90deg, #F6DFA9 0%, #EDC67F 75.52%);
    }
    .record-list {
      .item {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 70px;
      }
      .item:not(:last-child) {
        border-bottom: 1px solid #000000;
      }
      .item-desc {
        flex: 1;
        margin-right: 20px;
      }
      .item-title {
        margin-bottom: 2px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
      }
      .item-time {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #B8B6B2;
      }
      .item-value {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
  .tab {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: calc(100% + 40px);
    margin: 0 -20px;
    margin-bottom: 16px;
    .tab-item {
      position: relative;
      flex: 1;
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .tab-text {
      margin-bottom: 4px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #A5A8AA;
    }
    .tab-op {
      width: 28px;
      height: 4px;
      background: transparent;
    }
    .tab-active {
      .tab-text {
        color: #FFFFFF;
      }
      .tab-op {
        background: #EDC071;
        box-shadow: inset 0px -1px 2px #D28B0E;
        border-radius: 25px;
      }
    }
  }
}
</style>

