<template>
  <div class="Contact">
    <div class="list">
      <div class="item" v-for="(o, i) in contactList" :key="i" @click="copy(o)">
        <img :src="o.img" alt="">
        <div class="item-name">{{o.name}}</div>
        <div class="item-url">{{o.url}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import Clipboard from "clipboard"
export default {
  name: 'Contact',
  components: {
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
    })
  },
  watch: {
    systemInfo() {
      this.getData()
    }
  },
  data() {
    return {
      contactList: [],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapActions([]),
    getData() {
      this.contactList = [
        {
          img: require('@/assets/images/icon_mailbox.png'),
          name: 'Email',
          url: this.systemInfo?.email || '-',
        },
        {
          img: require('@/assets/images/icon_Facebook.png'),
          name: 'Facebook',
          url: this.systemInfo?.facbook || '-',
        },
        {
          img: require('@/assets/images/icon_Twitter.png'),
          name: 'Twitter',
          url: this.systemInfo?.twitter || '-',
        },
        {
          img: require('@/assets/images/icon_Telgram.png'),
          name: 'Telgram',
          url: this.systemInfo?.telgram || '-',
        },
      ]
    },
    copy(o) {
      if (o.name == 'Email') {
        let random = o.url
        const input = document.createElement('input');
        document.body.appendChild(input);
        input.setAttribute('value',random);
        input.select();
        document.execCommand("Copy");
        this.$toast(`${this.$t('common.copySuccess')}！`)
      } else if (o.name == 'Facebook') {
        window.open(`https://www.facebook.com/${o.url}`)
      } else if (o.name == 'Telgram') {
        window.open(`https://t.me/${o.url}`)
      } else if (o.name == 'Twitter') {
        window.open(`https://twitter.com/${o.url}`)
      }
      
      // let className = '.ll-img-url'
      // let text = url
      // let clipboard = new Clipboard(className, {
      //   text: () => {
      //     return text
      //   }
      // })
      // clipboard.on('success', () => {
      //   this.$toast(`${this.$t('common.copySuccess')}！`)
      //   clipboard.destroy()
      // })
      // clipboard.on('error', () => {
      //   this.$toast(`${this.$t('common.copyFail')}！`)
      //   clipboard.destroy()
      // })
    },
  }
}
</script>
<style lang="scss" scoped>
.Contact {
  position: relative;
  min-height: calc(100vh - 50px);
  padding: 0 15px;
  padding-top: 10px;
  padding-bottom: 70px;
  background: url(~@/assets/images/bg_my.png);
  background-size: 100% auto;
  background-position: 0 -40px;
  .list {
    width: 100%;
    margin-bottom: 32px;
    .item {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 59px;
      margin: 12px 0;
      padding: 0 15px;
      background: #202122;
      box-shadow: inset -1px -1px 1px rgba(210, 210, 210, 0.25), inset -2px 1px 4px #000000;
      border-radius: 10px;
      img {
        width: 31px;
        height: 31px;
        margin-right: 16px;
        background: linear-gradient(320.66deg, #141515 14.75%, #2E3236 84.81%);
        border: 1px solid #282B2E;
        box-shadow: 0px 4px 4px #000000, 4px 6px 20px rgba(0, 0, 0, 0.35), 0px -2px 4px rgba(255, 255, 255, 0.14);
        border-radius: 50%;
      }
      .item-name {
        flex: 1;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
      }
      .item-url {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #A5A8AA;
      }
    }
  }
}
</style>

