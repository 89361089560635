<template>
  <div class="More">
    <div class="head">
      <div class="account">
        <div class="text">{{infoData.name}}</div>
        <div class="tag" :class="{'tag-close': infoData.rewardstatus == 2}">{{infoData.rewardstatus == 1 ? $t('note.normal') : $t('note.pause')}}</div>
       </div>
      <div class="head-addr">{{ infoData.addr ? infoData.addr.slice(0, 4) + '....' + infoData.addr.slice(-4) : '' }}</div>
      <div class="head-list">
        <div class="head-item">
          <div class="hi-key">{{ moreType == 1 ? $t('note.nowPledge') : $t('note.nowPledge2')}}</div>
          <div class="hi-value">{{infoData.pledge}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{ moreType == 1 ? $t('note.monthAdd') : $t('note.monthAdd2')}}</div>
          <div class="hi-value">{{infoData.month}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{ moreType == 1 ? $t('note.earnRatio') : $t('note.earnRatio2')}}</div>
          <div class="hi-value">{{infoData.rate}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{$t('note.issueCycle')}}</div>
          <div class="hi-value">{{infoData.ratetype}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{$t('note.accumulated')}}</div>
          <div class="hi-value">{{infoData.reward}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{$t('note.issued')}}</div>
          <div class="hi-value">{{infoData.rewarding}}</div>
        </div>
      </div>
    </div>
    <div class="plan" v-if="moreType == 1">
      <div class="plan-top">
        <div class="plan-title">{{$t('note.pledgeDetail')}}</div>
        <div class="plan-head">
          <div class="ph-item">
            <CommonBtn :btnText="$t('common.all')" :btnType="type == 0 ? 0 : 2" @btnClick="changeType(0)"></CommonBtn>
          </div>
          <div class="ph-item">
            <CommonBtn :btnText="$t('invite.myTeam')" :btnType="type == 1 ? 0 : 2" @btnClick="changeType(1)"></CommonBtn>
          </div>
        </div>
      </div>
      
      <div class="plan-main">
        <table class="plan-table">
          <thead>
            <tr>
              <th class="td1">{{$t('wallet.walletAddr')}}</th>
              <th class="td2">{{$t('common.name')}}</th>
              <th class="td3">{{$t('record.pledgeing')}}</th>
              <th class="td4">{{$t('note.allPledge')}}</th>
              <th class="td5">{{$t('note.allPledgeReward')}}</th>
            </tr>
          </thead>
        </table>
        <table class="plan-table">
          <tbody>
            <tr v-for="(o, i) in infoList" :key="i">
              <td class="td1">
                <div class="owt">{{o.addr}}</div>
                <div class="tree-tag" v-if="o.tree != 0">
                  {{o.tree == 1 ? $t('note.core') : o.tree}}
                </div>
              </td>
              <td class="td2 owt">
                <div class="owt">{{o.name}}</div>
              </td>
              <td class="td3">{{o.quoteing}}</td>
              <td class="td4">{{o.quoteall}}</td>
              <td class="td5">{{o.rewardall}}</td>
            </tr>
          </tbody>
        </table>
        <NoData v-if="infoList.length == 0"/>
      </div>
    </div>
    <div class="main" v-else>
      <div class="title">{{$t('note.child')}}</div>
      <div class="symbol"></div>
      <template v-if="infoList.length != 0">
        <div class="content" v-for="(item, index) in infoList" :key="index" @click="openModal(item)">
          <div class="avatar">{{item.name ? item.name[0] : '-'}}</div>
          <div class="desc">
            <div class="desc-top">
              <div class="desc-head">
                <div class="account">
                  <div class="text">{{item.name}}</div>
                  <div class="tag" :class="{'tag-close': item.rewardstatus == 2}">{{item.rewardstatus == 1 ? $t('note.normal') : $t('note.pause')}}</div>
                </div>
                <div class="address">{{item.user ? item.user.slice(0,4) + '...' + item.user.slice(item.user.length - 4) : '-'}}</div>
              </div>
            </div>
            <div class="desc-main">
              <div class="desc-item">
                <div class="di-key">{{$t('note.team')}}</div>
                <div class="di-value">{{item.team}}</div>
              </div>
              <div class="desc-item">
                <div class="di-key">{{$t('note.ratio')}}</div>
                <div class="di-value">{{item.rate}}</div>
              </div>
              <div class="desc-item">
                <div class="di-key">{{$t('note.issued')}}</div>
                <div class="di-value">{{item.rewarding}}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="noChild" v-else>{{$t('note.noChild')}}</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { NodeInfo, getNodeUserDetail, NodeInfoChild, NodeTree } from '@/api/user'
// import NoData from '@/components/NoData'
export default {
  name: 'More',
  components: {
    // NoData
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
      userInfo: state => state.user.userInfo,
    }),
  },
  watch: {
    userInfo() {
      if (this.userInfo.uid) {
        this.getData()
      }
    }
  },
  data() {
    return {
      nodeid: '',
      infoData: {
        sup: {},
        my: {},
        son: {}
      },
      type: 0, // 0全部 1我的团队
      infoList: [],
      moreType: 1, // 1 当前节点详情 2 子节点详情
    }
  },
  mounted() {
    this.nodeid = this.$route.query.nodeid || 0
    this.moreType = this.$route.query.moreType || 1
    this.getData()
  },
  methods: {
    ...mapActions(['setResetPage']),
    async getData() {
      if (this.moreType == 1) {
        await NodeInfo().then(res => {
          this.infoData = res.sup
        })
      } else {
        await NodeInfoChild({nodeid: this.nodeid}).then(res => {
          this.infoData = res.data
        })
      }
      await this.getList()
    },
    changeType(index) {
      this.type = index
      this.getList()
    },
    getList() {
      let req = null
      if (this.moreType == 1) {
        req = getNodeUserDetail
      } else {
        req = NodeTree
      }
      req({nodeid: this.nodeid, type: this.type + ''}).then(res => {
        if (this.moreType == 1) {
          this.infoList = res
        } else {
          this.infoList = res.son
        }
        this.infoList.forEach(item => {
          item.addr = item.addr.slice(0, 4) + '....' + item.addr.slice(-4)
          if (item.name.length > 5) {
            item.name = item.name.slice(0, 4) + '....'
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.More {
  min-height: calc(100vh - 45px);
  padding: 14px 15px 20px 15px;
  background: url(~@/assets/images/bg.png);
  background-size: auto;
  background-repeat: repeat;
  .account {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    .text {
      margin-right: 10px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
    }
    .tag {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1px 8px;
      background: linear-gradient(90deg, #EEBE66 0%, #EDC67F 100%);
      border-radius: 3px;
    }
    .tag-close {
      color: #fff;
      background: #FB3E3E;
    }
  }
  .head-addr {
    width: 100%;
    text-align: center;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #A5A8AA;
  }
  .tree-tag {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 26px;
    padding: 1px 2px;
    margin-left: 2px;
    background: linear-gradient(90deg, #EEBE66 0%, #EDC67F 100%);
    border-radius: 3px;
    font-family: 'PingFang SC';
    font-size: 12px;
    line-height: 14px;
    color: #212529;
  }
  .head {
    margin-bottom: 22px;
    .head-list {
      display:flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 10px 0;
      .head-item {
        width: 46%;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 4px 0;
        .hi-key {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #A5A8AA;
        }
        .hi-value {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #FFFFFF;
        }
      }
    }
  }
  .plan {
    width: 100%;
    padding: 35px 16px;
    background: #202122;
    // box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D, inset 5px 11px 20px #000000;
    border-radius: 40px;
    .plan-top {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    .plan-title {
      margin-left: 16px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #FFFFFF;
    }
    .plan-head {
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .ph-item {
        width: 80px;
        height: 36px;
        margin-right: 9px;
      }
    }
    .plan-img {
      width: 20px;
      height: 20px;
      margin-left: 2px;
    }
    $table-width: 680px;
    .plan-main {
      width: 100%;
      overflow-x: auto;
    }
    .plan-list {
      width: $table-width;
      height: 430px;
      overflow-y: auto;
    }
    .plan-table {
      overflow-x: auto;
      width: $table-width;
      thead {
        background: #202122;
        box-shadow: inset -1px -1px 1px rgba(210, 210, 210, 0.25), inset -2px 1px 4px #000000;
        border-radius: 10px;
      }
      th, td {
        text-align: left;
        font-size: 14px;
        font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 16px;
      }
      th, td{
        padding: 14px 16px;
      }
      tbody tr:not(:last-child) {
        border-bottom: 1px solid rgba(255,255,255,0.2);
      }
      .td1 {
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 160px;
      }
      .td2 {
        width: 100px;
      }
      .td3 {
        width: 140px;
      }
      .td4 {
        width: 140px;
      }
      .td5 {
        width: 140px;
      }
    }
  }
}
.title {
  margin-bottom: 7px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  color: #FFFFFF;
}
.symbol {
  margin-bottom: 20px;
  width: 29px;
  height: 6px;
  background: linear-gradient(90deg, #F6DFA9 0%, #EDC67F 75.52%);
}
.main {
  position: relative;
  width: 100%;
  padding: 24px 20px 14px 20px;
  margin-bottom: 30px;
  background: #202122;
  // box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
  box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D, inset 5px 11px 20px #000000;
  border-radius: 40px;
  .main-add {
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 24px;
    color: #EDC274;
  }
  .content {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .avatar {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: linear-gradient(320.66deg, #141515 14.75%, #2E3236 84.81%);
    border: 1px solid #282B2E;
    box-shadow: 0px 4px 4px #000000, 4px 6px 20px rgba(0, 0, 0, 0.35), 0px -2px 4px rgba(255, 255, 255, 0.14);
    border-radius: 30px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #EDC274;
  }
  .desc {
    flex: 1;
    margin-left: 14px;
    .desc-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .desc-btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .desc-btn-edit {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 3px 10px;
      margin-right: 6px;
      background: linear-gradient(90deg, #EEBE66 0%, #EDC67F 100%);
      border-radius: 3px;
    }
    .desc-btn-del {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1px 8px;
      border-radius: 3px;
      color: #fff;
      background: #FB3E3E;
    }
    .desc-head {
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    .address {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #D9D9D9;
    }
    .desc-main {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 12px 0;
    }
    .desc-item {
      flex: 1;
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .di-key {
        margin-bottom: 6px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #A5A8AA;
      }
      .di-value {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #A5A8AA;
      }
    }
  }
}
.noChild {
  width: 100%;
  margin: 10px 0;
  text-align: center;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
}
</style>

