<template>
  <div class="Invite">
    <div class="add-up" v-if="inviteInfo.has == 1">{{$t('invite.up')}}：{{inviteInfo.up}}</div>
    <div class="add-up" @click="addUp" v-else>
      + {{$t('invite.addUp')}}
    </div>
    <!-- <div class="head">
       <div class="head-rule" @click="openNotice">{{$t('invite.rule')}} ></div>
    </div>
    <div class="link">
      <div class="link-title">
        <div>{{$t('invite.myLink')}}</div>
      </div>
      <div class="link-symbol"></div>
      <div class="link-action">
        <div class="link-left">
          <div class="link-link">
            <div class="ll-main owt">{{inviteUrl}}</div>
            <img class="ll-img ll-img-url" @click="copy('url')" src="@/assets/images/icon_Copy.png" alt="">
          </div>
          <div class="link-link">
            <div class="ll-main owt">{{inviteInfo.icode}}</div>
            <img class="ll-img ll-img-icode" @click="copy('icode')" src="@/assets/images/icon_Copy.png" alt="">
          </div>
        </div>
        <div class="link-img" @click="showPoster">
          <img src="@/assets/images/icon_QrCode.png" alt="">
        </div>
      </div>
    </div>
    <div class="people">
      <div class="people-item">
        <div class="pi-key">{{$t('invite.teamNum')}}</div>
        <div class="pi-value">{{inviteInfo.userAll}}</div>
      </div>
      <div class="people-item">
        <div class="pi-key">{{$t('invite.teamRate')}}</div>
        <div class="pi-value">{{inviteInfo.rate}}</div>
      </div>
      <div class="people-item">
        <div class="pi-key">{{$t('invite.teamPledge')}}</div>
        <div class="pi-value">{{inviteInfo.quoteAll}}</div>
      </div>
    </div> -->
    <div class="level" v-if="viplist.length>0">
      <div class="round1"></div>
      <div class="fan" :style="{transform: 'rotate(' + rotationAngle + 'deg)'}"></div>
      <div class="round2"></div>
      <div class="hid"></div>

      <div class="point p_level0"></div>
      <!-- <div class="point p_level1"></div>
      <div class="point-un p_level2"></div>
      <div class="point-un p_level3"></div>
      <div class="point-un p_level4"></div> -->
      <div class="point-un p_level5"></div>
    

      <div v-for="(item,index) in pointlist" :key="index" >
        <div class="point" :class="{'point-un': index>parseInt(myinfo['lv'])-1}" :style="item['left']!=null?{bottom: item['bottom']+'px', left: item['left']+'px'}:{bottom: item['bottom']+'px', right: item['right']+'px'}"></div>
      </div>

      <div class="vip0 point-title" :class="myinfo['lv_name'] == viplist[0]['lv_name'] ? 'point-title-active' : ''">{{ viplist[0]['lv_name'] }}</div>
      <!-- <div class="vip1 point-title">VIP1</div>
      <div class="vip2 point-title">VIP2</div>
      <div class="vip3 point-title">VIP3</div>
      <div class="vip4 point-title">VIP4</div> -->
      <div class="vip5 point-title" :class="myinfo['lv_name'] == viplist[viplist.length-1]['lv_name'] ? 'point-title-active' : ''">{{ viplist[viplist.length-1]['lv_name'] }}</div>

      <div v-for="(item,index) in gradelist" :key="index" >
        <div class="point-title" :class="myinfo['lv_name'] == item['title']" :style="item['left']!=null?{bottom: item['bottom']+'px', left: item['left']+'px'}:{bottom: item['bottom']+'px', right: item['right']+'px'}">
          {{ item['title'] }}
        </div>
      </div>

      <div class="level-shadow"></div>
      <img class="level-logo" :src="''+myinfo['lv_icon']" alt="">
      <div class="level-name">{{ myinfo['lv_name'] }}</div>
    </div>

    <div class="info">
      <div class="flexrow">
        <div class="nrow">
          <img class="icon" src="@/assets/images/icon_bonus.png" alt="">
          <div class="reward right10">{{ $t('invite.reward') }}:</div>
          <div class="price">{{ myinfo['amount'] }}{{ systemInfo.tkname }}</div>
        </div>
        <div class="nrow" @click="this.$router.push('/withdraw')">
          <div class="towithdraw">{{ $t('invite.towith') }}</div>
          <img class="y_arrow_right" src="@/assets/images/icon_Yellow_arrow.png" alt="">
        </div>
      </div>
      <div class="flexrow padding20">
        <div class="flexcol0">
          <div class="flexcol">
            <div class="title">{{ $t('invite.mySpeed') }}</div>
            <div class="price">{{ myinfo['rate'] }}%</div>
          </div>
          <div class="flexcol">
            <div class="title">{{ $t('invite.teamNum') }}</div>
            <div class="price">{{ myinfo['userAll'] }}</div>
          </div>
          <div class="flexcol">
            <div class="title">{{ $t('invite.allTeam') }}</div>
            <div class="price">{{ myinfo['team'] }}</div>
          </div>
        </div>
        <div class="flexcol0">
          <div class="flexcol">
            <div class="title">{{ $t('invite.teamReward') }}</div>
            <div class="price">{{ myinfo['rewards'] }}%</div>
          </div>
          <div class="flexcol">
            <div class="title">{{ $t('invite.teamPledge') }}</div>
            <div class="price">{{ myinfo['quote'] }}</div>
          </div>
          <div class="flexcol">
            <div class="title">{{ $t('invite.allTeamPledge') }}</div>
            <div class="price">{{ myinfo['quoteAll'] }}</div>
          </div>
        </div>
      </div>
      <div class="invite">
          <CommonBtn :btnText="$t('invite.inviteOthers')"  @btnClick="inviteShow = true;"></CommonBtn>
      </div>
      <div class="rule">
        <div class="title"  @click="openNotice">{{ $t('invite.knowRule') }}</div>
        <img class="y_arrow_right arrow_p" src="@/assets/images/right_arrow.png" alt="">
      </div>
    </div>
    <div class="plan">
      <div class="plan-head">
        <div class="ph-item">
           <CommonBtn :btnText="$t('invite.myTeam')" :btnType="planType == 0 ? 0 : 2" @btnClick="changePlanType(0)"></CommonBtn>
        </div>
        <div class="ph-item">
           <CommonBtn :btnText="$t('invite.record')" :btnType="planType == 1 ? 0 : 2" @btnClick="changePlanType(1)"></CommonBtn>
        </div>
      </div>
      <div class="grade-list" v-if="viplist.length>0">
        <div class="grade-item" v-for="(item, index) in viplist" :key="index">
          <img class="grade-icon" :src="item.lv_icon" alt="">
          <div class="grade">{{ item.count }}</div>
          <div class="grade-name">{{ item.lv_name }}</div>
        </div>
      </div>
      <div class="plan-main">
        <table class="plan-table" :class="planType == 0 ? 'plan-table-1' : 'plan-table-2'">
          <thead v-if="planType == 0">
            <tr>
              <th class="td1">{{$t('wallet.walletAddr')}}</th>
              <th class="td2">{{$t('common.name')}}</th>
              <th class="td2">{{$t('invite.grade')}}</th>
              <!-- <th class="td4">{{$t('invite.speed')}}</th> -->
              <th class="td8">{{$t('invite.pledgeIng')}}</th>
              <th class="td3">{{$t('note.nowPledge')}}</th>
              <th class="td4">{{$t('note.monthAdd')}}</th>
              <th class="td5">{{$t('note.quote_all')}}</th>
              <th class="td5">{{$t('note.quote_team')}}</th>
              <th class="td6">{{$t('note.tree1')}}</th>
              <th class="td6">{{$t('invite.teamNum')}}</th>
              <th class="td3">{{$t('invite.joinT')}}</th>
            </tr>
          </thead>
          <thead v-else>
            <tr>
              <th class="td1">{{$t('wallet.walletAddr')}}</th>
              <th class="td2">{{$t('common.name')}}</th>
              <th class="td2">{{$t('invite.grade')}}</th>
              <th class="td5">{{$t('invite.pledgeTime')}}</th>
              <th class="td6">{{$t('invite.pledgeNum')}}</th>
              <!-- <th class="td7">{{$t('invite.speed')}}</th> -->
            </tr>
          </thead>
        </table>
        <van-list
          v-if="inviteList.length != 0 || firstLoad"
          v-model:loading="loading"
          :finished="finished"
          finished-text=""
          @load="onLoad"
          class="plan-list"
        >
          <table class="plan-table">
            <tbody v-if="planType == 0">
              <tr v-for="(o, i) in inviteList" :key="i" @click="toMore(o)">
                <td class="td1">
                  <div class="owt">{{o.addr}}</div>
                  <div class="tree-tag" v-if="o.tree != 0">
                    {{o.tree == 1 ? $t('note.core') : o.tree}}
                  </div>
                </td>
                <td class="td2 owt">
                  <div class="owt">{{o.nickname}}</div>
                </td>
                <td class="td2 owt">
                  <div class="owt">{{o.lv}}</div>
                </td>
                <!-- <td class="td4">{{o.rate}}%</td> -->
                <td class="td8">{{o.cting}}</td>
                <td class="td3">{{o.quote}}</td>
                <td class="td4">{{o.quote_m}}</td>
                <td class="td5">{{o.quote_all}}</td>
                <td class="td5">{{o.quote_team}}</td>
                <td class="td6">{{o.tree1}}</td>
                <td class="td6">{{o.team}}</td>
                <td class="td3">{{o.time}}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="(o, i) in inviteList" :key="i">
                <td class="td1">
                  <div class="owt">{{o.addr}}</div>
                </td>
                <td class="td2 owt">
                  <div class="owt">{{o.nickname}}</div>
                </td>
                <td class="td5">{{o.time}}</td>
                <td class="td6">{{o.quote}}</td>
                <!-- <td class="td7">{{o.rate}}%</td> -->
              </tr>
            </tbody>
          </table>
        </van-list>
        <NoData v-if="inviteList.length == 0 && !firstLoad"/>
      </div>
    </div>
    <img v-if="posterShow" class="poster" :src="posterUrl" alt="">
    <van-action-sheet
      v-model:show="posterShow"
      :actions="actions"
      :cancel-text="$t('common.CANCEL')"
      @select="onSelect" />
    <van-popup 
      v-model:show="visible"
      :close-on-click-overlay="true"
      round
      class="AddUp"
    >
      <div class="content">
        <div class="header">
          <div class="header-text">{{$t('invite.addUp')}}</div>
        </div>
        <div class="item">
          <div class="item-title">{{$t('login.icode')}}：</div>
          <div class="input">
            <input v-model="icode" type="text" :placeholder="$t('toast.addUp')">
          </div>
        </div>
        <div class="edit-btn">
          <div class="edit-btn-item">
            <CommonBtn :btnText="$t('common.save')" :loading="btnLoading" @btnClick="save"></CommonBtn>
          </div>
          <div class="edit-btn-item">
            <CommonBtn :btnText="$t('common.CANCEL')" :btnType="2" :loading="btnLoading" @btnClick="close"></CommonBtn>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup 
      v-model:show="inviteShow"
      :close-on-click-overlay="true"
      style="background: none;"
    >
      <!-- <div class="invite-box"></div> -->
      <div class="invite-box">
        <div class="flexrow topimg">
          <div class="icon">
            <img class="itemimg" src="@/assets/images/icon_share.png" alt="">
          </div>
          <div class="icon">
            <img class="itemimg" src="@/assets/images/icon_user_2.png" alt="">
          </div>
          <div class="icon">
            <img class="itemimg" src="@/assets/images/icon_gift.png" alt="">
          </div>
        </div>
        <div class="flexrow toptext">
          <div class="single">1. {{ $t('invite.shareLink') }}</div>
          <div class="single">2. {{ $t('invite.otherLogin') }}</div>
          <div class="single">3. {{ $t('invite.matePledge') }}</div>
        </div>
        <div class="line"></div>
        <div class="invite-title">{{ $t('invite.inviteLink') }}</div>
        <div class="invite-info">
          <div class="info-text">{{ this.inviteUrl }}</div>
          <div class="copy-box" @click="copy('url')">
            <img class="copy" src="@/assets/images/icon_copy_2.png" alt="">
          </div>
        </div>
        <div class="invite-title">{{ $t('invite.code') }}</div>
        <div class="invite-info">
          <div class="info-text">{{ this.inviteInfo.icode }}</div>
          <div class="copy-box" @click="copy('icode')">
            <img class="copy" src="@/assets/images/icon_copy_2.png" alt="">
          </div>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { InvitInfo, InvitList, RateList,  GetPoster, addUpApi } from '@/api/user'
import NoData from '@/components/NoData'
// import Clipboard from "clipboard"
import { myInvite, vipList } from '../../api/user'
export default {
  name: 'Invite',
  components: {
    NoData
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
      userInfo: state => state.user.userInfo,
      account: state => state.wallet.account,
    }),
    inviteUrl () {
      return window.location.origin + window.location.pathname + '#/home?icode=' + this.inviteInfo.icode
    },
  },
  watch: {
    account() {
      this.getData()
    },
  },
  data() {
    return {
      inviteInfo: {},
      planType: 0,
      inviteList: [],
      start: 0,
      limit: 20,
      firstLoad: true,
      loading: false,
      finished: false,
      posterUrl: '',
      posterShow: false,
      actions: [
      ],
      visible: false,
      rotationAngle: -180,
      icode: '',
      btnLoading: false,
      inviteShow: false,
      myinfo: {},
      viplist: [],
      pointlist: [],
      gradelist: [],
    }
  },
  mounted() {
    this.checkLink().then(async () => {
      await this.getInfo()
      await this.getData()
     
    })
    // if (this.systemInfo && this.systemInfo.banner) {
    //   let imgArr = this.systemInfo.banner.filter(item => item.type == 3)
    //   let imgObj = JSON.parse(JSON.stringify(imgArr[0]))
    //   let div = document.getElementsByClassName('head')[0]
    //   div.style.backgroundImage = `url(/${imgObj.url})`
    // }
  },
  methods: {
    ...mapActions(['checkLink', 'SetMaskLoading', 'SetLoadingText', 'setNoticeType']),
    async getData() {
      await InvitInfo().then(res => {
        this.inviteInfo = res
      })
      await this.onLoad()
    },
    async getInfo() {
      await myInvite().then(res=>{
        this.myinfo = res;
        this.getVipList()
      })
    },
    async getVipList(){
      await vipList().then(res=>{
        this.viplist = res.list;
        this.rotationAngle = 180/(this.viplist.length-1) * (parseInt(this.myinfo['lv'])) - 180;
        
        this.pointlist = [];
        this.gradelist = [];
        const radius = 150
        
        for(var i = 1; i<=res.list.length-2; i++){
          var angle = i * (180/(this.viplist.length-1));
          if(angle>90){
            angle = 90 - (angle - 90);
            const angleInRadians = angle * (Math.PI / 180);
            var x = window.innerWidth/2 + radius * Math.cos(angleInRadians) - 10;
            var y = radius * Math.sin(angleInRadians)+24;
            this.pointlist.unshift({
              bottom: y,
              right: x,
            })
            this.gradelist.unshift({
              bottom: y + 10,
              right: x+0,

              title: res.list[res.list.length-1-i]['lv_name'],
              icon: res.list[res.list.length-1-i]['lv_icon'],
              count: res.list[res.list.length-1-i]['count'],
            })
          }else{
            const angleInRadians = angle * (Math.PI / 180);
            var x1 = window.innerWidth/2 + radius * Math.cos(angleInRadians) - 12;
            var y1 = radius * Math.sin(angleInRadians)+30;
            this.pointlist.unshift({
              bottom: y1 - 5,
              left: x1,
            });
            this.gradelist.unshift({
              bottom: y1 + 10,
              left: x1,

              title: res.list[res.list.length-1-i]['lv_name'],
              icon: res.list[res.list.length-1-i]['lv_icon'],
              count: res.list[res.list.length-1-i]['count'],
            })
          }
        }
        // 微调
        this.pointlist[0].right = this.pointlist[0].right - 4
        this.pointlist[2].left = this.pointlist[2].left - 6
        this.pointlist[2].bottom = this.pointlist[2].bottom + 2
        this.pointlist[3].left = this.pointlist[3].left - 2
      })
    },
    changePlanType(index) {
      this.planType = index

      this.start = 0
      this.inviteList = []
      this.firstLoad = true
      this.finished = false
      // this.$forceUpdate()
      this.onLoad()
    },
    async onLoad() {
      console.log('onload')
      // if (this.loading) {
      //   return
      // }
      this.loading = true
      let req = this.planType == 0 ? InvitList : RateList
      await req({start: this.start, limit: this.limit}).then(res => {
        if (this.firstLoad) {
          this.firstLoad = false
        }
        if (this.start == 0 || !this.start) {
          this.inviteList = []
        }
        this.inviteList = this.inviteList.concat(res.list)
        this.inviteList.forEach(item => {
          item.addr = item.addr.slice(0, 4) + '....' + item.addr.slice(-4)
          if (item.nickname.length > 5) {
            item.nickname = item.nickname.slice(0, 4) + '....'
          }
        })
        if (this.inviteList.length >= res.count) {
          this.finished = true
        } else {
          this.finished = false
        }
        this.start = this.inviteList.length
        this.loading = false
        console.log(this.start, this.limit, this.finished, this.loading)
      })
    },
    copy(type) {
      // let className = type == 'url' ? '.ll-img-url' : '.ll-img-icode'
      let text = type == 'url' ? this.inviteUrl : this.inviteInfo.icode
      // let clipboard = new Clipboard(className, {
      //   text: () => {
      //     return text
      //   }
      // })
      navigator.clipboard.writeText(text)
      .then(() => {
        this.$toast(`${this.$t('common.copySuccess')}！`)
      })
      .catch(() => {
        this.$toast(`${this.$t('common.copyFail')}！`)
      });
      // clipboard.on('success', () => {
      //   this.$toast(`${this.$t('common.copySuccess')}！`)
      //   clipboard.destroy()
      // })
      // clipboard.on('error', () => {
      //   this.$toast(`${this.$t('common.copyFail')}！`)
      //   clipboard.destroy()
      // })
    },
    showPoster() {
      this.SetMaskLoading(true)
      this.SetLoadingText('Loading..')
      GetPoster().then(res => {
        this.posterUrl = res.img
        this.posterShow = true
        setTimeout(() => {
          this.SetMaskLoading(false)
        }, 1000);
        
      })
    },
    onSelect() {
      // 下载同源图片
      this.downloadPicture(this.posterUrl, 'poster')
      
    },
    downloadPicture(imgSrc, name) {
      // 同源情况
      // this.imgUrl = imgSrc
      // var alink = document.createElement("a")
      // alink.href = this.imgUrl
      // alink.download = name //图片名
      // alink.click()

      const image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.src = imgSrc;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.download = name || "photo";
          a.href = url;
          a.click();
          a.remove();
          URL.revokeObjectURL(url);
        });
      };
      this.posterShow = false
    },
    openNotice() {
      this.setNoticeType(4)
    },
    // 无上级新增推荐人
    addUp() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
    save() {
      if (this.icode == '') {
        this.$toast(this.$t('toast.addUp'))
        return
      }
      this.btnLoading = true
      addUpApi({icode: this.icode}).then(() => {
        this.$toast(this.$t('toast.editSuccess'))
        this.visible = false
        this.btnLoading = false
        this.getData()
      }).catch(() => {
        this.btnLoading = false
      })
    },
    toMore(data) {
      console.log(data)
      this.$router.push(`/inviteMore?id=${data.id}`)
    }
  }
}
</script>
<style lang="scss" scoped>
.Invite {
  min-height: calc(100vh - 45px);
  padding: 0 15px;
  padding-top: 20px;
  padding-bottom: 30px;
  background: url(~@/assets/images/bg_home2.png);
  background-size: auto;
  background-repeat: repeat;
  .add-up {
    position: fixed;
    top: 12px;
    right: 15px;
    z-index: 99;
    font-family: 'Alibaba PuHuiTi';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #EDC67F;
  }
  .AddUp {
    border-radius: 40px;
    .content {
      width: 320px;
      height: 200px;
      padding: 20px 18px;
      background: #292D32;
      box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 0px -1px 11px rgba(255, 255, 255, 0.25), inset 5px 11px 20px rgba(0, 0, 0, 0.59);
      // border-radius: 40px;
    }
    .header {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      font-family: 'Alibaba PuHuiTi';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #FFFFFF;
    }
    .item {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .item-title {
        width: 60px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #FFFFFF;
      }
    }
    .input {
      flex: 1;
      margin: 6px 0;
      input {
        width: 100%;
        height: 40px;
        padding: 0 16px;
        background: #292D32;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -5px 4px rgba(80, 80, 80, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset -5px -8px 10px #30343A, inset 8px 8px 10px #24262C;
        border-radius: 20px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #B8B6B2;
        border: 1px solid rgba(41, 45, 50, 1);
      }
    }
    .edit-btn {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      .edit-btn-item {
        width: 130px;
        height: 40px;
      }
    }
  }
  .head {
    position: absolute;
    left: 0;
    top: -25px;
    width: 100vw;
    height: 274px;
    // background: url(~@/assets/images/bg_Invite.png);
    background-size: 100% 100%;
    .head-rule {
      padding: 0 32px;
      margin-top: 186px;
      font-family: 'Alibaba PuHuiTi';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #EDC67F;
    }
  }

  .link {
    position: relative;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 26px;
    padding: 25px 16px;
    background: #202122;
    // box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D, inset 5px 11px 20px #000000;
    border-radius: 40px;
    .link-title {
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 0 10px;
      margin-bottom: 9px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #A5A8AA;
      .header-role {
        margin-left: 12px;
        padding: 2px 14px;
        background: $color-gradient;
        border-radius: 10px;
        color: #fff;
        font-size: 14px;
        font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
        font-weight: 500;
      }
    }
    .link-symbol {
      width: 29px;
      height: 6px;
      margin-left: 10px;
      margin-bottom: 7px;
      background: linear-gradient(90deg, #F6DFA9 0%, #EDC67F 75.52%);
    }
    .link-action {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
      .link-left {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .link-link {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 226px;
        height: 49px;
        margin: 7px 0;
        padding: 0 15px;
        background: #292D32;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -5px 4px rgba(80, 80, 80, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset -5px -8px 10px #30343A, inset 8px 8px 10px #24262C;
        border-radius: 10px;
        .ll-main {
          flex: 1;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #B8B6B2;
        }
        .ll-img {
          width: 20px;
          height: 20px;
          margin-left: 10px;
        }
      }
      .link-img {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 77px;
        height: 112px;
        background: #292D32;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -5px 4px rgba(80, 80, 80, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset -5px -8px 10px #30343A, inset 8px 8px 10px #24262C;
        border-radius: 10px;
        img {
          width: 27px;
          height: 27px;
        }
      }
    }

  }
  
  .people {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 345px;
    margin-bottom: 26px;
    padding: 16px 32px;
    background: #202122;
    // box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D, inset 5px 11px 20px #000000;
    border-radius: 25px;
    .people-item {
      // flex: 1;
      width: 140px;
      .pi-key {
        margin-bottom: 4px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #A5A8AA;
      }
      .pi-value {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #FFFFFF;
      }
    }
  }
  
  .plan {
    width: 100%;
    padding: 35px 16px;
    background: #202122;
    // box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D, inset 5px 11px 20px #000000;
    border-radius: 40px;
    .plan-head {
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 25px;
      .ph-item {
        width: 92px;
        height: 36px;
        margin-right: 9px;
      }
    }
    .grade-list {
      width: 100%;
      overflow-x: auto;
      display: flex;
      height: 70px;
      .grade-item {
        display: flex;
        flex-direction: column;
        width: 60px;
        align-items: center;
        .grade-icon {
          height: 28px;
          // width: 28px;
        }
        .grade {
          font-size: 13px;
          color: white;
        }
        .grade-name {
          font-size: 12px;
          color: #616161;
        }
      }
    }
    .plan-main {
      width: 100%;
      overflow-x: auto;
    }
    $table-width1: 1200px;
    $table-width2: 500px;
    .plan-list {
      width: $table-width1;
      height: 430px;
      overflow-y: auto;
    }
    .plan-table {
      overflow-x: auto;
      thead {
        background: #202122;
        box-shadow: inset -1px -1px 1px rgba(210, 210, 210, 0.25), inset -2px 1px 4px #000000;
        border-radius: 10px;
      }
      th, td {
        text-align: left;
        font-size: 14px;
        font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 16px;
      }
      th, td{
        padding: 14px 16px;
      }
      tbody tr:not(:last-child) {
        border-bottom: 1px solid rgba(255,255,255,0.2);
      }
      .td1 {
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 160px;
      } 
      .td2 {
        width: 110px;
      }
      .td3 {
        width: 140px;
      }
      .td4 {
        width: 90px;
      }
      .td5 {
        width: 130px;
      }
      .td6 {
        width: 100px;
      }
      .td7 {
        width: 100px;
      }
      
      .td8 {
        width: 130px;
      }
    }
    .plan-table-1 {
      width: $table-width1;
    }
    .plan-table-2 {
      width: $table-width2;
    }
  }
}
.tree-tag {
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 26px;
  padding: 1px 2px;
  margin-left: 2px;
  background: linear-gradient(90deg, #EEBE66 0%, #EDC67F 100%);
  border-radius: 3px;
  font-family: 'PingFang SC';
  font-size: 12px;
  line-height: 14px;
  color: #212529;
}
.popup {
  .content {
    padding: 15px 22px;
  }
  .header {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .header-text {
      font-size: 16px;
      font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
      font-weight: 500;
      color: #40384A;
    }
    .header-icon {
      width: 16px;
      height: 16px;
    }
  }
  .list {
    .list-head {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
      .lh-item {
        font-size: 14px;
        font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
        font-weight: 400;
        color: #ADAFBC;
        line-height: 16px;
      }
    }
    .list-main {
      width: 100%;
      height: 240px;
      overflow-y: auto;
      .lm-content {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
      }
      .lm-content:not(:last-child) {
        border-bottom: 1px solid $color-border;
      }
      .lm-item {
        display:flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        height: 100%;
        padding: 10px 0;
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #362B45;
        line-height: 16px;
        div {
          margin: 2px 0;
        }
      }
    }
    .lh-item1, .lh-item3 {
      flex: 1;
    }
    .lh-item2 {
      width: 80px;
    }
  }
  
}
.poster {
  position: fixed;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  width: 300px;
  height: auto;
  z-index: 2009;
}
.level {
  height: 205px;
  width: 100%;
  position: relative;
  overflow: hidden;
  .round1 {
    height: 160px;
    width: 320px;
    border-top-left-radius: 160px;
    border-top-right-radius: 160px;
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
    margin: 0 auto;
    box-shadow: inset 0px 0px 10px #000000;
    background: #404245;
  }
  .round2 {
    height: 155px;
    width: 310px;
    border-top-left-radius: 155px;
    border-top-right-radius: 155px;
    position: absolute;
    left: 0;
    right: 0;
    top: 25px;
    margin: 0 auto;
    box-shadow:  0px 0px 4px #ffffff07;
    background: url(~@/assets/images/bg_home2.png);
  }
  .fan {
    height: 159px;
    width: 318px;
    border-top-left-radius: 159px;
    border-top-right-radius: 159px;
    background: #E0B15B;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 20px;
    transform-origin: 50% 100%;
  }
  .point {
    height: 11px;
    width: 11px;
    border-radius: 5.5px;
    background: #E0B15B;
    position: absolute;
  }
  .point-un {
    height: 11px;
    width: 11px;
    border-radius: 5.5px;
    position: absolute;
    box-shadow: inset 0px 4px 4px #00000084;
    background: #404245;
  }
  .p_level0 {
    left: 10px;
    bottom: 20px;
  }
  .p_level1 {
    left: 41px;
    bottom: 113px;
  }
  .p_level2 {
    left: 120px;
    bottom: 170px;
  }
  .p_level3 {
    right: 120px;
    bottom: 170px;
  }
  .p_level4 {
    right: 41px;
    bottom: 113px;
  }
  .p_level5 {
    right: 10px;
    bottom: 20px;
  }
  .point-title {
    font-size: 12px;
    color: white;
    position: absolute;
  }
  .point-title-active {
    color: #E0B15B;
  }
  .vip0 {
    left: 5px;
    bottom: 0;
  }
  .vip1 {
    left: 5px;
    bottom: 113px;
  }
  .vip2 {
    left: 87px;
    bottom: 175px;
  }
  .vip3 {
    right: 87px;
    bottom: 175px;
  }
  .vip4 {
    right: 5px;
    bottom: 113px;
  }
  .vip5 {
    right: 0px;
    bottom: 0;
  }
  
  .hid {
    height: 25px;
    width: 100%;
    background: url(~@/assets/images/bg_home2.png);
    position: absolute;
    bottom: 0;
  }
  .level1 {
    transform: rotate(-144deg);
    transform-origin: 50% 100%;
  }
  .level2 {
    transform: rotate(-108deg);
    transform-origin: 50% 100%;
  }
  .level3 {
    transform: rotate(-72deg);
    transform-origin: 50% 100%;
  }
  .level4 {
    transform: rotate(-36deg);
    transform-origin: 50% 100%;
  }
  .level5 {
    transform: rotate(0deg);
    transform-origin: 50% 100%;
  }

  .level-logo {
    height: 87px;
    width: 116px;
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .level-shadow {
    position: absolute;
    width: 60px;
    height: 10px;
    border-radius: 5px;
    filter: blur(10px);
    background: white;
    bottom: 35px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .level-name {
    height: 15px;
    width: 36px;
    border-radius: 7.5px;
    border: 1px solid #614969;
    background: #A192A2;
    color: white;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
.flexrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info {
  width: 100%;
    padding: 24px 30px;
    background: #202122;
    height: 366px;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
    border-radius: 40px;
    margin: 20px 0 20px 0;
    display: flex;
    flex-direction: column;
    
    .flexcol0 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    .flexcol {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 60px;
    }
    .nrow {
      display: flex;
      align-items: center;
    }
    .reward {
      font-size: 14px;
      color: #A5A8AA;
    }
    .title {
      font-size: 13px;
      color: #A5A8AA;
      margin-bottom: 10px;
    }
    .price {
      font-size: 14px;
      color: white;
      
    }
    .icon {
      height: 15px;
      width: 15px;
      margin-right: 5px;
    }
    .towithdraw {
      font-size: 12px;
      color: #EDC67F;
    }
    .right10 {
      margin-right: 10px;
    }
    .y_arrow_right {
      height: 8px;
      width: 5px;
      margin-left: 5px;
    }
    .padding20 {
      padding: 20px 20px;
    }
    .invite {
      height: 49px;
      width: 100%;
    }
    .rule {
      width: 100%;
      text-align: center;
      margin-top: 10px;
      position: relative;
      .arrow_p {
        position: absolute;
        top: 6px;
        right: 90px;
      }
    }

}
.invite-box {
  width: 345px;
  padding: 24px 0px;
  background: #202122;
  height: 467px;
  box-shadow: inset 0px -1px 11px rgba(255, 255, 255, 0.25), inset 5px 11px 20px rgba(0, 0, 0, 0.59);
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  .icon {
    height: 41px;
    width: 41px;
    border-radius: 20.5px;
    box-shadow: 0px 4px 4px #000000, 0px -4px 4px #ffffff1f;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .itemimg {
    height: 18.5px;
    width: 18.5px;
  }
  .topimg {
    width: 75%;
    margin: 30px auto 0 auto;
  }
  .toptext {
    width: 95%;
    margin: 20px auto;
    align-items: flex-start;
    .single {
      text-align: center;
      width: 99px;
      font-size: 12px;
      color: #B8B6B2;
    }
  }
  .line {
    height: 1px;
    width: 280px;
    background: black;
    margin: 0 auto 20px auto;
  }
  .invite-title {
    font-size: 14px;
    color: white;
    margin: 0 0 10px 30px;
  }
  .invite-info {
    position: relative;
    background: #202122;
    box-shadow: inset -1px -1px 1px rgba(210, 210, 210, 0.25), inset -2px 1px 4px #000000;
    border-radius: 10px;
    height: 42px;
    width: 290px;
    margin: 0 auto 20px auto;
    padding: 0 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    .info-text {
      font-size: 12px;
      color: #B8B6B2;
      
    }
    .copy-box {
      height: 42px;
      width: 42px;
      border-radius: 10px;
      background: #EDC67F;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      .copy {
        height: 20px;
        width: 20px;
      }
    }
  }
}
</style>

