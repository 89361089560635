<template>
  <div class="Lock">
    <div class="title">空投</div>
    <div class="main main1">
      <div class="main-award">
        <div class="ma-key">当前钱包地址</div>
        <div class="ma-addr">{{account}}</div>
      </div>
      <div class="main-list">
        <div class="main-item">
          <div class="mi-key">钱包{{systemInfo.tkname}}余额</div>
          <div class="mi-value">{{mudBalance}}</div>
        </div>
        <div class="main-item">
          <div class="mi-key">钱包MATIC余额</div>
          <div class="mi-value">{{ethBalance}}</div>
        </div>
      </div>
    </div>
    <div class="main main2">
      <div class="main-list main-list-col">
        <div class="main-row">
          <div class="main-title">空投{{systemInfo.tkname}}数量</div>
          <div class="main-input">
            <input type="number" v-model="value" placeholder="请输入大于0的数量">
          </div>
          <div class="main-title">空投人数</div>
          <div class="main-input">
            <input type="number" v-model="ct" placeholder="请输入大于0的正整数">
          </div>
          <div class="main-btn">
            <CommonBtn :btnText="'开始空投'" :loading="loading"  @btnClick="dropBefore"></CommonBtn>
          </div>
          <div class="main-bottom">
            <div class="" v-if="status == 1">
              <van-icon name="clock-o" /> 准备中
            </div>
            <div class="" v-if="status == 2">
              <van-icon name="replay" /> 等待执行结果
            </div>
            <div class="" v-if="status == 3">
              <van-icon name="passed" /> 执行成功
            </div>
            <div class="" v-if="status == 4">
              <van-icon name="close" /> 执行失败请确认后重试
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getReferendum } from '@/api/user'
import Web3 from "web3"
import coinAbi from '@/utils/coinAbi'
import airAbi from '@/utils/airAbi'
export default {
  name: 'Lock',
  components: {
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
      account: state => state.wallet.account,
      mudBalance: state => state.wallet.mudBalance,
      ethBalance: state => state.wallet.ethBalance,
      mudAddr: state => state.wallet.mudAddr,
      airAddr: state => state.wallet.airAddr,
    }),
  },
  watch: {
  },
  data() {
    return {
      angel: {},
      ab: {},
      ct: '',
      value: '',
      loading: false,
      // 状态 1准备 2等待 3完成
      status: 1,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapActions(['SetMaskLoading', 'SetLoadingText', 'SetBalance']),
    async getData() {
    },
    dropBefore() {
      if (this.loading) {
        this.$toast('请先等待上个动作完成')
        return
      }
      const regular = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/ //正数（包括小数)
      if (this.value == 0 || !regular.test(this.value)) {
        this.$toast('空投数量应为大于0的数字')
        return
      }
      const regNumber = /^\+?[1-9][0-9]*$/ // 正整数
      if (this.ct == 0 || !regNumber.test(this.ct)) {
        this.$toast('空投人数应为大于0的正整数')
        return
      }
      if (+this.ct > +this.systemInfo.ktct) {
        this.$toast('一次性空投人数不能高于' + this.systemInfo.ktct)
        return
      }
      // 判断剩余质押额度是否足够
      const web3 = new Web3(window.ethereum)
      const address = this.account
      try {
        this.SetMaskLoading(true)
        this.SetLoadingText(this.$t('toast.limit'))
        let myContract = new web3.eth.Contract(coinAbi, this.mudAddr, {
          from: address, // default from address
          // gasLimit: 100000,
          // gasPrice: 10000000000 // default gas price in wei, 10 gwei in this case
        });
        myContract.methods.allowance(address, this.airAddr).call((error, result) => {
          console.log('allowance', error, result)
          let amount = this.accMul(this.value)


          // 查询剩余质押额度
          let limit = result
          this.SetMaskLoading(false)
          console.log(limit, amount)
          if (+limit >= +amount) {
            this.drop()
          } else {
            myContract.methods.increaseAllowance(this.airAddr, this.accMul(999999999)).send((error, result) => {
            console.log('increaseAllowance', error, result)
            if (!error) {
              this.watchHash(result)
              this.SetMaskLoading(false)
              this.$toast(this.$t('toast.pledgeSuccess'))
            } else {
              this.SetMaskLoading(false)
              console.log('error', error)
            }
            
          });
          }
        });
      } catch(error) {
        // this.btnLoading = false
        console.error("trigger smart contract error", error)
        this.$toast(error || 'Do not assign to the exception parameter')
      }
    },
    drop() {

      
      this.loading = true
      getReferendum({ct: this.ct, value: this.value}).then(res => {
        console.log(res)
        this.loading = false
        // let flag = true
        // if (flag) {
        //   this.$toast('合约开发中')
        //   return
        // }
        const address = this.account
        const web3 = new Web3(window.ethereum)
        let addr = res.addr
        let value = res.value
        let contractAbi = airAbi
        let contractAddress = this.airAddr
        this.status = 2
        console.log(addr, value)
        let myContract = new web3.eth.Contract(contractAbi, contractAddress, {
          from: address, 
        });
        myContract.methods.airdrops(addr, value).send((error, result) => {
          console.log('airdrops- Output:', error, result, '\n')
          if (!error) {
            this.watchHash(result)
          } else {
            this.loading = false
            this.status = 4
            this.$toast(error)
          }
        })
      })
    },
    watchHash(hash) {
      let ethereum = window.ethereum
      console.log(hash)
      ethereum.request({ 
        // method: 'eth_getTransactionByHash',
        method: 'eth_getTransactionReceipt',
        params: [hash]
      })
      .then(async res => {
        console.log(res)
        if (res && res.status && res.status == "0x1") {
          this.$toast('执行成功！')
          this.SetBalance()
          this.loading = false
          this.status = 1
        } else if (res && res.status && res.status == "0x0") {
          this.$toast('执行失败！')
          this.SetBalance()
          this.loading = false
          this.status = 4
        } else {
          setTimeout(() => {
            this.watchHash(hash)
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error)
      })
      
    },
    accMul(arg1) {
      // 乘以10^6次方
      var m=0,s1=arg1.toString()
      try{
        m+=s1.split(".")[1].length
      }catch(e){
        // console.log(e)
      }
      let final = Number(s1.replace(".",""))*Math.pow(10,6)/Math.pow(10,m)
      // 转16进制
      // let result = final.toString(16)
      return final
    },
  }
}
</script>
<style lang="scss" scoped>
.Lock {
  position: relative;
  height: 100vh;
  padding: 0 15px;
  background: url(~@/assets/images/bg.png);
  background-size: 100% 100%;
  background-position: 0 -40px;
  .title {
    width: 100%;
    padding: 40px 0;
    text-align: center;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
  }
  .head {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    margin-bottom: 30px;
    .head-item {
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      .hi-text {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #BDBDBD;
      }
      .hi-op {
        width: 44px;
        height: 5px;
        margin-top: 5px;
        background: transparent;
      }
    }
    .head-item-active {
      .hi-text {
        color: #FFFFFF;
      }
      .hi-op {
        background: #EDC071;
        box-shadow: inset 0px -1px 2px #D28B0E;
        border-radius: 25px;
      }
    }
  }
  .main {
    width: 100%;
    padding: 30px 24px;
    margin-bottom: 24px;
    border-radius: 40px;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D;
    .main-award {
      margin: 0 4px;
      margin-bottom: 10px;
      .ma-key {
        margin-bottom: 6px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #A5A8AA;
      }
      .ma-value {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        color: #FFFFFF;
      }
      .ma-addr {
        word-break: break-all;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
      }
    }
    .main-list {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 32px;
      .main-row {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px 0;
        margin: 4px 0;
        .main-title {
          width: 100%;
          margin-bottom: 10px;
          text-align: left;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #fff;
        }
        .main-input {
          width: 100%;
          input {
            width: 100%;
            height: 32px;
            padding: 0 4px;
            margin: 0 0 18px 0;
            border-radius: 4px;
            border: 1px solid #eeeeee;
            background: transparent;
            text-align: left;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #dddddd;
          }
        }
        .main-top {
          display:flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
        .main-bottom {
          margin: 4px 0 2px 0;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #EDC071;
        }
        .main-btn {
          width: 200px;
          height: 36px;
          margin-bottom: 10px;
          margin-left: 20px;
        }
      }
      .main-row:not(:last-child) {
        border-bottom: 1px solid rgba($color: #999999, $alpha: 0.3);
      }
      .main-item {
        flex: 1;
        margin: 0 4px;
        .mi-key {
          margin-bottom: 8px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #A5A8AA;
        }
        .mi-value {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #FFFFFF;
        }
      }
    }
    .main-list-col {
      flex-direction: column;
    }
  }
  .main1 {
    height: 170px;
    background: url(~@/assets/images/bg_Mining.png);
    background-size: 100% auto;
  }
  .main2 {
    height: 270px;
    padding: 16px 24px;
    // background: url(~@/assets/images/bg_WholeNetwork.png);
    // background: url(~@/assets/images/bg_Mining.png);
    background: url(~@/assets/images/bg.png);
    background-size: 100% auto;
  }
}
</style>

