<template>
  <div class="Success">
      <img class="img" src="@/assets/images/icon_Success.png" alt="">
      <div class="text">{{$t('success.title')}}</div>
      <div class="subtext">{{$t('success.sub_title')}}</div>
      <div class="main" v-if="info.pageType && info.pageType == 2">
        <div class="item">
          <div class="key">{{$t('success.pledgeNum')}}：</div>
          <div class="value">{{info.quote}}</div>
        </div>
        <div class="item">
          <div class="key"></div>
          <div class="value link">{{$t('success.tips')}}</div>
        </div>
        <div class="item">
          <div class="key">{{$t('success.pledgeCycle')}}：</div>
          <div class="value">{{info.cycle}} {{ $t('common.day') }}</div>
        </div>
        <div class="item">
          <div class="key">{{$t('success.quote')}}：</div>
          <div class="value">{{info.node}}</div>
        </div>
        <div class="item">
          <div class="key">{{$t('success.rate')}}：</div>
          <div class="value">{{info.rate}}</div>
        </div>
        <div class="item">
          <div class="key">{{$t('success.hash')}}：</div>
          <div class="value">{{info.hash}}</div>
        </div>
        <div class="item">
          <div class="key"></div>
          <div class="value link" @click="toScan">{{$t('success.link')}} ></div>
        </div>
      </div>
      <div class="main" v-else>
        <div class="item">
          <div class="key">{{$t('success.orderId')}}：</div>
          <div class="value">{{info.sn}}</div>
        </div>
        <div class="item">
          <div class="key">{{$t('success.time')}}：</div>
          <div class="value">{{info.time}}</div>
        </div>
        <div class="item">
          <div class="key">{{$t('success.pledgeNum')}}：</div>
          <div class="value">{{info.quote}}</div>
        </div>
        <div class="item">
          <div class="key">{{$t('success.pledgeCycle')}}：</div>
          <div class="value">{{info.cycle}}</div>
        </div>
        <div class="item">
          <div class="key">{{$t('success.quote')}}：</div>
          <div class="value">{{info.node}}</div>
        </div>
        <div class="item">
          <div class="key">{{$t('success.rate')}}：</div>
          <div class="value">{{info.rate}}</div>
        </div>
      </div>
      <div class="success-btns">
        <div class="success-btn">
          <CommonBtn :btnText="$t('common.myPledge')" @btnClick="toRecord"></CommonBtn>
        </div>
        <div class="success-btn" @click="toHome">
          <CommonBtn :btnText="$t('success.back')" @btnClick="toHome" :btnType="2"></CommonBtn>
        </div>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Success',
  components: {
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
      userInfo: state => state.user.userInfo
    }),
  },
  data() {
    return {
      info: {}
    }
  },
  mounted() {
    let data = this.$route.query.data || ''
    if (data && data.length) {
      data = data.replace(/%22/g, '"')
      data = JSON.parse(data)
      if (data.node) {
        data.node = decodeURIComponent(data.node)
      }
    } else {
      data = {}
    }
    
    this.info = data
  },
  methods: {
    toScan() {
      window.open("https://polygonscan.com/tx/" + this.info.hash);
    },
    toRecord() {
      this.$router.push('/record')
    },
    toHome() {
      this.$router.push('/home')
    }
  }
}
</script>
<style lang="scss" scoped>
.Success {
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  padding: 0 15px;
  padding-top: 20px;
  background: url(~@/assets/images/bg.png);
  background-size: auto;
  background-repeat: repeat;
  .img {
    width: 86px;
    height: 86px;
    margin-top: 10px;
    margin-bottom: 18px;
    background: #2B3035;
    border-radius: 50%;
    box-shadow: 0px -2px 4px rgba(108, 108, 108, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .text {
    margin-bottom: 18px;
    font-family: 'Alibaba PuHuiTi';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #FFFFFF;
  }
  .subtext {
    margin-bottom: 18px;
    font-family: 'Alibaba PuHuiTi';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #B8B6B2;
  }
  .main {
    width: 100%;
    padding: 15px 25px;
    margin-bottom: 20px;
    background: #292D32;
    // box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px rgba(0, 0, 0, 0.66);
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D, inset 5px 11px 20px #000000;
    border-radius: 40px;
    border: 1px solid;
    border-image-source: linear-gradient(128.42deg, #000000 -16.98%, rgba(255, 255, 255, 0) 64.98%);
  }
  .item {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 14px 0;
  }
  .key {
    min-width: 80px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #B8B6B2;
  }
  .value {
    word-break: break-all;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
  }
  .link {
    font-size: 12px;
    color: #C7C7C7;
  }
  .success-btns {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 10px;
    .success-btn {
      width: 154px;
      height: 41px;
    }
  }
}
</style>

