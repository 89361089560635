<template>
  <div class="Record">
    <div class="head-tab">
      <div class="head">
        <div class="head-item" :class="{'head-item-active': pageType == 2}" @click="changeStatus(2)">
          <div class="hi-text">{{$t('asset.liquid')}}</div>
          <div class="hi-op"></div>
        </div>
        <div class="head-item" :class="{'head-item-active': pageType == 1}" @click="changeStatus(1)">
          <div class="hi-text">{{$t('asset.mining')}}</div>
          <div class="hi-op"></div>
        </div>
      </div>
    </div>
    <div class="head">
      <!-- <div class="head-top">
        <div class="ht-item">
          <div class="head-key">{{$t('record.pledgeing')}}{{systemInfo.tkname}}</div>
          <div class="head-value">{{infoData.available}}</div>
        </div>
      </div> -->
      <div class="head-bottom">
        <div class="ht-item">
          <div class="head-key">{{$t('note.allPledge')}}{{systemInfo.tkname}}</div>
          <div class="head-value2">{{infoData.quote}}</div>
        </div>
      </div>
      <div class="head-bottom">
        <div class="hb-item">
          <div class="head-key">{{$t('record.pledgeing')}}</div>
          <div class="head-value2">{{pledge}}</div>
        </div>
        <div class="hb-item">
          <div class="head-key">{{$t('record.daily')}}</div>
          <div class="head-value2">{{infoData.lock}}</div>
        </div>
      </div>
    </div>
    <!-- <div class="line"></div> -->
    <!-- <div class="content">
      <div class="desc">
        <div class="desc-title">{{$t('withdraw.extracted')}}({{systemInfo.tkname}}) 
          <img @click="openNotice" src="@/assets/images/icon_Tooltip.png" alt="">
        </div>
        <div class="desc-text">{{pledgeNum[0]}}</div>
      </div>
      <div class="btn-w">
        <CommonBtn :loading="btnLoading" :btnText="$t('record.extract')"  @btnClick="withdraw()"></CommonBtn>
      </div>
    </div> -->
    <div class="line"></div>
    <div class="tab">
      <div class="tab-item" :class="{'tab-active': tabIndex == 1}" @click="changeTabIndex(1)">
        <div class="tab-tips" v-if="countInfo.ing && countInfo.ing != 0">{{countInfo.ing}}</div>
        <div class="tab-text">{{$t('record.status_1')}}</div>
        <div class="tab-op"></div>
      </div>
      <div class="tab-item" :class="{'tab-active': tabIndex == 2}" @click="changeTabIndex(2)">
        <div class="tab-tips" v-if="countInfo.end && countInfo.end != 0">{{countInfo.end}}</div>
        <div class="tab-text">{{$t('record.status_2')}}</div>
        <div class="tab-op"></div>
      </div>
      <div class="tab-item" :class="{'tab-active': tabIndex == 3}" @click="changeTabIndex(3)">
        <div class="tab-tips" v-if="countInfo.re && countInfo.re != 0">{{countInfo.re}}</div>
        <div class="tab-text">{{$t('record.status_3')}}</div>
        <div class="tab-op"></div>
      </div>
    </div>
    <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text=""
      @load="onLoad"
    >
      <div class="list">
        <div class="item" v-for="(o, i) in exchangeLog" :key="i">
          <div class="item-top">
            <div class="item-desc">
              <div class="item-main">{{$t('exchange.note_2')}}：{{o.nodeName}}</div>
              <div class="item-notice">{{$t('record.pledgeNum')}}：{{o.quote}}</div>
            </div>
            <div class="item-btn">
              <CommonBtn v-if="tabIndex == 1" :loading="btnLoading" :btnText="$t('record.redeem')"  @btnClick="breakContract(o)"></CommonBtn>
              <template v-else-if="tabIndex == 2">
                <CommonBtn v-if="pageType == 2 && o.status == 2" :loading="btnLoading" :btnText="$t('record.withdraw')"  @btnClick="withdrawSwap(o)"></CommonBtn>
                <div v-else class="item-main">{{$t('record.status_2')}}</div>
              </template>
              <template v-else>
                <template  v-if="pageType == 2">
                  <CommonBtn v-if="o.status == 2" :loading="btnLoading" :btnText="$t('record.withdraw')"  @btnClick="withdrawSwap(o)"></CommonBtn>
                  <div v-else-if="o.status == 4" class="item-main">{{$t('record.status_4')}}</div>
                  <div v-else class="item-main">{{$t('record.status_3')}}</div>
                </template>
                <div v-else class="item-main">{{$t('record.status_3')}}</div>
              </template>
              
            </div>
          </div> 
          <div class="item-bottom">
            <div class="item-item">
              <div class="item-key">
                <img class="item-img-time" src="@/assets/images/icon_pledge1.png" alt="">
                <div class="">{{$t('record.pledgeID')}}: </div>
              </div>
              <div class="item-value">{{o.id}}</div>
            </div>
            <div class="item-item">
              <div class="item-key">
                <img src="@/assets/images/icon_contract.png" alt="">
                <div class="">{{$t('record.contractID')}}: </div>
              </div>
              <div class="item-value">{{o.contractResult}}</div>
            </div>
            <template v-if="tabIndex == 1">
              <div class="item-item">
                <div class="item-key">
                  <img class="item-img-time" src="@/assets/images/icon_time.png" alt="">
                  <div class="">{{$t('record.endTime')}}: </div>
                </div>
                <div class="item-value">{{o.endTime}}</div>
              </div>
              <div class="item-item">
                <div class="item-key">
                  <img src="@/assets/images/icon_accelerate.png" alt="">
                  <div class="">{{$t('record.rate')}}: </div>
                </div>
                <div class="item-value">{{o.profit_rate}}</div>
              </div>
              <div class="item-item">
                <div class="item-key">
                  <img src="@/assets/images/icon_estimate.png" alt="">
                  <div class="">{{$t('record.reDaily')}}: </div>
                </div>
                <div class="item-value">{{o.rewardDay}}</div>
              </div>
              <div class="item-item">
                <div class="item-key">
                  <img src="@/assets/images/icon_profit.png" alt="">
                  <div class="">{{$t('record.reward')}}: </div>
                </div>
                <div class="item-value">{{o.reward}}</div>
              </div>
            </template>
            <template v-else-if="tabIndex == 2">
              <div class="item-item">
                <div class="item-key">
                  <img class="item-img-time" src="@/assets/images/icon_time.png" alt="">
                  <div class="">{{$t('record.endTime')}}: </div>
                </div>
                <div class="item-value">{{o.endTime}}</div>
              </div>
              <div class="item-item">
                <div class="item-key">
                  <img src="@/assets/images/icon_accelerate.png" alt="">
                  <div class="">{{$t('record.source')}}: </div>
                </div>
                <div class="item-value">{{o.source}}</div>
              </div>
              <div class="item-item">
                <div class="item-key">
                  <img src="@/assets/images/icon_estimate.png" alt="">
                  <div class="">{{$t('record.reAvg')}}: </div>
                </div>
                <div class="item-value">{{o.rewardDay}}</div>
              </div>
              <div class="item-item">
                <div class="item-key">
                  <img src="@/assets/images/icon_profit.png" alt="">
                  <div class="">{{$t('record.reward')}}: </div>
                </div>
                <div class="item-value">{{o.reward}}</div>
              </div>
            </template>
            <template v-else>
              <div class="item-item">
                <div class="item-key">
                  <img class="item-img-time" src="@/assets/images/icon_time.png" alt="">
                  <div class="">{{$t('record.reTime')}}: </div>
                </div>
                <div class="item-value">{{o.reTime}}</div>
              </div>
              <div class="item-item">
                <div class="item-key">
                  <img src="@/assets/images/icon_profit.png" alt="">
                  <div class="">{{$t('record.reward')}}: </div>
                </div>
                <div class="item-value">{{o.reward}}</div>
              </div>
              <div class="item-item item-item-all">
                <div class="item-key">
                  <img src="@/assets/images/icon_apply.png" alt="">
                  <div class="">{{o.profit}}</div>
                </div>
              </div>
              <div class="item-item item-item-all">
                <div class="item-key">
                  <img class="item-img-time" src="@/assets/images/icon_time.png" alt="">
                  <div class="">{{$t('record.reTime')}}: </div>
                </div>
                <div class="item-value">{{o.reTimwInt}}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </van-list>
    <div class="nodata-div" v-if="exchangeLog.length == 0 && !firstLoad">
      <NoData ></NoData>
    </div>
    <van-popup 
      v-model:show="visible"
      :lock-srcoll="false"
      round
      class="Notice"
    >
      <div class="content">
        <div class="header">
          <div class="header-text">{{$t('login.notice')}}</div>
        </div>
        <p class="item">{{$t('record.notice')}}</p>
        <div class="edit-btn">
          <div class="edit-btn-item">
            <CommonBtn :btnText="$t('common.OK')" :loading="btnLoading" @btnClick="confirm"></CommonBtn>
          </div>
          <div class="edit-btn-item">
            <CommonBtn :btnText="$t('common.CANCEL')" :btnType="2" :loading="btnLoading" @btnClick="closeNotice"></CommonBtn>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Position, ExchangeLog, ExchangeCt, ExchangeStatus } from '@/api/user'
import NoData from '@/components/NoData'
import Web3 from "web3"
import pledgeAbi from '@/utils/pledgeAbi'
import swapAbi from '@/utils/swapAbi'
export default {
  name: 'Record',
  components: {
    NoData
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
      userInfo: state => state.user.userInfo,
      account: state => state.wallet.account,
      decimals: state => state.wallet.decimals,
      pledgeAddr: state => state.wallet.pledgeAddr,
      pledgeNum: state => state.wallet.pledgeNum,
      swapAddr: state => state.wallet.swapAddr,
    }),
    pledge() {
      if (this.pledgeNum[1] == '-' || this.pledgeNum[0] == '-') {
        return '-'
      } else {
        return +this.pledgeNum[1] - +this.pledgeNum[0]
      }
    }
  },
  watch: {
    userInfo() {
      if (this.userInfo.uid) {
        this.getData()
      }
    }
  },

  data() {
    return {
      infoData: {},
      tabIndex: 1, // 0全部1质押中 2已到期 3已赎回
      firstLoad: true,
      exchangeLog: [],
      status: '0',
      start: 0,
      limit: 10,
      loading: false,
      finished: false,
      countInfo: {},
      btnLoading: false,
      withdrawNum: '',
      visible: false,
      pageType: 2,
    }
  },
  mounted() {
    let tab = this.$route.query.tab || 2
    this.pageType = tab;
    this.getData()
  },
  methods: {
    ...mapActions(['setNoticeType', 'checkBalance', 'SetMaskLoading', 'SetLoadingText']),
    changeStatus(type) {
      this.pageType = type
      this.getData()

      this.start = 0
      this.exchangeLog = []
      this.firstLoad = true
      this.finished = false
      this.onLoad()
    },
    async getData() {
      await Position({type: this.pageType}).then(res => {
        this.infoData = res
      })
      await ExchangeCt({type: this.pageType}).then(res => {
        this.countInfo = res
      })
      await this.checkBalance(4)
    },
    changeTabIndex(type) {
      this.tabIndex = type
      this.getData()

      this.start = 0
      this.exchangeLog = []
      this.firstLoad = true
      this.finished = false
      this.onLoad()
    },
    async onLoad() {
      let start = this.start
      await ExchangeLog({status: this.tabIndex, start: start, limit: this.limit, type: this.pageType}).then(res => {
        console.log()
        if (this.firstLoad) {
          this.firstLoad = false
        }
        if (!start || start == 0) {
          this.exchangeLog = []
        }
        this.exchangeLog = this.exchangeLog.concat(res.list)
        if (this.exchangeLog.length >= res.count) {
          this.finished = true
        } else {
          this.finished = false
        }
        this.start = this.exchangeLog.length
        this.loading = false
        
      })
    },
    async breakContract(data) {
      if (!data.contractResult) {
        this.$toast(this.$t('toast.contractResult'))
        return
      }
      // 判断是否设置不可提取
      if (this.userInfo.isredeem == 2) {
        this.$toast(this.$t('withdraw.toast_1'))
        return
      }
      this.visibleData = data
      this.visible = true
    },
    async withdrawSwap(data) {
      // 判断是否设置不可提取
      if (this.userInfo.isextractZy && this.userInfo.isextractZy != 2) {
        const web3 = new Web3(window.ethereum)
        const address = this.account
        let myContract = new web3.eth.Contract(swapAbi, this.swapAddr, {
          from: address, 
        });
        this.btnLoading = true

        await myContract.methods.unstake(data.contractResult).send((error, result) => {
          console.log('unstake', error, result)
          if (!error) {
            this.btnLoading = false
            this.changeTabIndex(this.tabIndex)
            this.$toast(this.$t('toast.withdraw'))
          } else {
            this.btnLoading = false
            error = error || this.$t('toast.dealFailed')
            this.$toast(error)
          }
        });
      } else if (this.userInfo.isextractZy == 2) {
        this.$toast(this.$t('withdraw.toast_1'))
        return
      }
    },
    async confirm() {
      let data = this.visibleData
      this.visible = false
      const web3 = new Web3(window.ethereum)
      const address = this.account
      let addr = ''
      let abi = ''
      if (this.pageType == 2) {
        addr = this.swapAddr
        abi = swapAbi
      } else {
        addr = this.pledgeAddr
        abi = pledgeAbi
      }
      let myContract = new web3.eth.Contract(abi, addr, {
        from: address, 
      });
      this.btnLoading = true
      this.SetMaskLoading(true)
      this.SetLoadingText('Loading..')
      if (this.pageType == 2) {
        await myContract.methods.prematureUnstake(data.contractResult).send((error, result) => {
          this.doAction('prematureUnstake', error, result)
        });
      } else {
        await myContract.methods.breakContract(data.contractResult).send((error, result) => {
          this.doAction('breakContract', error, result)
        });
      }
     
    },
    doAction(action, error, result) {
      console.log(action, error, result)
      this.SetMaskLoading(false)
      if (!error) {
        this.btnLoading = false
        this.$toast(this.$t('toast.breakContract'))
        this.changeTabIndex(this.tabIndex)
      } else {
        this.btnLoading = false
        error = error || this.$t('toast.dealFailed')
        this.$toast(error)
      }
    },
    async withdraw() {
      // 判断是否设置不可提取
      if (this.userInfo.isextractZy && this.userInfo.isextractZy != 2) {
        if (this.pledgeNum[0] == 0) {
          this.$toast(this.$t('toast.noBalance'))
          return
        }
        const web3 = new Web3(window.ethereum)
        const address = this.account
        let myContract = new web3.eth.Contract(pledgeAbi, this.pledgeAddr, {
          from: address, 
        });
        this.btnLoading = true

        await myContract.methods.Withdraw().send((error, result) => {
          console.log('Withdraw', error, result)
          if (!error) {
            ExchangeStatus({
              id: -1,
              txid: result,
              status: 2,
              value: this.pledgeNum[0],
              type: this.pageType
            }).then(res => {
              console.log(res)
              this.btnLoading = false
              this.changeTabIndex(this.tabIndex)
              this.$toast(this.$t('toast.withdraw'))
            })
          } else {
            this.btnLoading = false
            error = error || this.$t('toast.dealFailed')
            this.$toast(error)
          }
        });
      } else if (this.userInfo.isextractZy == 2) {
        this.$toast(this.$t('withdraw.toast_1'))
        return
      }
    },
    openNotice() {
      this.setNoticeType(11)
    },
    closeNotice() {
      this.visible = false
    },
  }
}
</script>
<style lang="scss" scoped>
.head-tab {
  .head {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 0px;
    margin-bottom: 30px;
    .head-item {
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      .hi-text {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #BDBDBD;
      }
      .hi-op {
        width: 44px;
        height: 5px;
        margin-top: 5px;
        background: transparent;
      }
    }
    .head-item-active {
      .hi-text {
        color: #FFFFFF;
      }
      .hi-op {
        background: #EDC071;
        box-shadow: inset 0px -1px 2px #D28B0E;
        border-radius: 25px;
      }
    }
  }
}
.Record {
  min-height: calc(100vh - 45px);
  padding: 10px 15px 20px 15px;
  background: url(~@/assets/images/bg.png);
  background-size: auto;
  background-repeat: repeat;
  .head {
    margin-bottom: 22px;
    .head-top {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
      .ht-item {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    .head-bottom {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 5px;
      .hb-item {
        flex: 1;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 10px 0;
      }
    }
    .head-key {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #A5A8AA;
    }
    .head-value {
      margin-top: 10px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 31px;
      color: #FFFFFF;
    }
    .head-value2 {
      margin-top: 4px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
    }
  }
  .line {
    width: 100%;
    height: 4px;
    margin-bottom: 22px;
    background: rgba(36, 37, 40, 0.7);
    box-shadow: inset -2px -2px 4px rgba(255, 255, 255, 0.08), inset 2px 2px 4px rgba(0, 0, 0, 0.8);
    border-radius: 25px;
  }
  .content {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px 0;
    .desc {
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .desc-title {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #A5A8AA;
        img {
          width: 25px;
          height: 25px;
          margin-left: 4px;
        }
      }
      .desc-text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #FFFFFF;
      }
    }
    .btn-w {
      width: 90px;
      height: 36px;
    }
  }
  .tab {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
    .tab-item {
      position: relative;
      flex: 1;
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .tab-tips {
        position: absolute;
        right: 12px;
        top: -2px;
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        background: linear-gradient(320.66deg, #FE4F4F 14.75%, #F72424 84.81%);
        border: 1px solid #BF0202;
        box-shadow: 0px 4px 4px #000000, 4px 6px 20px rgba(0, 0, 0, 0.35), 0px -2px 4px rgba(255, 255, 255, 0.14);
        border-radius: 30px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 9px;
        color: #FFFFFF;
      }
    }
    .tab-text {
      margin-bottom: 7px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #A5A8AA;
    }
    .tab-op {
      width: 34px;
      height: 5px;
      background: transparent;
    }
    .tab-active {
      .tab-text {
        color: #FFFFFF;
      }
      .tab-op {
        background: #EDC071;
        box-shadow: inset 0px -1px 2px #D28B0E;
        border-radius: 25px;
      }
    }
  }
  .list {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
    .item {
      position: relative;
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 14px 0;
      padding: 14px 21px;
      background: #202122;
      // box-shadow: -6px -6px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
      box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D, inset 5px 11px 20px #000000;
      border-radius: 30px;
      .item-top {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 4px;
      }
      .item-notice {
        font-size: 14px;
        // font-weight: bold;
        line-height: 20px;
        color: #FFFFFF;
      }
      .item-main {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 8px 0;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #D9D9D9;
      }
      .item-desc {
        flex: 1;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        
      }
      .item-btn {
        width: 76px;
        height: 36px;
        margin-left: 4px;
      }
      .item-bottom {
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
      }
      .item-item {
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 50%;
        margin: 4px 0;
        .item-key {
          display:flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-right: 2px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #D9D9D9;
          img {
            width: 15px;
            height: 15px;
            margin-right: 3px;
          }
        }
        .item-value {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: #EDC071;
        }
      }
      .item-item-all {
        width: 100%;
        .item-key {
          img {
            width: 16px;
            height: 16px;
            margin-right: 3px;
          }
        }
      }
      .item-img-time {
        width: 17px !important;
        height: 17px !important;
        margin-right: 1px !important;
      }
    }
  }
  .nodata-div {
    width: 100%;
    // height: 100vh;
  }
  .Notice {
    border-radius: 40px;
    .content {
      flex-direction: column;
      width: 320px;
      height: 200px;
      margin: 0;
      padding: 15px 18px;
      background: #292D32;
      box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 0px -1px 11px rgba(255, 255, 255, 0.25), inset 5px 11px 20px rgba(0, 0, 0, 0.59);
      // border-radius: 40px;
    }

    .header {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      font-family: 'Alibaba PuHuiTi';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #FFFFFF;
    }
    .item {
      margin-bottom: 2px;
      // text-indent:2em;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #FFFFFF;
    }
    .edit-btn {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 20px;
      .edit-btn-item {
        width: 130px;
        height: 40px;
      }
    }
  }
}
</style>

