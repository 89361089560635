<template>
    <div class="Admit">
      <div class="step">
        <div class="step-top">
          <div class="st-circle">
            <div class="stc-in"></div>
          </div>
          <div class="st-line"></div>
          <div class="st-circle">
            <div class="stc-em"></div>
          </div>
          <div class="st-line"></div>
          <div class="st-circle">
            <div class="stc-em"></div>
          </div>
        </div>
        <div class="step-bottom">
          <div class="sb-item">{{$t('withdraw.step_1')}}</div>
          <div class="sb-item">{{$t('withdraw.step_2')}}</div>
          <div class="sb-item">{{$t('withdraw.step_3')}}</div>
        </div>
      </div>
      <div class="flexcol">
        <img class="success" src="@/assets/images/admit_success.png"/>
        <div class="title">{{$t('withdraw.admitted')}}</div>
        <div class="subtitle">{{ '提现申请提交成功，平台会在1-2个工作日内审核。一旦通过，币会自动转入您的提现钱包。' }}</div>
        <div class="backtohome">
            <CommonBtn :btnText="$t('success.back')"  @btnClick="toHome"></CommonBtn>
        </div>
    </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex'
  export default {
    name: 'Admit',
    components: {
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system.systemInfo,
        mudBalance: state => state.wallet.mudBalance,
      }),
    },
    data() {
      return {
        
      }
    },
    mounted() {
     
    },
    methods: {
      ...mapActions(['GetUserInfo', 'checkLink', 'setNoticeType', 'SetBalance', 'SetMaskLoading', 'SetLoadingText']),
      toHome(){
        this.$router.push('/home')
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  :root {
    --van-overlay-background-color: rgba(0, 0, 0, .4) ;
  }
  
  .Admit {
    min-height: calc(100vh - 45px);
    padding: 0 15px;
    padding-top: 20px;
    padding-bottom: 10px;
    background: url(~@/assets/images/bg.png);
    background-size: auto;
    background-repeat: repeat;
  
    .slide-tips {
      width: 100%;
      margin: -10px 0 25px 0;
      text-align: center;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #EDC67F;
    }
    .step {
      width: 100%;
      margin-bottom: 38px;
      .step-top {
        display:flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 0 15px;
        .st-circle {
          display:flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          background: #2C2F34;
          box-shadow: 0px -3px 6px rgba(147, 147, 147, 0.25), 0px 4px 4px #000000;
          border-radius: 50%;
          .stc-in {
            width: 12px;
            height: 12px;
            background: linear-gradient(180deg, #EFBA5B 0%, #EDC57C 100%);
            box-shadow: inset 0px 4px 4px #B16D00;
            border-radius: 50%;
          }
          .stc-em {
            width: 12px;
            height: 12px;
            background: #2C2F34;
            box-shadow: inset 0px 4px 4px #000000, inset 0px -3px 6px rgba(147, 147, 147, 0.25);
            border-radius: 50%;
          }
        }
        .st-line {
          width: 80px;
          height: 4px;
          background: rgba(36, 37, 40, 0.7);
          box-shadow: inset -2px -2px 4px rgba(255, 255, 255, 0.08), inset 2px 2px 4px rgba(0, 0, 0, 0.8);
          border-radius: 25px;
        }
      }

      
      .step-bottom {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        .sb-item {
          // flex: 1;
          min-width: 65px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          text-align: center;
          color: #A5A8AA;
        }
      }
    }
}
.backtohome {
    height: 49px;
    width: 90%;
    margin: 40px auto;
    border-radius: 30px;
    box-shadow: 0px 10px 10px #000000;
}
.flexcol {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.success {
    height: 62px;
    width: 62px;
    margin-top: 30px;
    border-radius: 31px;
    box-shadow: 0px 4px 4px #000000;
}
.title {
    font-size: 18px;
    color: white;
    margin-top: 15px;
}
.subtitle {
    font-size: 13px;
    color: #A5A8AA;
    width: 95%;
    text-align: center;
    margin-top: 15px;
}
</style>