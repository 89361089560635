<template>
    <div class="NftTab">
        <div class="hn-main">
            <div class="hnm-logo">
                <img class="hnm-logo-img" v-if="systemInfo.logo" :src="systemInfo.logo" alt="">
            </div>
            <div class="hnm-btn" @click="goPage('/mynft')">
                {{ $t('nft.mynft') }}
            </div>
        </div>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item, index) in systemInfo.banner.filter(item=>item.type=='4')" :key="index" @click="goDetail(item)">
                <img class="swipe-img" v-lazy="item.url" />
            </van-swipe-item>
        </van-swipe>
        <van-list
            v-model:loading="loading"
            :finished="finished"
        >
            <div class="compose-list">
                <div v-for="(item, index) in composeList" :key="index">
                    <!-- <img class="compose-logo" v-lazy="'https://ipfs.infura.io/ipfs/QmfZVQ1w2V1W2BGAwNy9PKif6p1Jt6UBm5ymsvY6HxWiZS/animals/gold/deer/1.json'" /> -->
                    <div class="compose-item" v-if="item.data.length>0" @click="goPage('/compose?lv='+item.lv)">
                        <img :src="fullImage(item.data[0].uri)" alt="Image" class="compose-logo">
                        <div class="compose-text">{{ getName(item.lv) }}</div>
                        <div class="hnm-btn">{{ $t('nft.compose') }}</div>
                    </div>
                </div>
            </div>
        </van-list>
        <div style="color: white;font-family: 'PingFang SC';
            font-style: normal;
            margin-left: 20px;
            font-weight: 400;
            font-size: 16px;">{{ $t('nft.deal') }}</div>
        <div class="list-condition">
            <div class="small-box box-dec">
                <span>{{ $t('nft.price') }}</span>
                <img style="height: 17px; width: 17px; margin-left: 5px;" src="../../assets/images/icon_arrow2.png" alt="">
            </div>
            <div class="small-box box-dec" @click="visible=true">
                <span>{{ $t('nft.sort') }}</span>
                <img style="height: 7px; width: 11px; margin-left: 5px;" src="../../assets/images/icon_arrow1.png" alt="">
            </div>
            <div class="big-box box-dec">
                <img style="height: 15px; width: 15px; margin-right: 5px;" src="../../assets/images/icon_search.png" alt="">
                <span>{{ $t('nft.inputid') }}</span>
            </div>
        </div> 
        <div style="width: 100%; text-align: center;">
            <img style="height: 150px; margin: 20px auto;" src="../../assets/images/Group799.png" alt="">
            <div style="color: white; font-weight: 400; font-size: 16px;">{{ $t('nft.developing') }}</div>
        </div>
        <van-popup
            v-model:show="visible"
            position="bottom"
            style="background: none;"
        >
            <Sort
                :closePop="()=>{visible=false}"
                :sortCleanUp="sortCleanUp"
                :sortConfirm="sortConfirm"
            />
        </van-popup>
        <TabBar :active="1"></TabBar>
    </div>
</template>
  
<script>
  import { mapState } from 'vuex'
  import { nftList } from '@/api/user'
  import TabBar from '@/components/TabBar'
  import Sort from '@/components/Sort'
  import { fullImageUrl, getContract } from '@/utils/filter'
  export default {
    name: 'NftTab',
    components: {
        TabBar,
        Sort
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system.systemInfo,
        userInfo: state => state.user.userInfo,
        account: state => state.wallet.account
      }),

      
    },
    data() {
        return {
            images: [],
            composeList: [],
            loading: true,
            finished: false,
            visible: false,
        }
    },
    mounted() {
        this.getNft()
        
    },
    methods: {

        goPage(url){
            this.$router.push(url)
        },
        getNft(){
            this.loading = true;
            this.finished = false;
            nftList().then((res)=>{
                res.list.splice(0,1);
                this.composeList = res.list;
            })
            this.loading = false;
            this.finished = true;

        },
        fullImage(url){
            return fullImageUrl(url)
        },
        getName(lv){
            return getContract(lv).name
        },
        goDetail(item) {
            // 链接类型 1不跳转 2内链 3外链
            switch(item['type']){
                case '1':
                    this.$router.push('/message?type=1&&id='+item['id'].toString())
                    break;
                case '2':
                    this.$router.push(item['url'])
                    break;
                case '3':
                    window.open(item['url'])
                    break;
            }
        },
        sortCleanUp(){
            this.visible = false;
        },
        sortConfirm(){
            this.visible = false;
        }
    }
  }
</script>
<style lang="scss" scoped>
.NftTab {
    position: relative;
    min-height: calc(100vh - 50px);
    padding: 0 0px;
    padding-top: 0px;
    padding-bottom: 70px;
    background: url(~@/assets/images/bg_NFT.png);
    background-size: 100% auto;
    background-position: 0 -40px;
    .hn-main {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px 15px;
        .hnm-icon {
            width: 16px;
            height: 12px;
        }
        .hnm-logo {
            display:flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex: 1;
            .hnm-logo-img {
                width: 38px;
                height: 38px;
                margin-right: 7px;
                border-radius: 50%;
            }
            .hnm-logo-text {
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 25px;

                color: #FFFFFF;
            }
        }
        
    }
}
.hnm-btn {
    height: 31px;
    width: 87px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(~@/assets/images/btn_NFT.png);
    background-size: 100% auto;
}
.my-swipe {
    color: #fff;
    font-size: 20px;
    height: 156px;
    width: 341px;
    background: #fffffe94;
    border-radius: 9px;
    margin: 5px auto;
    .swipe-img {
        height: 156px;
        width: 341px;
        object-fit: cover;
    }
}
.compose-list {
    display: -webkit-box; 
    overflow-x: scroll;
    overflow-y: hidden;
    width: 341px;
    margin: 20px auto;
    white-space: nowrap;
    .compose-item {
        height: 205px;
        width: 135px;
        border-radius: 17px;
        background: #3E4C66;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 10px;
        border: 1px solid #3E4C66;
        box-shadow: inset 0px 4px 21px #39456F;
        
        .compose-logo {
            height: 121px;
            width: 121px;
            border-radius: 10px;
            object-fit: cover;
        }
        .compose-text {
            color: white;
            font-size: 12px;
            margin: 10px 0;
        }
    }
}
.list-condition {
    display: flex;
    width: 341px;
    height: 38px;
    margin: 10px auto;
    .small-box {
        height: 38px;
        width: 82px;
        margin-right: 10px;
    }
    .big-box {
        height: 38px;
        width: 161px;
    }
    .box-dec {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 17px;
        border: 1px solid #FFE6007D;
        box-shadow: inset 0px 4px 21px #39456F;
        color: #465667;
        font-size: 13px;
        font-weight: 400;
    }
}
</style>
  
  