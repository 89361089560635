<template>
  <div class="Nft">
    <div class="">mud余额：{{ mudBalance }}</div>
    <div class="" @click="queryNftBtn">queryNft</div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { GetRule } from '@/api/system'
export default {
  name: 'Pdf',
  components: {
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
      mudBalance: state => state.wallet.mudBalance,
    }),
  },
  data() {
    return {
      ruleInfo: {}
    }
  },
  mounted() {
    GetRule({type: 1}).then(res => {
      this.ruleInfo = res
      this.ruleInfo.content = this.ruleInfo.content.replace(/<img/g, '<img style="width: 100%;height:auto;" ')
    })
  },
  methods: {
    ...mapActions(['SetMaskLoading', 'SetLoadingText', 'queryNft']),
    queryNftBtn() {
      this.queryNft()
    }
  }
}
</script>
<style lang="scss" scoped>
.Nft {
  min-height: calc(100vh - 45px);
  padding: 20px 15px 20px 15px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;

  color: #B8B6B2;
}
</style>

