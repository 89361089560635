<template>
  <div class="Note">
    <div class="title">{{$t('note.all')}}</div>
    <div class="symbol"></div>
    <div class="head">
      <div class="account">
        <div class="text">{{infoData.sup.name}}</div>
        <div class="tag" :class="{'tag-close': infoData.sup.rewardstatus == 2}">{{infoData.sup.rewardstatus == 1 ? $t('note.normal') : $t('note.pause')}}</div>
        <div class="more" @click="toMore">详情 ></div>
      </div>
      <div class="head-list">
        <div class="head-item">
          <div class="hi-key">{{$t('note.nowPledge')}}</div>
          <div class="hi-value">{{infoData.sup.pledge}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{$t('note.monthAdd')}}</div>
          <div class="hi-value">{{infoData.sup.month}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{$t('note.earnRatio')}}</div>
          <div class="hi-value">{{infoData.sup.rate}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{$t('note.issueCycle')}}</div>
          <div class="hi-value">{{infoData.sup.ratetype}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{$t('asset.error_item2')}}</div>
          <div class="hi-value">{{infoData.sup.reward}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{$t('note.issued')}}</div>
          <div class="hi-value">{{infoData.sup.rewarding}}</div>
        </div>
      </div>
    </div>
    <div class="main"  @click="openModal(infoData.my, true)">
      <div class="title">{{$t('note.myInfo')}}</div>
      <div class="symbol"></div>
      <div class="content">
        <div class="avatar">{{infoData.my.name ? infoData.my.name[0] : '-'}}</div>
        <div class="desc">
          <div class="desc-head">
            <div class="account">
              <div class="text">{{infoData.my.name}}</div>
              <div class="tag" :class="{'tag-close': infoData.my.rewardstatus == 2}">{{infoData.my.rewardstatus == 1 ? $t('note.normal') : $t('note.pause')}}</div>
            </div>
            <div class="address">{{infoData.my.user ? infoData.my.user.slice(0,4) + '...' + infoData.my.user.slice(infoData.my.user.length - 4) : '-'}}</div>
          </div>
          <div class="desc-main">
            <div class="desc-item">
              <div class="di-key">{{$t('note.team')}}</div>
              <div class="di-value">{{infoData.my.team}}</div>
            </div>
            <div class="desc-item">
              <div class="di-key">{{$t('note.ratio')}}</div>
              <div class="di-value">{{infoData.my.rate}}</div>
            </div>
            <div class="desc-item">
              <div class="di-key">{{$t('note.issued')}}</div>
              <div class="di-value">{{infoData.my.rewarding}}</div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <div class="main">
      <div class="main-add" @click="add">+</div>
      <div class="title">{{$t('note.child')}}</div>
      <div class="symbol"></div>
      <template v-if="infoData.son.length != 0">
        <div class="content" v-for="(item, index) in infoData.son" :key="index" @click="openModal(item)">
          <div class="avatar">{{item.name ? item.name[0] : '-'}}</div>
          <div class="desc">
            <div class="desc-top">
              <div class="desc-head">
                <div class="account">
                  <div class="text">{{item.name}}</div>
                  <div class="tag" :class="{'tag-close': item.rewardstatus == 2}">{{item.rewardstatus == 1 ? $t('note.normal') : $t('note.pause')}}</div>
                </div>
                <div class="address">{{item.user ? item.user.slice(0,4) + '...' + item.user.slice(item.user.length - 4) : '-'}}</div>
              </div>
              <div class="desc-btn">
                <div class="desc-btn-edit" @click.stop="edit(item)">{{ $t('common.edit') }}</div>
                <!-- <div class="desc-btn-del">{{ $t('common.del') }}</div> -->
              </div>
            </div>
            <div class="desc-main">
              <div class="desc-item">
                <div class="di-key">{{$t('note.team')}}</div>
                <div class="di-value">{{item.team}}</div>
              </div>
              <div class="desc-item">
                <div class="di-key">{{$t('note.ratio')}}</div>
                <div class="di-value">{{item.rate}}</div>
              </div>
              <div class="desc-item">
                <div class="di-key">{{$t('note.issued')}}</div>
                <div class="di-value">{{item.rewarding}}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="noChild" v-else>{{$t('note.noChild')}}</div>
    </div>
    <van-popup 
      v-model:show="visible"
      round
      class="nodeDetail"
    >
      <div class="content">
        <div class="head">
          <div class="avatar">{{modalData.name ? modalData.name[0] : '-'}}</div>
          <div class="desc-head">
            <div class="account">
              <div class="text">{{modalData.name}}</div>
              <div class="tag" :class="{'tag-close': modalData.rewardstatus !== 2}">{{modalData.rewardstatus == 1 ? $t('note.normal') : $t('note.pause')}}</div>
            </div>
            <div class="address">{{modalData.user ? modalData.user.slice(0,4) + '...' + modalData.user.slice(modalData.user.length - 4) : '-'}}</div>
          </div>
          <!-- <img class="head-edit" v-if="!isMine" @click="edit" src="@/assets/images/edit.png" alt=""> -->
        </div>
        <div class="list">
          <div class="item">
            <div class="key">{{$t('note.team')}}</div>
            <div class="value">{{modalData.team}}</div>
          </div>
          <div class="item">
            <div class="key">{{$t('note.add')}}</div>
            <div class="value">{{modalData.month}}</div>
          </div>
          <div class="item">
            <div class="key">{{$t('note.superior')}}</div>
            <div class="value">{{modalData.uprate == 1 ? $t('note.type_1') : $t('note.type_2')}}{{modalData.uprate == 1 ? `(${modalData.rate}%)`: ''}}</div>
          </div>
          <div class="item">
            <div class="key">{{$t('note.issueCycle')}}</div>
            <div class="value">{{modalData.ratetype == 1 ? $t('note.day') : $t('note.month')}}</div>
          </div>
          <div class="item">
            <div class="key">{{$t('note.accumulated')}}</div>
            <div class="value">{{modalData.reward}}</div>
          </div>
          <div class="item">
            <div class="key">{{$t('note.issued')}}</div>
            <div class="value">{{modalData.rewarding}}</div>
          </div>
        </div>
      </div>
    </van-popup>
    <NoteContent ref="NoteContent" @updatePage="updatePage"></NoteContent>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { NodeInfo, nodeDetail } from '@/api/user'
import NoteContent from '@/components/NoteContent'
export default {
  name: 'Note',
  components: {
    NoteContent
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
      userInfo: state => state.user.userInfo,
    }),
  },
  watch: {
    userInfo() {
      if (this.userInfo.uid) {
        this.getData()
      }
    }
  },
  data() {
    return {
      infoData: {
        sup: {},
        my: {},
        son: {}
      },
      modalData: {},
      visible: false,
      isMine: true,
    }
  },
  mounted() {
    this.SetLoadingText(this.$t('common.loading'))
    this.SetMaskLoading(true)
    this.getData()
  },
  methods: {
    ...mapActions(['SetMaskLoading', 'SetLoadingText', 'setResetPage']),
    async getData() {
      await NodeInfo().then(res => {
        console.log(res)
        this.infoData = res
        this.SetMaskLoading(false)
      })
    },
    add() {
      this.$refs.NoteContent.show()
    },
    openModal(data, isMine = false) {
      this.isMine = isMine
      if (isMine) {
        nodeDetail({nodeid: data.nodeid}).then(res => {
          this.modalData = res
          this.visible = true
        })
      } else {
        this.$router.push(`/more?nodeid=${data.nodeid}&moreType=2`)
      }
    },
    edit(data) {
      this.visible = false
      nodeDetail({nodeid: data.nodeid}).then(res => {
        this.$refs.NoteContent.show(res)
      })
      // this.$refs.NoteContent.show(this.modalData)
    },
    updatePage() {
      this.getData()
    },
    toMore() {
      this.$router.push(`/more?nodeid=${this.infoData.sup.nodeid}`)
    }
  }
}
</script>
<style lang="scss" scoped>
.Note {
  min-height: calc(100vh - 45px);
  padding: 14px 15px 20px 15px;
  background: url(~@/assets/images/bg.png);
  background-size: auto;
  background-repeat: repeat;
  .title {
    margin-bottom: 7px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    color: #FFFFFF;
  } 
  .symbol {
    margin-bottom: 20px;
    width: 29px;
    height: 6px;
    background: linear-gradient(90deg, #F6DFA9 0%, #EDC67F 75.52%);
  }
  .account {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    .text {
      margin-right: 10px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
    }
    .tag {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1px 8px;
      background: linear-gradient(90deg, #EEBE66 0%, #EDC67F 100%);
      border-radius: 3px;
    }
    .tag-close {
      color: #fff;
      background: #FB3E3E;
    }
    .more {
      flex: 1;
      text-align: right;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #EDC071;
    }
  }
  .head {
    margin-bottom: 22px;
    .head-list {
      display:flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 10px 0;
      .head-item {
        width: 46%;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 4px 0;
        .hi-key {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #A5A8AA;
        }
        .hi-value {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #FFFFFF;
        }
      }
    }
  }
  .main {
    position: relative;
    width: 100%;
    padding: 24px 20px 14px 20px;
    margin-bottom: 30px;
    background: #202122;
    // box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D, inset 5px 11px 20px #000000;
    border-radius: 40px;
    .main-add {
      position: absolute;
      right: 20px;
      top: 10px;
      font-size: 24px;
      color: #EDC274;
    }
    .content {
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .avatar {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: linear-gradient(320.66deg, #141515 14.75%, #2E3236 84.81%);
      border: 1px solid #282B2E;
      box-shadow: 0px 4px 4px #000000, 4px 6px 20px rgba(0, 0, 0, 0.35), 0px -2px 4px rgba(255, 255, 255, 0.14);
      border-radius: 30px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #EDC274;
    }
    .desc {
      flex: 1;
      margin-left: 14px;
      .desc-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .desc-btn {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .desc-btn-edit {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 3px 10px;
        margin-right: 6px;
        background: linear-gradient(90deg, #EEBE66 0%, #EDC67F 100%);
        border-radius: 3px;
      }
      .desc-btn-del {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 1px 8px;
        border-radius: 3px;
        color: #fff;
        background: #FB3E3E;
      }
      .desc-head {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
      .address {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #D9D9D9;
      }
      .desc-main {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 12px 0;
      }
      .desc-item {
        flex: 1;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        .di-key {
          margin-bottom: 6px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          color: #A5A8AA;
        }
        .di-value {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          color: #A5A8AA;
        }
      }
    }
  }

  .noChild {
    width: 100%;
    margin: 10px 0;
    text-align: center;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
  }
  .nodeDetail {
    border-radius: 40px;
    .content {
      width: 320px;
      // height: 350px;
      padding: 15px 18px;
      background: #292D32;
      box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 0px -1px 11px rgba(255, 255, 255, 0.25), inset 5px 11px 20px rgba(0, 0, 0, 0.59);
      // border-radius: 40px;
    }
    .head {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      .avatar {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        margin-right: 14px;
        border-radius: 50%;
        background: linear-gradient(320.66deg, #141515 14.75%, #2E3236 84.81%);
        border: 1px solid #282B2E;
        box-shadow: 0px 4px 4px #000000, 4px 6px 20px rgba(0, 0, 0, 0.35), 0px -2px 4px rgba(255, 255, 255, 0.14);
        border-radius: 30px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #EDC274;
      }
      .desc-head {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex: 1;
      }
      .address {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #D9D9D9;
      }
    }
    .head-edit {
      width: 30px;
      height: 30px;
    }
    .list {
      width: 100%;
      .item {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin:12px 0;
        .key {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #A5A8AA;
        }
        .value {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>

