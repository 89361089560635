<template>
    <div class="NftDetail">
        <img class="back-btn" src="../../assets/images/icon_Return.png" alt="" @click="goBack()">
        <div class="title" style="margin-top: 50px;">{{ nftName(detail.lv)+ '-' + attribute(detail.lv, parseInt(detail.tokenId)) }}</div>
        <div class="title" style="margin-top: 5px;">{{ '#'+detail.tokenId }}</div>
        <img class="logo" v-lazy="fullImage(detail.uri)" />
        <div class="dbtn" @click="this.$toast('开发中')">{{ $t('nft.tosold') }}</div>
        <div class="title" style="margin: 10px 0 0 15px; font-size: 18px;">{{ $t('nft.detail') }}</div>
        <div class="detail">
            <div class="pair" @click="copyAddr">
                <div class="grey">{{ $t('nft.address') }}</div>
                <div style="display: flex; align-items: center;" >
                    <div class="white">{{ nftAddr(detail.lv) }}</div>
                    <img style="height: 12px; width: 12px; margin-left: 5px;" src="../../assets/images/icon_coppy.png" alt="">
                </div>
            </div>
            <div class="pair">
                <div class="grey">{{ $t('nft.token') + 'ID' }}</div>
                <div class="white">{{ '#'+detail.tokenId }}</div>
            </div>
            <div class="pair">
                <div class="grey">{{ $t('nft.network') }}</div>
                <div class="white">Polygon</div>
            </div>
            <div class="pair">
                <div class="grey">{{ $t('nft.rule') }}</div>
                <div class="white">ERC 721</div>
            </div>
        </div>
    </div>
</template>
  
<script>
  import { mapState } from 'vuex'
  import { fullImageUrl, getContract, getAttribute } from '@/utils/filter'
  export default {
    name: 'NftDetail',
    computed: {
      ...mapState({
        systemInfo: state => state.system.systemInfo,
        userInfo: state => state.user.userInfo,
        account: state => state.wallet.account
      }),
    },
    data() {
        return {
            detail: {}
        }
    },
    mounted() {
        this.setInfo()
    },
    methods: {
        goBack(){
            this.$router.back();
        },
        setInfo(){
            this.detail = JSON.parse(this.$route.query.detail)
        },
        fullImage(url){
            return fullImageUrl(url)
        },
        nftName(lv){
            return getContract(lv).name
        },
        nftAddr(lv){
            if(!lv)return;
            let address = getContract(lv).addr;
            return address.substring(0,6) + '...' + address.substring(address.length - 4);
        },
        copyAddr(){
            navigator.clipboard.writeText(getContract(this.detail.lv).addr)
            this.$toast("复制成功")
        },
        attribute(lv, id){
            return getAttribute(lv, id)
        },
    }
  }
</script>
<style lang="scss" scoped>
.NftDetail {
    position: relative;
    min-height: 100%;
    padding: 0 20px;
    padding-top: 10px;
    // padding-bottom: 70px;
    background: url(~@/assets/images/bg_NFT.png);
    background-size: 100% auto;
    background-position: 0 -40px;
}
.back-btn {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 20px;
    left: 20px;
}
.title {
    font-size: 24px;
    font-weight: 400;
    color: white;
    margin-left: 15px;
}
.logo {
    height: 308px;
    width: 311px;
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px 10px 0 10px;
}
.dbtn {
    height: 46px;
    width: 299px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(~@/assets/images/btn_purchase.png);
    background-size: 100% 100%;
    margin: 20px auto;
    font-size: 14px;
    font-weight: 400;
        
}
.detail {
    background: #2F2F2F;
    height: 143px;
    width: 336px;
    border-radius: 9px;
    margin-top: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .pair {
        display: flex;
        justify-content: space-between;

        .white {
            font-size: 13px;
            color: white;
        }
        .grey {
            font-size: 13px;
            color: rgba($color: #FFFFFF, $alpha: 0.5);
        }
    }
}
</style>
  
  