<template>
  <van-popup
    v-model:show="visible"
    position="bottom"
    round
    class="Withdraw"
  >
    <!-- teleport="#App" -->
    <div class="content">
      <div class="head">
        <div class="head-item" :class="{'head-active': tabIndex == 0}" @click="changeTabIndex(0)">{{$t('withdraw.mExtract')}}</div>
        <div class="head-item" :class="{'head-active': tabIndex == 1}" @click="changeTabIndex(1)">{{$t('withdraw.nExtract')}}</div>
        <div class="head-flex"></div>
        <img class="head-img" @click="close" src="@/assets/images/icon_Delete.png" alt="">
      </div>
      <div class="symbol"></div>
      <div class="wallet">
        <div class="wallet-text">{{$t('withdraw.extract')}}</div>
        <div class="wallet-address">{{account}}</div>
      </div>
      <div class="list">
        <div class="item">
          <div class="item-key">{{contractName}}{{$t('withdraw.extracted')}}{{systemInfo.tkname}}{{$t('withdraw.balance')}}:</div>
          <div class="item-value item-gold">{{num}}</div>
        </div>
        <div class="item">
          <div class="item-key">GAS {{$t('withdraw.fee')}}:</div>
          <div class="item-value">{{$t('withdraw.consume')}} MATIC</div>
        </div>
        <div class="item">
          <div class="item-key">{{$t('withdraw.wallet')}}MATIC{{$t('withdraw.balance')}}:</div>
          <div class="item-value">{{ethBalance}} MATIC</div>
        </div>
      </div>
      <div class="notice" v-if="ethBalance < 10">
        <img src="@/assets/images/icon_Notes_red.png" alt="">
        <div class="color-down">{{$t('withdraw.notice')}}</div>
      </div>
      <div class="w-btn">
        <CommonBtn :btnText="$t('withdraw.btn_1')" :loading="btnLoading" @btnClick="submit"></CommonBtn>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ExtractLog } from '@/api/user'
import angleAbi from '@/utils/angleAbi'
import abAbi from '@/utils/abAbi'
import teamAbi from '@/utils/teamAbi'
import miningAbi from '@/utils/miningAbi'
import Web3 from "web3"
export default {
  name: 'Withdraw',
  data() {
    return {
      visible: false,
      tabIndex: 0, // 0挖矿 1全网 
      contractType: 0, // 1 天使轮 2 AB轮 3 团队成员
      contractName: '',
      contractAddr: '',
      contractAbi: '',
      num: '-',
      btnLoading: false,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      systemInfo: state => state.system.systemInfo,
      account: state => state.wallet.account,
      ethBalance: state => state.wallet.ethBalance,
      angleAddr: state => state.wallet.angleAddr,
      abAddr: state => state.wallet.abAddr,
      teamAddr: state => state.wallet.teamAddr,
      angleNum: state => state.wallet.angleNum,
      abNum: state => state.wallet.abNum,
      teamNum: state => state.wallet.teamNum,
      pledgeNum: state => state.wallet.pledgeNum,
      decimals: state => state.wallet.decimals,
      miningAddr: state => state.wallet.miningAddr,
      miningExtracted: state => state.wallet.miningExtracted,
    }),
  },
  mounted() {
  },
  methods: {
    ...mapActions(['GetUserInfo', 'SetBalance', 'checkBalance', 'miningCheckBalance']),
    show(type, status) {
      // type 类型 0挖矿 1全网
      // status 合约类型 1 天使轮 2 AB轮 3 团队成员
      // num 可提取
      this.visible = true
      this.tabIndex = type
      this.contractType = status || 0
      this.getNum()
      this.checkBalance()
      this.SetBalance()
    },
    close() {
      this.visible = false
    },
    changeTabIndex(index) {
      this.tabIndex = index
      console.log(this.tabIndex , this.contractType)
      if (this.tabIndex == 1 && this.contractType == 0) {
        this.contractType = 1
      }
      this.getNum()
      this.SetBalance()
    },
    async getNum() {
      if (this.tabIndex == 0) {
        this.num = this.miningExtracted
      } else {
        switch(this.contractType) {
          case 1:
            this.num = this.angleNum[0]
            this.contractName = this.$t('common.angle')
            this.contractAddr = this.angleAddr
            this.contractAbi = angleAbi
            break
          case 2:
            this.num = this.abNum[0]
            this.contractName = this.$t('common.ab')
            this.contractAddr = this.abAddr
            this.contractAbi = abAbi
            break
          case 3:
            this.num = this.teamNum[0]
            this.contractName = this.$t('common.team')
            this.contractAddr = this.teamAddr
            this.contractAbi = teamAbi
            break
        }
      }
    },
    async submit() {
      const web3 = new Web3(window.ethereum)
      let myContract = ''
      let address = this.account
      // 提取
      try {
        // 矿池提取
        if (this.tabIndex == 0) {
          // 判断是否设置不可提取
          if (this.userInfo.isextractWk == 2) {
            this.$toast(this.$t('withdraw.toast_1'))
            return
          }
          myContract = new web3.eth.Contract(miningAbi, this.miningAddr, {
            from: address,
          });
          await myContract.methods.withdraw()
          .send((error, result) => {
              console.log('withdraw- Output:', error, result, '\n')
            if (!error) {
              ExtractLog({
                txid: result,
                value: this.num,
                type: 3,
              }).then(() => {
                this.btnLoading = false
                this.$toast(this.$t('toast.extract'))
                this.miningCheckBalance()
                this.visible = false
                this.$emit('refresh')
              })
            } else {
              this.btnLoading = false
              error = this.$t('toast.dealFailed')
              this.$toast(error)
            }
          });
          
        } else {
          // 全网
          // 判断是否设置不可提取
          if (this.userInfo.isextract == 2) {
            this.$toast(this.$t('withdraw.toast_1'))
            return
          }
          myContract = new web3.eth.Contract(this.contractAbi, this.contractAddr, {
            from: address,
          });
          await myContract.methods.releaseToken()
          .send((error, result) => {
              console.log('releaseToken- Output:', error, result, '\n')
            if (!error) {
              ExtractLog({
                txid: result,
                value: this.num,
                type: this.contractType == 3 ? 4 : this.contractType,
              }).then(() => {
                this.btnLoading = false
                this.$toast(this.$t('toast.extract'))
                this.visible = false
                this.$emit('refresh')
              })
            } else {
              this.btnLoading = false
              error = this.$t('toast.dealFailed')
              this.$toast(error)
            }
          });
        }
      } catch(error) {
        // this.btnLoading = false
        console.error("trigger smart contract error", error)
        this.$toast(error || 'Do not assign to the exception parameter')
      }
    },
    async watchTtans () {
      // // 监听事件
      // let tronWeb = window.tronWeb
      // let contract = await tronWeb.contract().at(this.contractAddr)
      // //contract.[eventname].watch(callback)  eventname是要监听此合约事件的名称
      // await contract && contract.releaseToken().watch((err, event) => {
      //   if(err)
      //     return console.error('Error with "Message" event:', err);
        
      //   console.group('New event received');
      //   console.log('- Contract Address:', event.contract);
      //   console.log('- Event Name:', event.name);
      //   console.log('- Transaction:', event.transaction);
      //   console.log('- Block number:', event.block);
      //   console.log('- Result:', event.result, '\n');
      //   console.groupEnd();
      // });
    },
  }
}
</script>

<style lang="scss" scoped>
:root {
  --van-overlay-background-color: rgba(0, 0, 0, .4) ;
}
.Withdraw {
  border-radius: 40px 40px 0px 0px;
  .content {
    padding: 22px 22px;
    background: #292D32;
    box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 6px 20px rgba(0, 0, 0, 0.66);
    .head {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 12px;
      .head-item {
        margin-right: 24px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #A5A8AA;
      }
      .head-active {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #FFFFFF;
      }
      .head-flex {
        flex: 1;
      }
      .head-img {
        width: 22px;
        height: 22px;
        background: linear-gradient(320.66deg, #141515 14.75%, #2E3236 84.81%);
        border: 1px solid #282B2E;
        box-shadow: 0px 4px 4px #000000, 4px 6px 20px rgba(0, 0, 0, 0.35), 0px -2px 4px rgba(255, 255, 255, 0.14);
        border-radius: 30px;
      }
    }
    .symbol {
      width: 29px;
      height: 6px;
      margin-bottom: 22px;
      background: linear-gradient(90deg, #F6DFA9 0%, #EDC67F 75.52%);
    }
    .wallet {
      margin-bottom: 12px;
      .wallet-text {
        margin-bottom: 12px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #B8B6B2;
      }
      .wallet-address {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #EFBB5E;
      }
    }
    .list {
      .item {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 16px 0;
        .item-key {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #B8B6B2;
        }
        .item-value {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #FFFFFF;
        }
        .item-gold {
          color: #EFBB5E;
        }
      }
    }
    .notice {
      display:flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 30px;
      img {
        width: 25px;
        height: 25px;
        margin-right: 9px;
        background: linear-gradient(320.66deg, #FE4F4F 14.75%, #F72424 84.81%);
        border: 1px solid #BF0202;
        box-shadow: 0px 4px 4px #000000, 4px 6px 20px rgba(0, 0, 0, 0.35), 0px -2px 4px rgba(255, 255, 255, 0.14);
        border-radius: 30px;
      }
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    .w-btn {
      width: 302px;
      height: 40px;
      margin: 0 auto;
      margin-bottom: 12px;
    }
  }
}
</style>
