<template>
  <div class="Lock">
    <div class="title">批量锁仓</div>
    <div class="main main1">
      <div class="main-award">
        <div class="ma-key">当前钱包地址</div>
        <div class="ma-addr">{{account}}</div>
      </div>
      <div class="main-list">
        <div class="main-item">
          <div class="mi-key">钱包{{systemInfo.tkname}}余额</div>
          <div class="mi-value">{{mudBalance}}</div>
        </div>
        <div class="main-item">
          <div class="mi-key">钱包MATIC余额</div>
          <div class="mi-value">{{ethBalance}}</div>
        </div>
      </div>
    </div>
    <div class="main main2">
      <div class="main-list main-list-col">
        <div class="main-row">
          <div class="main-head">天使轮{{systemInfo.tkname}}总和{{angel.allValue || '-'}}，共{{angel.allCount || '-'}}条</div>
          <div class="main-top">
            <div class="main-item">
                <div class="mi-key">第{{angel.start}}-{{angel.end}}条总和</div>
                <div class="mi-value">{{angel.thisValue || '-'}}</div>
            </div>
            <div class="main-btn">
              <CommonBtn :btnText="'转入天使轮合约'" :loading="loading1"  @btnClick="transfer(1)"></CommonBtn>
            </div>
          </div>
          <div class="main-bottom">
            <div class="" v-if="status1 == 1">
              <van-icon name="clock-o" /> 准备中
            </div>
            <div class="" v-if="status1 == 2">
              <van-icon name="replay" /> 等待执行结果
            </div>
            <div class="" v-if="status1 == 3">
              <van-icon name="passed" /> 执行成功
            </div>
            <div class="" v-if="status1 == 4">
              <van-icon name="close" /> 执行失败请确认后重试
            </div>
          </div>
        </div>
        <div class="main-row">
          <div class="main-head">天使轮{{systemInfo.tkname}}总和{{ab.allValue || '-'}}，共{{ab.allCount || '-'}}条</div>
          <div class="main-top">
            <div class="main-item">
                <div class="mi-key">第{{ab.start}}-{{ab.end}}条总和</div>
                <div class="mi-value">{{ab.thisValue || '-'}}</div>
            </div>
            <div class="main-btn">
              <CommonBtn :btnText="'转入AB轮合约'" :loading="loading2"  @btnClick="transfer(2)"></CommonBtn>
            </div>
          </div>
          <div class="main-bottom">
            <div class="" v-if="status2 == 1">
              <van-icon name="clock-o" /> 准备中
            </div>
            <div class="" v-if="status2 == 2">
              <van-icon name="replay" /> 等待执行结果
            </div>
            <div class="" v-if="status2 == 3">
              <van-icon name="passed" /> 执行成功
            </div>
            <div class="" v-if="status2 == 4">
              <van-icon name="close" /> 执行失败请确认后重试
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getAbAddrArrAngel, getAbAddrArrAb, setAbAddrArrAngelStatus, setAbAddrArrAbStatus } from '@/api/user'
import Web3 from "web3"
import angleAbi from '@/utils/angleAbi'
import abAbi from '@/utils/abAbi'
export default {
  name: 'Lock',
  components: {
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
      account: state => state.wallet.account,
      mudBalance: state => state.wallet.mudBalance,
      ethBalance: state => state.wallet.ethBalance,
      angleAddr: state => state.wallet.angleAddr,
      abAddr: state => state.wallet.abAddr,
    }),
  },
  watch: {
  },
  data() {
    return {
      angel: {},
      ab: {},
      loading1: false,
      loading2: false,
      // 状态 1准备 2等待 3完成
      status1: 1,
      status2: 1,
      type: '', // 操作类型 1天使 2ab
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapActions(['GetUserInfo', 'setNoticeType', 'checkBalance', 'miningCheckBalance', 'SetBalance']),
    async getData() {
      await getAbAddrArrAngel().then(res => {
        console.log(res)
        this.angel = res
      })

      await getAbAddrArrAb().then(res => {
        this.ab = res
      })

    },
    transfer(type) {
      // type 1天使 2AB
      console.log('transfer', type)
      this.type = type

      if (this.loading1 || this.loading2) {
        this.$toast('请先等待上个动作完成')
        return
      }
      const address = this.account
      const web3 = new Web3(window.ethereum)
      let myContract = ''
      let contractAbi = {}
      let contractAddress = ''
      // let decimals = state.decimals
      let addr = []
      let value = []
      if (type == 1)  {
        this.loading1 = true
        this.status1 = 2
        contractAbi = angleAbi
        contractAddress = this.angleAddr
        addr = this.angel.addr
        value = this.angel.value
        // addr = ['0xAbBd2d34bA6453f6bB616324fA31D9Ea5131Bded']
        // value = ['400000000']
      } else {
        this.loading2 = true
        this.status2 = 2
        contractAbi = abAbi
        contractAddress = this.abAddr
        addr = this.ab.addr
        value = this.ab.value
      }
      myContract = new web3.eth.Contract(contractAbi, contractAddress, {
        from: address, 
      });
      myContract.methods.icoDeposits(addr, value).send((error, result) => {
        console.log('icoDeposits- Output:', error, result, '\n')
        if (!error) {
          this.watchHash(result)
        } else {
          if (type == 1)  {
            this.loading1 = false
            this.status1 = 4
          } else {
            this.loading2 = false
            this.status2 = 4
          }
          this.$toast(error)
        }
      })
      
    },
    watchHash(hash) {
      let ethereum = window.ethereum
      console.log(hash)
      ethereum.request({ 
        // method: 'eth_getTransactionByHash',
        method: 'eth_getTransactionReceipt',
        params: [hash]
      })
      .then(async res => {
        console.log(res)
        if (res && res.status && res.status == "0x1") {
          this.$toast('执行成功！')
          this.SetBalance()
          if (this.type == 1)  {
            this.loading1 = false
            this.status1 = 1
            await setAbAddrArrAngelStatus({txid: hash}).then(() => {
              this.getData()
            })
          } else {
            this.loading2 = false
            this.status2 = 1
            await setAbAddrArrAbStatus({txid: hash}).then(() => {
              this.getData()
            })
          }
        } else {
          setTimeout(() => {
            this.watchHash(hash)
          }, 2000);
        }
      })
      .catch((error) => {
        console.log(error)
      })
      
    }
  }
}
</script>
<style lang="scss" scoped>
.Lock {
  position: relative;
  height: 100vh;
  padding: 0 15px;
  background: url(~@/assets/images/bg.png);
  background-size: 100% 100%;
  background-position: 0 -40px;
  .title {
    width: 100%;
    padding: 40px 0;
    text-align: center;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
  }
  .head {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    margin-bottom: 30px;
    .head-item {
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      .hi-text {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #BDBDBD;
      }
      .hi-op {
        width: 44px;
        height: 5px;
        margin-top: 5px;
        background: transparent;
      }
    }
    .head-item-active {
      .hi-text {
        color: #FFFFFF;
      }
      .hi-op {
        background: #EDC071;
        box-shadow: inset 0px -1px 2px #D28B0E;
        border-radius: 25px;
      }
    }
  }
  .main {
    width: 100%;
    padding: 30px 24px;
    margin-bottom: 24px;
    border-radius: 40px;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D;
    .main-award {
      margin: 0 4px;
      margin-bottom: 10px;
      .ma-key {
        margin-bottom: 6px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #A5A8AA;
      }
      .ma-value {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        color: #FFFFFF;
      }
      .ma-addr {
        word-break: break-all;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
      }
    }
    .main-list {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 32px;
      .main-row {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px 0;
        margin: 4px 0;
        .main-head {
          width: 100%;
          margin-bottom: 10px;
          text-align: center;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #fff;
        }
        .main-top {
          display:flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
        .main-bottom {
          margin: 4px 0 2px 0;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #EDC071;
        }
        .main-btn {
          width: 120px;
          height: 36px;
          margin-left: 20px;
        }
      }
      .main-row:not(:last-child) {
        border-bottom: 1px solid rgba($color: #999999, $alpha: 0.3);
      }
      .main-item {
        flex: 1;
        margin: 0 4px;
        .mi-key {
          margin-bottom: 8px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #A5A8AA;
        }
        .mi-value {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #FFFFFF;
        }
      }
    }
    .main-list-col {
      flex-direction: column;
    }
  }
  .main1 {
    height: 170px;
    background: url(~@/assets/images/bg_Mining.png);
    background-size: 100% auto;
  }
  .main2 {
    height: 270px;
    padding: 16px 24px;
    // background: url(~@/assets/images/bg_WholeNetwork.png);
    // background: url(~@/assets/images/bg_Mining.png);
    background: url(~@/assets/images/bg.png);
    background-size: 100% auto;
  }
}
</style>

