<template>
  <div class="Mine">
    <div class="head">
      <img class="head-avatar" src="@/assets/images/icon_User.png" alt="">
      <div class="head-desc">
        <div class="head-name">{{userInfo?.nickname || $t('mine.name')}}</div>
        <div class="head-account">{{userAccount}}</div>
        <!-- {{userMATIC ? ` | ${userMATIC}` : ''}} -->
      </div>
      <!-- <img class="head-edit" @click="editMATIC" src="@/assets/images/edit.png" alt=""> -->
    </div>
    <!-- <div class="tab">
      <div class="tab-item" @click="toPath('/record')">
        <img src="@/assets/images/icon_Pledge.png" alt="">
        <div class="">{{$t('common.myPledge')}}</div>
      </div>
      <div class="tab-item" @click="toPath('/invite')">
        <img src="@/assets/images/icon_Team.png" alt="">
        <div class="">{{$t('mine.team')}}</div>
      </div>
    </div>
    <div class="adv" v-if="imgUrl" @click="toPath('/invite')">
      <img :src="imgUrl" alt="">
    </div> -->
    <div class="team">
      <div class="myteam" @click="toPath('/invite')">
        <div>{{ $t('mine.team') }}</div>
        <img class="arrow" src="@/assets/images/team_arrow.png" alt="">
      </div>
      <div class="teamdetail">
        <div>{{ $t('mine.teamfast') }}：{{userinfo['team_recommend']}}%</div>
        <div>{{ $t('mine.teamrewardrate') }}：{{userinfo['team_accelerate']}}%</div>
      </div>
      <div class="level">
        <img class="logo" :src="userinfo['lv_icon']" alt="">
        <div class="level-name">{{userinfo['lv_name']}}</div>
      </div>
    </div>

    <div class="pledge">
      <div class="flexrow">
        <div class="nrow">
          <img class="icon" src="@/assets/images/icon_pledge1.png" alt="">
          <div class="title">{{ $t('mine.mybet') }}</div>
        </div>
        <div class="nrow" @click="toPath('/record')">
          <!-- <div class="subtitle">{{ $t('asset.tab_1') }}</div> -->
          <img class="toall" src="@/assets/images/icon_to.png" alt="">
        </div>
      </div>
      <div class="flexrow margin20">
        <div class="flexcol">
          <div class="title">{{ userinfo['order_ing'] }}</div>
          <div class="subtitle">{{ $t('record.status_1') }}</div>
        </div>
        <div class="flexcol">
          <div class="title">{{ userinfo['order_end'] }}</div>
          <div class="subtitle">{{ $t('record.status_2') }}</div>
        </div>
        <div class="flexcol">
          <div class="title">{{ userinfo['order_re'] }}</div>
          <div class="subtitle">{{ $t('record.status_3') }}</div>
        </div>
      </div>
      <div class="flexrow margin20">
        <div class="button" @click="toPath('/record?tab=2')">
          <CommonBtn :btnText="$t('mine.myfloatbet')" @btnClick="closeNotice"></CommonBtn>
        </div>
        <div class="button" @click="toPath('/record?tab=1')">
          <CommonBtn :btnText="$t('mine.mysinglebet')" :btnType="2" @btnClick="closeNotice"></CommonBtn>
        </div>
      </div>
    </div>

    <div class="list">
      <div class="item" v-for="(o, i) in menuList" :key="i" @click="toPath(o.url)">
        <img :src="o.img" alt="">
        <div class="">{{o.name}}</div>
      </div>
    </div>
    <van-popup 
      v-model:show="visible"
      round
      class="Language"
    >
      <div class="content">
        <div class="header">
        <div class="header-left">
          
        </div>
        <img class="header-icon" @click="close" src="@/assets/images/icon_Delete.png" alt="">
      </div>
        <div class="main">
          <div class="item" @click="changeLanguage('zh_hk')" :class="{'item-active': language == 'zh_hk'}">中文繁体</div>
          <div class="item" @click="changeLanguage('en_us')" :class="{'item-active': language == 'en_us'}">English</div>
        </div>
      </div>
    </van-popup>
    <TabBar :active="3"></TabBar>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import {  } from '@/api/user'
import TabBar from '@/components/TabBar'
import { myInfo, vipList } from '../../api/user'
export default {
  name: 'Mine',
  components: {
    TabBar,
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
      userInfo: state => state.user.userInfo,
      account: state => state.wallet.account
    }),
    userAccount() {
      return this.account ? this.account.slice(0, 4) + '....' + this.account.slice(-4) : '请先登录'
    },
    userMATIC() {
      return this.userInfo.eth ? this.userInfo.eth.slice(0, 4) + '....' + this.userInfo.eth.slice(-4) : '请先登录'
    },
  },
  watch: {
    userInfo: {
     handler: function() {
      this.getData()
     },
     deep: true
    }
  },
  data() {
    return {
      menuList: [],
      visible: false,
      language: '',
      imgUrl: '',
      viplist: [],
      userinfo: {}
    }
  },
  mounted() {
    this.getData()
    if (this.systemInfo && this.systemInfo.banner) {
      let imgArr = this.systemInfo.banner.filter(item => item.type == 2) // 1首页2我的3团队
      let imgObj = JSON.parse(JSON.stringify(imgArr[0]))
      this.imgUrl = '/' + imgObj.url
    }
  },
  methods: {
    ...mapActions(['GetUserInfo', 'checkLink', 'SetFirstLogin']),
    editMATIC() {
      this.SetFirstLogin(true)
    },
    async getData() {
      console.log(this.userInfo.hasnode, this.menuList.length)
      this.menuList = [
        {
          name: this.$t('mine.introduce'),
          img: require('@/assets/images/icon_Project.png'),
          url: '/pdf',
        },
        {
          name: this.$t('mine.protocol'),
          img: require('@/assets/images/icon_UserAgreement.png'),
          url: '/message?type=2',
        },
        {
          name: this.$t('mine.language'),
          img: require('@/assets/images/icon_Language.png'),
          url: 'language',
        },
        {
          name: this.$t('mine.contact'),
          img: require('@/assets/images/icon_contact.png'),
          url: '/contact',
        }
      ]
      if(this.userInfo.hasnode == 1) {
        this.menuList.unshift({
          name: this.$t('mine.node'),
          img: require('@/assets/images/icon_Node.png'),
          url: '/note',
        })
      }
      if(this.userInfo.hasteam == 1) {
        this.menuList.unshift({
          name: this.$t('mine.teamAccount'),
          img: require('@/assets/images/icon_team2.png'),
          url: '/team',
        })
      }

      this.myInfo();
    },
    async myInfo(){
      await myInfo().then(async res=>{
        this.userinfo = res
        await this.getVipList();
      });
    },
    async getVipList(){
      await vipList().then(res=>{
        var list = res.list;
        var grade = list.filter(element=>element['lv'].toString()==this.userinfo['lv'].toString());
        this.userinfo['logo'] = grade['lv_icon']
      })
    },
    toPath(url) {
      console.log(url)
      if (url == 'language') {
        this.language = localStorage.getItem('XELanguage') || ''
        // zh_hk en_us
        this.visible = true
      } else {
        this.$router.push(url)
      }
      
    },
    close() {
      this.visible = false
    },
    changeLanguage(type) {
      this.language = type
      localStorage.setItem('XELanguage', type)
      window.location.reload()
    }
  }
}
</script>
<style lang="scss" scoped>
.Mine {
  position: relative;
  min-height: calc(100vh - 50px);
  padding: 0 15px;
  padding-top: 40px;
  padding-bottom: 70px;
  background: url(~@/assets/images/bg_my.png);
  background-size: 100% auto;
  background-position: 0 -40px;
  .head {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    .head-avatar {
      width: 46px;
      height: 46px;
      margin-right: 14px;
      background: linear-gradient(320.66deg, #141515 14.75%, #2E3236 84.81%);
      border: 1px solid #282B2E;
      box-shadow: 0px 4px 4px #000000, 4px 6px 20px rgba(0, 0, 0, 0.35), 0px -2px 4px rgba(255, 255, 255, 0.14);
      border-radius: 30px;
    }
    .head-desc {
      display:flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 46px;
      flex: 1;
      .head-name {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
      }
      .head-account {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #A5A8AA;
      }
    }
    .head-edit {
      width: 41px;
      height: 41px;
    }
  }
  .tab {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 32px;
    .tab-item {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 166px;
      height: 72px;
      background: #202122;
      box-shadow: inset -1px -1px 1px rgba(210, 210, 210, 0.25), inset -2px 1px 4px #000000;
      border-radius: 10px;
      img {
        width: 41px;
        height: 41px;
        margin-right: 11px;
        background: linear-gradient(320.66deg, #141515 14.75%, #2E3236 84.81%);
        border: 1px solid #282B2E;
        box-shadow: 0px 4px 4px #000000, 4px 6px 20px rgba(0, 0, 0, 0.35), 0px -2px 4px rgba(255, 255, 255, 0.14);
        border-radius: 30px;
      }
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
    }
  }
  .adv {
    width: 345px;
    height: 66px;
    margin: 0 auto;
    margin-bottom: 28px;
    border-radius: 6px;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D;
    img {
      width: 100%;
      height: 100%;
    }  
  }
  .list {
    width: 100%;
    margin-bottom: 32px;
    .item {
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 59px;
      margin: 12px 0;
      padding: 0 15px;
      background: #202122;
      box-shadow: inset -1px -1px 1px rgba(210, 210, 210, 0.25), inset -2px 1px 4px #000000;
      border-radius: 10px;
      img {
        width: 31px;
        height: 31px;
        margin-right: 16px;
        background: linear-gradient(320.66deg, #141515 14.75%, #2E3236 84.81%);
        border: 1px solid #282B2E;
        box-shadow: 0px 4px 4px #000000, 4px 6px 20px rgba(0, 0, 0, 0.35), 0px -2px 4px rgba(255, 255, 255, 0.14);
        border-radius: 50%;
      }
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
    }
  }
}
.Language {
  border-radius: 40px 40px 0px 0px;
  .content {
    padding: 15px 22px;
    background: #292D32;
    box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px rgba(0, 0, 0, 0.66);

  }
  .header {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 7px;
    .header-left {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .header-text {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #FFFFFF;
    }
    .header-icon {
      width: 16px;
      height: 16px;
    }
  }
  .main {
    width: 300px;
    padding: 40px 20px;
    .item {
      width: 100%;
      margin: 10px 0;
      text-align: center;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #A5A8AA;
    }
    .item-active {
      font-size: 16px;
      line-height: 22px;
      color: #FFFFFF;
    }
  }
}
.team {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .myteam {
    width: 314px;
    height: 40px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: linear-gradient(90deg, #EFB956, #EDC67F);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #75521A;
    margin-top: 25px;
    .arrow {
      height: 13.5px;
      width: 9.5px;
      margin: 0 20px 0 10px;
    }
  }
  .teamdetail {
    height: 67px;
    width: 344px;
    border-radius: 10px;
    box-shadow:  6px 6px 12px rgba(0, 0, 0, 0.25), inset 0px 0px 20px rgba(0, 0, 0, 0.66);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    color: white;
    font-size: 16px;
  }
  .level {
    position: absolute;
    top: -5px;
    left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo {
      height: 48px;
      width: 64px;
    }
    .level-name {
      height: 13px;
      width: 30px;
      border-radius: 6.5px;
      border: 1px solid #614969;
      background: #A192A2;
      color: white;
      font-size: 9px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
    }
  }
}
.pledge {
    width: 100%;
    padding: 24px 30px;
    background: #202122;
    height: 204px;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
    border-radius: 40px;
    margin: 20px 0 20px 0;
    display: flex;
    flex-direction: column;
    .flexrow {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .flexcol {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .nrow {
      display: flex;
    }
    .icon {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }
    .toall {
      height: 18px;
      width: 18px;
      margin-left: 10px;
    }
    .title {
      font-size: 14px;
      color: white;
    }
    .subtitle {
      font-size: 14px;
      color: #B8B6B2;
      margin-top: 10px;
    }
    .margin20 {
      margin-top: 25px;
      padding: 0 10px;
    }
    .button {
      height: 36px;
      width: 126px;
    }
}
</style>

