<template>
  <div class="NoData">
    <img src="@/assets/images/img_nodata.png" alt="">
    <div :class="textColor == 'black' ? 'nd-black' : ''">{{$t('common.nodata')}}</div>
  </div>
</template>

<script>
export default {
  name: 'NoData',
  props: {
    textColor: {
      type: String,
      default: ''
    }
  },
}
</script>

<style lang="scss" scoped>
.NoData {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #fff;
  img {
    width: 180px;
    margin: 20px;
  }
  .nd-black {
    color: $color-black;
  }
}
</style>
