<template>
    <div class="MyNft">
        <img class="back-btn" src="../../assets/images/icon_Return.png" alt="" @click="goBack()">
        <img class="sort-btn" src="../../assets/images/icon_screen.png" alt="" @click="visible = true;">

        <div class="tab-box">
            <div v-for="(item, index) in tabs" :key="index" @click="switchTab(index)">
                <div style="display: flex; align-items: center; position: relative; height: 38px">
                    <div class="font" :class="selected==index?'selected':'unselected'">{{ item }}</div>
                    <div v-if="index!=2" class="line"></div>
                    <div v-if="selected==index" class="slide"></div>
                </div>
            </div>
        </div>
        <van-list
          v-model:loading="loading"
          :finished="finished"
        >
            <div class="nft-list" v-if="list.length>0">
                <div class="nft-item" :class="{'marginl9': index%2==1}" v-for="(item, index) in list" :key="index" @click="goDetail(item)">
                    <img class="nft-logo" v-lazy="fullImage(item.uri)" />
                    <div class="nft-text">
                        <span class="text-white">{{ nftName(item.lv)+ '-' + attribute(item.lv, parseInt(item.tokenId)) }}</span>
                        <span class="text-grey"></span>
                    </div>
                    <div class="nft-text">
                        <span class="text-grey">{{ '#'+item.tokenId }}</span>
                        <span class="text-green"></span>
                    </div>
                </div>
            </div>
            <div v-else-if="list.length==0&&!loading">
                <NoData/>
            </div>
        </van-list>
        <van-popup
            v-model:show="visible"
            position="bottom"
            style="background: none;"
        >
            <Sort
                :closePop="()=>{visible=false}"
                :sortCleanUp="sortCleanUp"
                :sortConfirm="sortConfirm"
            />
        </van-popup>
    </div>
</template>
  
<script>
  import { mapState } from 'vuex'
  import { myNft } from '@/api/user'
  import { fullImageUrl, getContract, getAttribute, getAttriList } from '@/utils/filter'
  import NoData from '@/components/NoData'
  import Sort from '@/components/Sort'

  export default {
    name: 'MyNft',
    components: {
        NoData,
        Sort
    },
    computed: {
      ...mapState({
        systemInfo: state => state.system.systemInfo,
        userInfo: state => state.user.userInfo,
        account: state => state.wallet.account
      }),
    },
    data() {
        return {
            tabs: [this.$t('nft.all'),this.$t('nft.sold'),this.$t('nft.free')],
            list: [],
            total: [],
            sold: [],
            free: [],
            selected: 0,
            loading: false,
            finished: false,
            visible: false,
            
        }
    },
    mounted() {
        this.getMyNft('0','0')
    },
    methods: {
        goBack(){
            this.$router.back();
        },
        goDetail(item){
            this.$router.push('/nftdetail?detail='+JSON.stringify(item))
        },
        switchTab(index){
            this.selected=index;
            this.list = index==0?this.total:(index==1?this.sold:this.free)
        },
        fullImage(url){
            return fullImageUrl(url)
        },
        nftName(lv){
            return getContract(lv).name
        },
        attribute(lv, id){
            return getAttribute(lv, id)
        },
        pushItem(element, id){
            if((getAttribute(element.lv, element.tokenId)==getAttriList()[parseInt(id)].name) || id=='0'){
                this.total.push(element)
                this.free.push(element);
            }
        },
        getMyNft(lv, id){
            this.total = [];
            this.free = [];
            this.sold = [];
            this.loading = true;
            this.finished = false;
            myNft().then((res)=>{
                if(lv=='0'||lv=='1'){
                    res.list[0].data.forEach(element => {
                        element.lv = res.list[0].lv;
                        this.pushItem(element, id)
                    });
                }
                if(lv=='0'||lv=='2'){
                    res.list[1].data.forEach(element => {
                        element.lv = res.list[1].lv;
                        this.pushItem(element, id)
                    });
                }
                if(lv=='0'||lv=='3'){
                    res.list[2].data.forEach(element => {
                        element.lv = res.list[2].lv;
                        this.pushItem(element, id)
                    });
                }
                if(lv=='0'||lv=='4'){
                    res.list[3].data.forEach(element => {
                        element.lv = res.list[3].lv;
                        this.pushItem(element, id)
                    });
                }
                if(lv=='0'||lv=='5'){
                    res.list[4].data.forEach(element => {
                        element.lv = res.list[4].lv;
                        this.pushItem(element, id)
                    });
                }
                this.list = this.total;
                this.loading = false;
                this.finished = true;
            })
        },
        sortCleanUp(){
            this.visible = false;
            this.getMyNft('0','0')
        },
        sortConfirm(lv, id){
            this.visible = false;
            this.getMyNft(lv, id)
        }
    }
  }
</script>
<style lang="scss" scoped>
.MyNft {
    position: relative;
    min-height: 100%;
    padding: 0 0px;
    padding-top: 10px;
    // padding-bottom: 70px;
    background: url(~@/assets/images/bg_NFT.png);
    background-size: 100% auto;
    background-position: 0 -40px;
}
.back-btn {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 15px;
    left: 20px;
}
.sort-btn {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 20px;
    right: 20px;
}
.tab-box {
    height: 38px;
    width: 240px;
    margin: 0px auto 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #FFE6007D;
    box-shadow: inset 0px 4px 21px #39456F;
    .line {
        height: 14px;
        width: 1px;
        background: rgba(255, 255, 255, 0.3);
    }
    .font {
        width: 79px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #6A7476;
        font-size: 13px;
        font-weight: 400;
    }
    .unselected {
        color: #6A7476;
    }
    .selected {
        color: #FFE600;
    }
    .slide {
        height: 3px;
        width: 20px;
        background: #FFE600;
        position: absolute;
        bottom: 0;
        margin: 0 auto;
        left: 0;
        right: 0;
    }
}
.nft-list {
    display: flex;
    flex-wrap: wrap;
    width: 341px;
    margin: 0 auto;
    .nft-item {
        height: 204px;
        width: 166px;
        border-radius: 17px;
        background: #213335;
        display: flex;
        margin: 0 0 10px 0px;
        flex-direction: column;
        align-items: center;
        border: 1px solid #3E4C66;
        box-shadow: inset 0px 4px 21px #39456F;

        .nft-logo {
            height: 130px;
            width: 152px;
            border-radius: 10px;
            object-fit: cover;
            margin: 5px auto;
        }
        .nft-text {
            display: flex;
            width: 145px;
            margin: 5px auto 0 auto;
            justify-content: space-between;
            .text-white {
                color: white;
                font-size: 12px;
                font-weight: 400;
            }
            .text-grey {
                color: rgba(255, 255, 255, 0.5);
                font-size: 12px;
                font-weight: 400;
            }
            .text-green {
                color: #00C744;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
    .marginl9 {
        margin-left: 9px;
    }
}
.grade {
    width: 100%;
    height: 180px;
    background: #121E1F;
    display: flex;
    flex-direction: column;
    .item {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 14px;
    }
}
</style>
  
  