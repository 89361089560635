<template>
  <van-tabbar v-model="localActive">
    <van-tabbar-item v-for="(o,i) in tabArr" :key="i" replace :to="o.to">
      <span>{{o.name}}</span>
      <template #icon="props">
        <img :src="props.active ? o.active : o.inactive" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: 'TabBar',
  props: {
    active: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      localActive: this.active,
      tabArr: [
        {
          name: this.$t('tab.home'),
          to: '/home',
          active: require('@/assets/images/icon_home_Nav_sel.png'),
          inactive: require('@/assets/images/icon_home_Nav_nor.png'),
        },
        {
          name: 'NFT',
          to: '/nftTab',
          active: require('@/assets/images/icon_NFT_Nav_sel.png'),
          inactive: require('@/assets/images/icon_NFT_Nav_nor.png'),
        },
        {
          name: this.$t('tab.asset'),
          to: '/asset',
          active: require('@/assets/images/icon_property_Nav_sel.png'),
          inactive: require('@/assets/images/icon_property_Nav_nor.png'),
        },
        {
          name: this.$t('tab.mine'),
          to: '/mine',
          active: require('@/assets/images/icon_my_Nav_sel.png'),
          inactive: require('@/assets/images/icon_my_Nav_nor.png'),
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.van-tabbar {
  background: #292E32;
}
.van-tabbar-item__icon img {
  height: 24px;
}
.van-tabbar-item {
  color: #7C7B6B;
  font-size: 9px;
}
.van-tabbar-item--active {
  color: white;
  background: #292E32;
}
.van-tabbar::before,
.van-tabbar::after {
  display: none;
}
</style>
