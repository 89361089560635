<template>
    <div class="sort">
        <div class="title">
            <div>{{ $t('nft.lvtitle') }}</div>
            <img style="height: 25px; margin-right: 20px;" src="../assets/images/icon_delete1.png" alt="" @click="closeSortPop">
        </div>
        <div class="gradeList">
            <div class="item" v-for="(item, index) in getGradeList()" :key="index" @click="selectedLv=item.lv">
                <div v-if="selectedLv!=item.lv" class="unselected"></div>
                <div v-else class="selected">
                    <img class="icon" src="../assets/images/icon_Selected1.png" alt="">
                </div>
                <div class="text">{{ item.name }}</div>
            </div>
        </div>
        <div class="title">
            <div>{{ $t('nft.attrititle') }}</div>
            <div></div>
        </div>
        <div class="gradeList">
            <div class="item" v-for="(item, index) in getAttriList()" :key="index" @click="selectedAttri=item.id">
                <div v-if="selectedAttri!=item.id" class="unselected"></div>
                <div v-else class="selected">
                    <img class="icon" src="../assets/images/icon_Selected1.png" alt="">
                </div>
                <div class="text">{{ item.name }}</div>
            </div>
        </div>
        <div style="display: flex; width: 80%; margin: 10px auto; justify-content: center;">
            <div v-if="sortType==0" @click="sortCleanUp()" style="margin-right: 20px;" class="cbtn">{{ "清除" }}</div>
            <div @click="sortConfirm(selectedLv, selectedAttri)" class="qbtn">{{ "确认" }}</div>
        </div>
    </div>
    
</template>

<script>
export default {
    name: 'Sort',
    props: {
        closePop: {
            type: Function,
            default: ()=>{}
        },
        sortCleanUp: {
            type: Function,
            default: ()=>{}
        },
        sortConfirm: {
            type: Function,
            default: ()=>{}
        },
        sortType: {
            type: Number,
            default: 0
        },
        initLv: {
            type: String,
            default: '0'
        },
        initId: {
            type: String,
            default: '0'
        },
    },
    data() {
        return {
            selectedLv: this.initLv,
            selectedAttri: this.initId,
            gradeList: [
                {name: this.$t('nft.all'), lv: '0'},
                {name: this.$t('nft.lv1'), lv: '1'},
                {name: this.$t('nft.lv2'), lv: '2'},
                {name: this.$t('nft.lv3'), lv: '3'},
                {name: this.$t('nft.lv4'), lv: '4'},
                {name: this.$t('nft.lv5'), lv: '5'},
            ],
            attributeList: [
                {name: this.$t('nft.all'), id: '0'},
                {name: this.$t('nft.a1'), id: '1'},
                {name: this.$t('nft.a2'), id: '2'},
                {name: this.$t('nft.a3'), id: '3'},
                {name: this.$t('nft.a4'), id: '4'},
                {name: this.$t('nft.a5'), id: '5'}
            ]
        }
    },
    methods: {
        getGradeList(){
            switch(this.sortType){
                case 0:
                    return this.gradeList;
                case 1:
                    return this.gradeList.slice(2)
            }
        },
        getAttriList(){
            switch(this.sortType){
                case 0:
                    return this.attributeList;
                case 1:
                    return this.attributeList.slice(1)
            }
        },
        closeSortPop(){
            this.closePop()
            this.selectedLv = this.initLv
            this.selectedAttri = this.initId
            
        }
    }
}
</script>

<style lang="scss" scoped>
.sort {
    height: 315px;
    border: 1px solid #FFE6007D;
    box-shadow: inset 0px 4px 21px #39456F;
    background: #121E1F;
    padding: 20px 0px 0 0px;
    display: flex;
    flex-direction: column;
    .title {
        display: flex;
        justify-content: space-between;
        color: white;
        font-size: 18px;
        font-weight: 400;
        margin: 0px 0 20px 20px;
    }
    .gradeList {
        display: flex;
        flex-wrap: wrap;
        margin-left: 20px;
        .item {
            width: 70px;
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            .unselected {
                height: 18px;
                width: 18px;
                background: #6A6961;
                border-radius: 2px;
            }
            .selected {
                height: 18px;
                width: 18px;
                background: #FFE600;
                border-radius: 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                .icon {
                    height: 12px;
                    width: 12px;
                }
            }
            .text {
                font-size: 14px; 
                color: white; 
                font-weight: 400; 
                margin: 0 10px 0 10px;
            }
        }
    }
    .cbtn {
        height: 45px;
        width: 138px;
        font-size: 14px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url(~@/assets/images/btn_cleanup.png);
        background-size: 100% 100%;
        // margin: 20px auto;
    }
    .qbtn {
        height: 45px;
        width: 138px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url(~@/assets/images/btn_confirm.png);
        background-size: 100% 100%;
        // margin: 20px auto;
    }
}
</style>