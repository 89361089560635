<template>
  <div class="More">
    <div class="head">
      <div class="account">
        <div class="text">{{infoData.nick_name}}</div>
        <div class="tag">{{infoData.lv_name}}</div>
       </div>
      <div class="head-addr">{{ infoData.addr ? infoData.addr.slice(0, 4) + '....' + infoData.addr.slice(-4) : '' }}</div>
      <div class="head-list">
        <div class="head-item">
          <div class="hi-key">{{ $t('note.nowPledge')}}</div>
          <div class="hi-value">{{infoData.quote}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{ $t('note.monthAdd')}}</div>
          <div class="hi-value">{{infoData.quote_m}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{ $t('note.quote_all')}}</div>
          <div class="hi-value">{{infoData.quote_all}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{$t('note.quote_team')}}</div>
          <div class="hi-value">{{infoData.quote_team}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{$t('note.tree1')}}</div>
          <div class="hi-value">{{infoData.tree1}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{$t('invite.teamNum')}}</div>
          <div class="hi-value">{{infoData.team}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{$t('note.quote_reward')}}</div>
          <div class="hi-value">{{infoData.quote_reward}}</div>
        </div>
        <div class="head-item">
          <div class="hi-key">{{$t('note.team_reward')}}</div>
          <div class="hi-value">{{infoData.team_reward}}</div>
        </div>
      </div>
    </div>
    <div class="plan">
      <div class="plan-top">
        <div class="plan-title">{{$t('common.team')}}</div>
      </div>
      
      <div class="plan-main">
        <table class="plan-table">
          <thead>
            <tr>
              <th class="td1">{{$t('wallet.walletAddr')}}</th>
              <th class="td2">{{$t('common.name')}}</th>
              <th class="td3">{{$t('note.nowPledge')}}</th>
              <th class="td4">{{$t('note.monthAdd')}}</th>
              <th class="td5">{{$t('note.quote_all')}}</th>
              <th class="td5">{{$t('note.quote_team')}}</th>
              <th class="td6">{{$t('note.tree1')}}</th>
              <th class="td6">{{$t('invite.teamNum')}}</th>
            </tr>
          </thead>
        </table>
        <van-list
          v-if="infoList.length != 0 || firstLoad"
          v-model:loading="loading"
          :finished="finished"
          finished-text=""
          @load="onLoad"
          class="plan-list"
        >
          <table class="plan-table">
            <tbody>
              <tr v-for="(o, i) in infoList" :key="i">
                <td class="td1">
                  <div class="owt">{{o.addr}}</div>
                  <div class="tree-tag" v-if="o.tree != 0">
                    {{o.tree == 1 ? $t('note.core') : o.tree}}
                  </div>
                  <!-- <img v-if="o.tree" class="plan-img" src="@/assets/images/icon_group.png" alt=""> -->
                </td>
                <td class="td2 owt">
                  <div class="owt">{{o.nickname}}</div>
                </td>
                <td class="td3">{{o.quote}}</td>
                <td class="td4">{{o.quote_m}}</td>
                <td class="td5">{{o.quote_all}}</td>
                <td class="td5">{{o.quote_team}}</td>
                <td class="td6">{{o.tree1}}</td>
                <td class="td6">{{o.team}}</td>
              </tr>
            </tbody>
          </table>
        </van-list>
        <NoData v-if="infoList.length == 0"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { TreeInfo, TnvitListTree } from '@/api/user'
import NoData from '@/components/NoData'
export default {
  name: 'More',
  components: {
    NoData
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
      userInfo: state => state.user.userInfo,
    }),
  },
  watch: {
    userInfo() {
      if (this.userInfo.uid) {
        this.getData()
      }
    }
  },
  data() {
    return {
      id: '',
      infoData: {
        sup: {},
        my: {},
        son: {}
      },
      type: 0, // 0全部 1我的团队
      infoList: [],
      start: 0,
      limit: 10,
      firstLoad: true,
      loading: false,
      finished: false,
    }
  },
  mounted() {
    this.id = this.$route.query.id || 0
    this.getData()
  },
  methods: {
    ...mapActions(['setResetPage']),
    async getData() {
      await TreeInfo({id: this.id}).then(res => {
        this.infoData = res
      })
      // await this.getList()
    },
    getList() {
      TnvitListTree({id: this.id}).then(res => {
        this.infoList = res
        this.infoList.forEach(item => {
          item.addr = item.addr.slice(0, 4) + '....' + item.addr.slice(-4)
          if (item.name.length > 5) {
            item.name = item.name.slice(0, 4) + '....'
          }
        })
      })
    },
    async onLoad() {
      console.log('onload')
      // if (this.loading) {
      //   return
      // }
      this.loading = true
      let req = TnvitListTree
      await req({id: this.id, start: this.start, limit: this.limit}).then(res => {
        if (this.firstLoad) {
          this.firstLoad = false
        }
        if (this.start == 0 || !this.start) {
          this.infoList = []
        }
        this.infoList = this.infoList.concat(res.list)
        this.infoList.forEach(item => {
          item.addr = item.addr.slice(0, 4) + '....' + item.addr.slice(-4)
          if (item.nickname.length > 5) {
            item.nickname = item.nickname.slice(0, 4) + '....'
          }
        })
        if (this.infoList.length >= res.count) {
          this.finished = true
        } else {
          this.finished = false
        }
        this.start = this.infoList.length
        this.loading = false
        console.log(this.start, this.limit, this.finished, this.loading)
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.More {
  min-height: calc(100vh - 45px);
  padding: 14px 15px 20px 15px;
  background: url(~@/assets/images/bg.png);
  background-size: auto;
  background-repeat: repeat;
  .account {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    .text {
      margin-right: 10px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
    }
    .tag {
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1px 8px;
      background: linear-gradient(90deg, #EEBE66 0%, #EDC67F 100%);
      border-radius: 3px;
    }
    .tag-close {
      color: #fff;
      background: #FB3E3E;
    }
  }
  .head-addr {
    width: 100%;
    text-align: center;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #A5A8AA;
  }
  .tree-tag {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 26px;
    padding: 1px 2px;
    margin-left: 2px;
    background: linear-gradient(90deg, #EEBE66 0%, #EDC67F 100%);
    border-radius: 3px;
    font-family: 'PingFang SC';
    font-size: 12px;
    line-height: 14px;
    color: #212529;
  }
  .head {
    margin-bottom: 22px;
    .head-list {
      display:flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 10px 0;
      .head-item {
        width: 46%;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 4px 0;
        .hi-key {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: #A5A8AA;
        }
        .hi-value {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: #FFFFFF;
        }
      }
    }
  }
  .plan {
    width: 100%;
    padding: 35px 16px;
    background: #202122;
    // box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D, inset 5px 11px 20px #000000;
    border-radius: 40px;
    .plan-top {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    .plan-title {
      margin-left: 16px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #FFFFFF;
    }
    .plan-head {
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .ph-item {
        width: 80px;
        height: 36px;
        margin-right: 9px;
      }
    }
    .plan-img {
      width: 20px;
      height: 20px;
      margin-left: 2px;
    }
    $table-width: 1000px;
    .plan-main {
      width: 100%;
      overflow-x: auto;
    }
    .plan-list {
      width: $table-width;
      height: 430px;
      overflow-y: auto;
    }
    .plan-table {
      overflow-x: auto;
      width: $table-width;
      thead {
        background: #202122;
        box-shadow: inset -1px -1px 1px rgba(210, 210, 210, 0.25), inset -2px 1px 4px #000000;
        border-radius: 10px;
      }
      th, td {
        text-align: left;
        font-size: 14px;
        font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 16px;
      }
      th, td{
        padding: 14px 16px;
      }
      tbody tr:not(:last-child) {
        border-bottom: 1px solid rgba(255,255,255,0.2);
      }
      .td1 {
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 160px;
      }
      .td2 {
        width: 100px;
      }
      .td3 {
        width: 140px;
      }
      .td4 {
        width: 140px;
      }
      .td5 {
        width: 140px;
      }
      .td6 {
        width: 100px;
      }
    }
  }
}
</style>

