<template>
  <div class="Node">
    <div class="list">
      <div 
        class="item"
        v-for="(o,i) in nodeList"
        :key="i"
        @click="pick(i)"
      >
      <div class="main">
        <img class="logo" :src="o.logo" alt="">
        <div class="desc">
          <div class="name">{{o.name}}</div>
          <div class="num">{{$t('exchange.pledgeNum')}}：{{o.num}}</div>
        </div>
        <img class="check" v-if="nodeIndex == i" src="@/assets/images/icon_Selected.png" alt="">
        <img class="check" v-else src="@/assets/images/icon_Unselected.png" alt="">
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { NodeList } from '@/api/system'
export default {
  name: 'Node',
  components: {
  },
  computed: {
    ...mapState({
      systemInfo: state => state.system.systemInfo,
      userInfo: state => state.user.userInfo,
    }),
  },
  data() {
    return {
      nodeList: {}, // 节点信息
      nodeIndex: 0,
    }
  },
  mounted() {
    this.nodeIndex = this.$route.query.index || 0
    this.getData()
  },
  methods: {
    ...mapActions([]),
    async getData() {
      await NodeList().then(res => {
        this.nodeList = res
      })
    },
    pick(i) {
      this.nodeIndex = i
      localStorage.setItem('NodeSelect', this.nodeIndex)
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.Node {
  min-height: calc(100vh - 45px);
  padding: 0 15px;
  padding-top: 20px;
  background: url(~@/assets/images/bg.png);
  background-size: auto;
  background-repeat: repeat;
  .list {

  }
  .item {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 109px;
    margin: 24px 0;
    background: #292D32;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 8px 8px 10px #17181D, inset 5px 11px 20px #000000;
    border-radius: 36px;
  }
  .main {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 326px;
    height: 87px;
    padding: 0 22px;
    background: linear-gradient(180deg, #353A40 0%, #22252A 100%);
    box-shadow: 0px 3px 6px #2F2A1F;
    border-radius: 30px;
  }
  .logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #D9D9D9;
  }
  .desc {
    flex: 1;
    margin: 0 10px;
  }
  .name {
    margin-bottom: 7px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
  }
  .num {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;

    color: #9F8E65;
  }
  .check {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: #2B3035;
    box-shadow: 0px -2px 4px rgba(108, 108, 108, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
</style>

