<template>
    <div class="Withdraw">
      <div class="step">
        <div class="step-top">
          <div class="st-circle">
            <div class="stc-in"></div>
          </div>
          <div class="st-line"></div>
          <div class="st-circle">
            <div class="stc-em"></div>
          </div>
          <div class="st-line"></div>
          <div class="st-circle">
            <div class="stc-em"></div>
          </div>
        </div>
        <div class="step-bottom">
          <div class="sb-item">{{$t('withdraw.step_1')}}</div>
          <div class="sb-item">{{$t('withdraw.step_2')}}</div>
          <div class="sb-item">{{$t('withdraw.step_3')}}</div>
        </div>
      </div>
      <div class="form">
        <div class="title">{{$t('withdraw.price')}}</div>
        <div class="record-symbol"></div>
        <div class="subtitle">{{ $t('withdraw.available') + ': ' + (info['remain']??'0') + systemInfo.tkname}}</div>
        <div class="main-input">
            <input type="text" @input="handleInput" v-model="amount" :placeholder="$t('withdraw.amountPlaceholder')+'('+$t('withdraw.min')+ (info['min']??'0') +')'">
            <div class="mi-all" @click="this.amount = this.info['remain']">{{$t('common.all')}}</div>
        </div>
        <div class="title">{{$t('withdraw.addr')}}</div>
        <div class="record-symbol"></div>
        <div class="main-input">
            <input type="text" v-model="addr" :placeholder="$t('withdraw.input')+'Matic'+$t('withdraw.addr')">
            <!-- <div class="mi-all">{{$t('common.all')}}</div> -->
        </div>
        <div class="pair">
            <div class="left">{{$t('withdraw.amount')}}</div>
            <div class="right">{{ amount==''?'0':amount }}{{ systemInfo.tkname }}</div>
        </div>
        <div class="pair">
            <div class="left">{{ $t('withdraw.hfee')+'('+(info['type']==null?'0':(info['type']=='1'?(info['rate']+'%'):info['rate']))+')' }}</div>
            <div class="right">{{ fee }}{{ systemInfo.tkname }}</div>
        </div>
        <div class="pair">
            <div class="left">{{ $t('withdraw.income') }}</div>
            <div class="right">{{ income }}{{ systemInfo.tkname }}</div>
        </div>
        <div class="admit">
            <CommonBtn :btnText="$t('withdraw.admit')"  @btnClick="withdraw"></CommonBtn>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex'
import { goWithdraw, rewardInfo, withdrawInfo } from '../../api/user'
  export default {
    name: 'Exchange',
    components: {
    },
    data() {
      return {
        info: {},
        amount: '',
        addr: '',
        // fee: '0',
        // income: '0'
      }
    },
    computed: {
        ...mapState({
            systemInfo: state => state.system.systemInfo,
            userInfo: state => state.user.userInfo,
        }),
        fee(){
            return this.amount==''?'0':(this.info['type']=='1'?(parseFloat(this.amount)*parseFloat(this.info['rate'])/100).toString():(this.info['rate']))
        },
        income(){
            return this.amount==''?'0':(parseFloat(this.amount)-parseFloat(this.fee)).toString();
        }
    },

    mounted() {
        this.getInfo();
    },
    methods: {
      ...mapActions(['GetUserInfo', 'checkLink', 'setNoticeType', 'SetBalance', 'SetMaskLoading', 'SetLoadingText', 'signVerify']),
      async withdraw(){

        if(this.amount==""||this.amount=='0'){
            this.$toast('请输入提现数量')
            return;
        }if(this.addr==""){
            this.$toast('请输入钱包地址')
            return;
        }else if(parseFloat(this.amount)>parseFloat(this.info['rewardAll'])){
            this.$toast('奖金余额不足')
            return;
        }else if(parseFloat(this.amount)<parseFloat(this.info['min'])){
            this.$toast('最小提现数量为'+this.info['min'])
            return;
        }

        await this.signVerify('是否确定提现？').then(async res=>{
            if(res!=null){
                await goWithdraw({
                    amount: parseFloat(this.amount),
                    address: this.addr
                }).then(re=>{
                    console.log(re)
                    this.$router.push('/admit')
                })
            }
        })
      },

      async getInfo(){
        await withdrawInfo().then(async res=>{
            this.info = res;
            this.addr = this.userInfo.eth;
            await rewardInfo().then(d=>{
                this.info['remain'] = d['rewardAll']
            })
        })
      },
      handleInput(event) {
        const regex = /^[0-9]*$/; // 正则表达式，只允许数字和小数点
        const newValue = event.target.value;
        if (!regex.test(newValue)) {
            this.amount = newValue.replace(/[^0-9]/g, '');
        } else {
            this.amount = newValue;
        }
        }
    }
  }
  </script>
  <style lang="scss" scoped>
  :root {
    --van-overlay-background-color: rgba(0, 0, 0, .4) ;
  }
  .Withdraw {
    min-height: calc(100vh - 45px);
    padding: 0 15px;
    padding-top: 20px;
    padding-bottom: 10px;
    background: url(~@/assets/images/bg.png);
    background-size: auto;
    background-repeat: repeat;
  
    .slide-tips {
      width: 100%;
      margin: -10px 0 25px 0;
      text-align: center;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #EDC67F;
    }
    .step {
      width: 100%;
      margin-bottom: 38px;
      .step-top {
        display:flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding: 0 15px;
        .st-circle {
          display:flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          background: #2C2F34;
          box-shadow: 0px -3px 6px rgba(147, 147, 147, 0.25), 0px 4px 4px #000000;
          border-radius: 50%;
          .stc-in {
            width: 12px;
            height: 12px;
            background: linear-gradient(180deg, #EFBA5B 0%, #EDC57C 100%);
            box-shadow: inset 0px 4px 4px #B16D00;
            border-radius: 50%;
          }
          .stc-em {
            width: 12px;
            height: 12px;
            background: #2C2F34;
            box-shadow: inset 0px 4px 4px #000000, inset 0px -3px 6px rgba(147, 147, 147, 0.25);
            border-radius: 50%;
          }
        }
        .st-line {
          width: 80px;
          height: 4px;
          background: rgba(36, 37, 40, 0.7);
          box-shadow: inset -2px -2px 4px rgba(255, 255, 255, 0.08), inset 2px 2px 4px rgba(0, 0, 0, 0.8);
          border-radius: 25px;
        }
      }
      .step-bottom {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        .sb-item {
          // flex: 1;
          min-width: 65px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          text-align: center;
          color: #A5A8AA;
        }
      }
    }
}

.form {
    width: 100%;
    padding: 24px 30px;
    background: #202122;
    // box-shadow: -6px -13px 12px rgba(255, 255, 255, 0.25), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
    box-shadow: -8px -4px 4px rgba(54, 58, 64, .6), 6px 6px 12px rgba(0, 0, 0, 0.25), inset 5px 11px 20px #000000;
    border-radius: 40px;
    .title {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #A5A8AA;
    }
    .subtitle {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #A5A8AA;
        margin-top: 12px;
    }
    .record-symbol {
      width: 29px;
      height: 6px;
      margin-top: 8px;
      background: linear-gradient(90deg, #F6DFA9 0%, #EDC67F 75.52%);
    }
}
.main-input {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 49px;
    padding: 0 16px;
    margin: 10px 0 20px 0;
    background: #292D32;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -5px 4px rgba(80, 80, 80, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25), inset -5px -8px 10px #30343A, inset 8px 8px 10px #24262C;
    border-radius: 10px;
    input {
        flex: 1;
        border: none;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        background: transparent;
        color: #B8B6B2;
    }
    .mi-all {
        margin-left: 20px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #EFBB5E;
    }
}
.pair {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .left {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #A5A8AA;
    }
    .right {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: white;
    }
}
.admit {
    height: 49px;
    width: 100%;
    margin: 20px auto;
}
</style>