import i18n from '../lang'
import wallet from '../store/modules/wallet';
const { t } = i18n.global

export function fullImageUrl(url) {
    if (!url) return "";
    let urlArr = url.split("/");
    if(urlArr.length==7){
        return "https://ipfs.filebase.io/ipfs/QmbXFmdKKiTvZveqXY98FLH1TkdcCbsvRnk9TjREnMQufe" + "/" + urlArr[3]+'/'+urlArr[4]+'/'+urlArr[5]+'/'+(urlArr[6].split('.')[0])+'.png';
    }else if(urlArr.length==5){
        return "https://ipfs.filebase.io/ipfs/QmXnGn7xd21VUEPkyQQTMMwf1JDu139neLWYPg7R8bhp6s" + "/" + urlArr[3]+'/'+(urlArr[4].split('.')[0])+'.JPG';
    }else {
        return ""
    }
}


export function getContract(lv) {
    switch(lv){
        case '1':
            return {
                lv: '1',
                name: t('nft.lv1'),
                addr: wallet.state.fractionAddr,
                need: 0
            };
        case '2':
            return {
                lv: '2',
                name: t('nft.lv2'),
                addr: wallet.state.commonAnimalAddr,
                need: 50
            };
        case '3':
            return {
                lv: '3',
                name: t('nft.lv3'),
                addr: wallet.state.rareAnimalAddr,
                need: 100
            };
        case '4':
            return {
                lv: '4',
                name: t('nft.lv4'),
                addr: wallet.state.epicAnimalAddr,
                need: 300
            };
        case '5':
            return {
                lv: '5',
                name: t('nft.lv5'),
                addr: wallet.state.legendAnimalAddr,
                need: 1000
            };
            
    }
    return ''
}

export function getAttribute(lv, id){
    switch(lv){
        case '1':
            if(id<=199&&id>=0){
                return t('nft.a1');
            }else if(id<=399&&id>=200){
                return t('nft.a2');
            }else if(id<=599&&id>=400){
                return t('nft.a3');
            }else if(id<=799&&id>=600){
                return t('nft.a4');
            }else if(id<=999&&id>=800){
                return t('nft.a5');
            }
            break;
        case '2':
            if(id<=99&&id>=0){
                return t('nft.a1');
            }else if(id<=199&&id>=100){
                return t('nft.a2');
            }else if(id<=299&&id>=200){
                return t('nft.a3');
            }else if(id<=399&&id>=300){
                return t('nft.a4');
            }else if(id<=499&&id>=400){
                return t('nft.a5');
            }
            break;
        case '3':
            if(id<=49&&id>=0){
                return t('nft.a1');
            }else if(id<=99&&id>=50){
                return t('nft.a2');
            }else if(id<=149&&id>=100){
                return t('nft.a3');
            }else if(id<=199&&id>=150){
                return t('nft.a4');
            }else if(id<=259&&id>=200){
                return t('nft.a5');
            }
            break;
        case '4':
            if(id<=24&&id>=0){
                return t('nft.a1');
            }else if(id<=49&&id>=25){
                return t('nft.a2');
            }else if(id<=74&&id>=50){
                return t('nft.a3');
            }else if(id<=99&&id>=75){
                return t('nft.a4');
            }else if(id<=124&&id>=100){
                return t('nft.a5');
            }
            break;
        case '5':
            if(id<=11&&id>=0){
                return t('nft.a1');
            }else if(id<=23&&id>=12){
                return t('nft.a2');
            }else if(id<=35&&id>=24){
                return t('nft.a3');
            }else if(id<=47&&id>=36){
                return t('nft.a4');
            }else if(id<=59&&id>=48){
                return t('nft.a5');
            }
    }
    // return '';
}

 export function getAttriList(){
    return [
        {name: t('nft.all'), id: '0'},
        {name: t('nft.a1'), id: '1'},
        {name: t('nft.a2'), id: '2'},
        {name: t('nft.a3'), id: '3'},
        {name: t('nft.a4'), id: '4'},
        {name: t('nft.a5'), id: '5'}
    ]
 }